export function LockedPage(prefix: string) {
    return {
        path: 'locked',
        name: `${prefix}.locked`,
        component: () => import('@/modules/common/pages/locked.page.vue'),
        meta: { modal: true },
    };
}

export function CommonMenuRoutes(prefix: string) {
    return [
        LockedPage(prefix),
    ];
}
