import { inject, injectable } from '@/inversify';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { IGuard } from '@/router/interfaces/guard.interface';

export const ClusterGuardS = Symbol.for('ClusterGuardS');
@injectable()
export default class ClusterGuard implements IGuard {
    @inject(UserServiceS) private userService!: UserService;

    async canActivate() {
        return this.userService.isClusterUser || false;
    }
}
