import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import { Expose, Transform } from 'class-transformer';

export default class DefaultSettingsModel {
    @Expose()
    los!: number;

    @Expose()
    @Transform((_, plain) => plain.number_of_guest)
    numberOfGuests!: number;

    @Expose()
    @Transform((_, plain) => ((plain.meal_type.length > 1 || !plain.meal_type.length) ? 'any' : plain.meal_type[0]))
    mealType!: string;

    @Expose()
    price!: PRICE_TYPE;

    @Expose()
    @Transform((_, plain) => plain.map_provider || 'Google')
    mapProvider!: string;

    @Expose()
    @Transform((_, plain) => plain.targeted_insights || {})
    targetedInsights!: Record<string, boolean>;
}
