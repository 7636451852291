import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import ChainGroup from '@/modules/chain/interfaces/chain-group.enum';
import ANY_MEAL_TYPE from '@/modules/meal-types/constants/any-meal-type.constant';

export default class RatesSettingsModel {
    provider: string | null = null;
    priceType: PRICE_TYPE | null = null;
    roomTypeId: number = ANY_ROOM_TYPE.id;
    mealTypeId: number | null = ANY_MEAL_TYPE.id;
    numberOfGuests: number | null = null;
    priceShown!: PRICE_SHOWN | null;
    groupBy?: ChainGroup = ChainGroup.CLUSTER;
    groupValue?: string = '';
}
