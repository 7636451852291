import { container } from '@/inversify.container';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import use from '@/router/use';

export default function RVSHotelLevelRoutes(basePath: string, prefix: string) {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/hotel-transition.page.vue'),
        beforeEnter: use([
            container.get<FeaturesGuard>(FeaturesGuardS),
        ]),
        children: [
            {
                name: prefix,
                path: '',
                component: () => import('@/modules/rvs/pages/index.page.vue'),
            },
        ],
    };
}
