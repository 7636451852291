import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import ANY_ROOM_TYPE from '@/modules/room-types/constants/any-room-type.constant';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';

export enum DILiteFilterDevice {
    ALL = '',
    MOBILE = 'mobile',
    DESKTOP = 'desktop',
}

export const DILiteFilterDeviceLabels = {
    [DILiteFilterDevice.ALL]: 'filters.all',
    [DILiteFilterDevice.MOBILE]: 'filters.device.mobile',
    [DILiteFilterDevice.DESKTOP]: 'filters.device.desktop',
};

export default class AllChannelsSettingsModel {
    provider: string = 'all';
    device: DILiteFilterDevice = DILiteFilterDevice.ALL;

    priceType: PRICE_TYPE = PRICE_TYPE.LOWEST;
    roomTypeId: number = ANY_ROOM_TYPE.id;
    mealTypeId: number | null = null;
    numberOfGuests: number | null = null;
    priceShown: PRICE_SHOWN = PRICE_SHOWN.SHOWN;

    shownProviders: string[] = [];
}
