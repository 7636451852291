/* eslint-disable */
"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventNames = void 0;
var EventNames;
(function (EventNames) {
    EventNames["METHOD_OPEN"] = "open";
    EventNames["METHOD_SEND"] = "send";
    EventNames["EVENT_ABORT"] = "abort";
    EventNames["EVENT_ERROR"] = "error";
    EventNames["EVENT_LOAD"] = "loaded";
    EventNames["EVENT_TIMEOUT"] = "timeout";
})(EventNames = exports.EventNames || (exports.EventNames = {}));
