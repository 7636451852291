import { inject, injectable } from '@/inversify';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import { plainToClass } from 'class-transformer';
import FleetDocumentModel from '@/modules/cars/modules/fleet/models/fleet-document.model';
import CarsSettingsModel from '@/modules/cars/models/cars-settings.model';
import FleetSettingsModel from './models/fleet-settings.model';

export const FleetApiServiceS = Symbol.for('FleetApiService');
@injectable()
export default class FleetApiService {
    @inject(ApiServiceS) private apiService!: ApiService;

    async getFleet(settings: FleetSettingsModel, dataSources: string[], _year: number, _month: number) {
        const { lor, pos, pickUpCityCode } = settings;

        const body: any = {
            data_source: dataSources.sort(),
            year: _year,
            month: _month + 1,
            fn_pick_up_location_code: [
                pickUpCityCode,
            ],
        };

        if (pos) {
            body.pos = pos;
        }
        if (lor) {
            body.lor = lor;
        }

        const { data } = await this.apiService.post('/car/fleet-density-grouped-docs', body);

        return plainToClass(FleetDocumentModel, data, { excludeExtraneousValues: true });
    }
}
