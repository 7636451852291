import { inject, injectable } from '@/inversify';
import { Route } from 'vue-router';
import { IMiddleware } from '@/router/interfaces/middleware.interface';
import RatesFiltersService, { RatesFiltersServiceS } from '@/modules/rates/rates-filters.service';

export const RatesFiltersMiddlewareS = Symbol.for('RatesFiltersMiddlewareS');
@injectable()
export default class RatesFiltersMiddleware implements IMiddleware {
    @inject(RatesFiltersServiceS) private ratesFiltersService!: RatesFiltersService;

    async canActivate(to: Route) {
        if (to.query && to.query.provider) {
            await this.ratesFiltersService.saveProvider(to.query.provider as string);
        }
        return true;
    }
}
