import { Expose } from 'class-transformer';

export default class RatesTriggerScanModel {
    @Expose()
    scanIds!: string[];

    @Expose()
    documentIds!: number[];

    @Expose()
    status!: string;
}
