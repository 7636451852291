import { Module, VuexModule } from 'vuex-module-decorators';
import MarketsDocumentModel from '@/modules/markets/models/markets-document.model';
import HomeFiltersModel from '@/modules/home/models/home-filters.model';
import LoadingModel from '@/modules/common/models/loading.model';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';

@Module
export default class HomeStore extends VuexModule {
    homeRateDocuments: {
        [compsetId: string]: RatesDocumentModel | null;
    } = {};
    homeVisibilityDocument: MarketsDocumentModel | null = null;
    settings: HomeFiltersModel = new HomeFiltersModel();

    loadingVisibility: LoadingModel = new LoadingModel();
    loadingRates: LoadingModel = new LoadingModel();
    loadingDemand: LoadingModel = new LoadingModel();

    demandData: (number | null)[] | null = null;
}
