import { inject, injectable } from '@/inversify';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import CarsSettingsModel from '@/modules/cars/models/cars-settings.model';
import CarsDocumentModel from '@/modules/cars/models/cars-document.model';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import FleetAvailabilityDocumentModel from '@/modules/cars/models/fleet-availability-document.model';
import CacheService, { CacheServiceS, MODULES, MODULE_METHODS } from '@/modules/common/services/cache/cache.service';
import BranchesModel from './models/branches.model';
import CarsAnalysisDocumentModel from './models/cars-analysis-document.model';

export const CarsApiServiceS = Symbol.for('CarsApiServiceS');
@injectable()
export default class CarsApiService {
    @inject(ApiServiceS) private apiService!: ApiService;
    @inject(ValidatorServiceS) private validatorService!: ValidatorService;
    @inject(CacheServiceS) private cacheService!: CacheService;

    async getCars(
        settings: DocumentFiltersModel,
        carSettings: CarsSettingsModel,
        chain: BranchesModel | null | undefined,
        pickUpCityCodes: string,
        categories: string[],
        query?: Record<string, any>,
    ): Promise<CarsDocumentModel | null> {
        const { month, year } = settings;
        const {
            dataSource,
            lor,
            pos,
            isAvgPrice,
            currentDataSourceMode,
        } = carSettings;

        const chainId = chain ? chain.chainId : '';

        const body: any = {
            is_avg: isAvgPrice,
            categories,
            comparison_type: currentDataSourceMode,
            ...query,
        };

        if (chain) {
            body.child_chain_id = chainId;
        }
        let encodedPOS = pos;
        if (pos) {
            encodedPOS = encodeURIComponent(pos);
        }

        const cacheKey = MODULE_METHODS[MODULES.CARS].getCars;
        const cacheDeps = [`${pickUpCityCodes}/${pickUpCityCodes}/${month + 1}/${year}/${dataSource}/${lor}/${encodedPOS}`, body];

        const cachedData = this.cacheService.get<CarsDocumentModel>(MODULES.CARS, cacheKey, cacheDeps);

        if (cachedData !== null) {
            return cachedData;
        }

        // eslint-disable-next-line global-require
        // eslint-disable-next-line import/no-unresolved
        // eslint-disable-next-line global-require
        // const data = require('../../../public/grouped.json');
        const params = [
            pickUpCityCodes,
            pickUpCityCodes,
            month + 1,
            year,
            dataSource,
            lor,
            encodedPOS,
        ].join('/');

        const { data } = await this.apiService.post(`/car/document_by_location_ids/${params}`, body);
        if (!data || data.status === 'no data') {
            return null;
        }
        const ModelType = query ? CarsAnalysisDocumentModel : CarsDocumentModel;
        const carsDocument = plainToClass(ModelType, <typeof ModelType>data, { excludeExtraneousValues: true });
        this.cacheService.save(MODULES.CARS, cacheKey, cacheDeps, carsDocument);
        return carsDocument;
    }

    async getFleetAvailability(settings: DocumentFiltersModel, carSettings: CarsSettingsModel, pickUpCityCodes: string, categories: string[]) {
        const { month, year } = settings;
        const { pos, isAvgPrice } = carSettings;

        const body: any = {
            is_avg: isAvgPrice,
            categories,
        };

        let encodedPOS = pos;
        if (pos) {
            encodedPOS = encodeURIComponent(pos);
        }

        const cacheKey = MODULE_METHODS[MODULES.CARS].getFleetAvailability;
        const cacheDeps = [`${year}/${month + 1}/${pickUpCityCodes}/${encodedPOS}`, body];

        const cachedData = this.cacheService.get<FleetAvailabilityDocumentModel>(MODULES.CARS, cacheKey, cacheDeps);

        if (cachedData !== null) {
            return cachedData;
        }

        const { data } = await this.apiService.post(
            `/car/get-fleet-occupancies/${year}/${month + 1}/${pickUpCityCodes}/${encodedPOS}`,
            body,
        );

        const fleetDoc = plainToClass(FleetAvailabilityDocumentModel, <FleetAvailabilityDocumentModel>data, { excludeExtraneousValues: true });

        this.cacheService.save(MODULES.CARS, cacheKey, cacheDeps, fleetDoc);

        return fleetDoc;
    }

    async getCarsAnalysisDocument(
        settings: DocumentFiltersModel,
        carSettings: CarsSettingsModel,
        chain: BranchesModel | null | undefined,
        pickUpCityCodes: string,
        categories: string[],
        key: string,
        value: number | string,
    ) {
        const query = key === 'diffDays'
            ? { diff_days: value }
            : {};

        return this.getCars(settings, carSettings, chain, pickUpCityCodes, categories, query);
    }
}
