import { Expose, plainToClass, Transform } from 'class-transformer';
import { InsightGroupBE, Insight } from '../types';
import InsightGroupModel from './insight-group.model';

export default class InsightGroupsListModel {
    @Expose()
    count!: number;

    @Expose()
    @Transform((_, plain: { data: InsightGroupBE[] }) => (
        plain.data.map(groupItem => plainToClass(InsightGroupModel, <InsightGroupBE> groupItem, { excludeExtraneousValues: true }))
    ))
    data!: InsightGroupModel<Insight>[];
}
