import { Expose, Transform } from 'class-transformer';

type Day = number;
type PercentValue = number;
type RootCauseComponent = string;

interface CheckinDateData {
    [hotelId: number]: {
        score: number;
        rootCause: Record<RootCauseComponent, PercentValue>;
    }
}

export default class RVSDocumentModel {
    @Expose({ name: 'main_fn_id' })
    mainHotelId: number = 0;

    @Expose()
    year: number = 0;

    @Expose()
    month: number = 0;

    /**
     * Average score for the whole month of the competitors average scores
     */
    get monthCompsetAverage() {
        const values = Object.values(this.averages).filter(Boolean);
        return Math.round(values.reduce((acc, avg) => acc + avg, 0) / values.length);
    }

    /**
     * Average score for the whole month of the main hotel
     */
    get monthMainAverage() {
        const values = Object
            .values(this.checkinDates)
            .map(data => data[this.mainHotelId]?.score || null)
            .filter(Boolean) as number[];

        return Math.round(values.reduce((acc, avg) => acc + avg, 0) / values.length);
    }

    @Expose({ name: 'check_in_dates' })
    @Transform(plain => {
        const checkinDates: Record<Day, CheckinDateData> = {};

        Object.entries(plain).forEach(([day, data]) => {
            checkinDates[+day] = {};

            Object.entries(data as Record<number, any>).forEach(([hotelId, scoreData]) => {
                const rootCause = { ...scoreData };
                delete rootCause.score;

                checkinDates[+day][+hotelId] = {
                    score: scoreData.score,
                    rootCause,
                };
            });
        });

        return checkinDates;
    })
    checkinDates: Record<Day, CheckinDateData> = {};

    @Expose()
    @Transform((_, plain) => {
        const mainHotelId = plain.main_fn_id;
        const averages: Record<number, number> = {};

        Object
            .entries(plain.check_in_dates as CheckinDateData)
            .forEach(([day, data]) => {
                const competitorsData = { ...data };
                delete competitorsData[mainHotelId];

                const competitorsCount = Object.keys(competitorsData).length;
                const avgValue = (Object
                    .values(competitorsData as any)
                    .reduce((acc, data) => acc + (data as any).score, 0)) as number;

                averages[+day] = Math.round(avgValue / competitorsCount);
            });

        return averages;
    })
    averages: Record<Day, number> = {};
}
