/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { Expose, Transform } from 'class-transformer';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';

export interface ProgramData extends Record<string, any>{
    status: boolean;
}

interface PromotionsProviderData {
    [hotelId: number]: {
        [day: number]: {
            [promotionName: string]: ProgramData;
        }
    }
}

interface PromotionsData {
    [provider: string]: PromotionsProviderData;
}

export default class PromotionsDocumentModel {
    @Expose()
    @Transform((_, plain) => plain._id)
    documentId!: number;

    @Expose()
    @Transform((_, plain) => plain.status)
    scanStatus!: SCAN_STATUS;

    @Expose()
    @Transform((_, plain) => (plain.update_date ? new Date(plain.update_date) : null))
    lastScanDate!: Date | null;

    @Expose()
    @Transform((_, plain) => {
        if (!plain.checkin_dates) {
            return null;
        }

        return Object.fromEntries(
            Object.entries(plain.checkin_dates as {[day: number]: { [k: string]: string }})
                .map(([day, value]) => [day, value.update_date ? new Date(value.update_date) : null]),
        );
    })
    scanDateByDay!: {
        [day: number]: Date | null
    } | null;

    @Expose()
    @Transform((_, plain) => {
        const providerData = {} as any;

        if (!plain.checkin_dates) return null;

        Object
            .values(plain.checkin_dates)
            .forEach((dayData: any, index) => {
                const day = index + 1;

                Object
                    .entries(dayData)
                    .forEach(([provider, hotels]: [string, any]) => {
                        if (provider === 'update_date') return;

                        Object
                            .entries(hotels)
                            .filter(([_, hotelData]: [string, any]) => !!Object.keys(hotelData).length)
                            .forEach(([hotelId, hotelData]: [string, any]) => {
                                const hasAtLeastOneProgramActive = Object.values(hotelData).some((program: any) => program.status);

                                if (!hasAtLeastOneProgramActive) return;

                                providerData[provider] = providerData[provider] || {};
                                providerData[provider][hotelId] = providerData[provider][hotelId] || {};
                                providerData[provider][hotelId][day] = hotelData;
                            });
                    });
            });

        return providerData;
    })
    providerData!: PromotionsData;

    @Expose()
    @Transform((_, plain) => {
        if (!plain.checkin_dates) return [];

        const hotels = [] as string[];

        Object
            .values(plain.checkin_dates)
            .forEach((dayData: any) => {
                Object
                    .values(dayData)
                    .forEach((d: any) => {
                        if (typeof d !== 'object') return;

                        hotels.push(...Object.keys(d));
                    });
            });

        return Array
            .from(new Set(hotels))
            .map(hid => +hid);
    })
    hotels!: number[];

    @Expose()
    @Transform((_, plain) => {
        if (!plain.checkin_dates) return {};

        const promotions = {} as {
            [provider: string]: string[];
        };

        Object
            .values(plain.checkin_dates)
            .forEach((dayData: any) => {
                Object
                    .entries(dayData)
                    .forEach(([provider, data]: [string, any]) => {
                        if (provider === 'update_date') return;

                        promotions[provider] = promotions[provider] || [];

                        Object
                            .values(data)
                            .forEach((hotelPromotions: any) => {
                                promotions[provider].push(...Object.keys(hotelPromotions));
                            });

                        promotions[provider] = Array.from(new Set(promotions[provider]));
                    });
            });

        return promotions;
    })

    /**
     * All possible promotions for each provider
     */
    promotions!: {
        [provider: string]: string[] | undefined;
    };

    @Expose()
    @Transform((_, plain) => plain.isNewPromotion)
    newPromotions!: {
        [provider: string]: {
            [program: string]: {
                [hotelId: number]: boolean;
            };
        };
    };

    @Expose()
    @Transform((_, plain) => plain.compset_id)
    id!: string | null;

    @Expose()
    @Transform((_, plain) => plain.los)
    los!: number;

    @Expose()
    @Transform((_, plain) => plain.pos)
    pos!: string;

    @Expose()
    @Transform((_, plain) => plain.month)
    month!: number;

    @Expose()
    @Transform((_, plain) => plain.yarn)
    year!: number;

    @Expose()
    @Transform((_, plain) => new Date(plain.update_date))
    updateDate!: Date;
}
