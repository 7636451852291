import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import GRAPH_TYPE from '@/modules/cars/modules/fleet/constants/graph-types.constant';
import CAR_PRICE_TYPE from '@/modules/cars/constants/car-price-type.constant';
import {
    DOORS_ANY,
    MILEAGE_ANY,
    PAYMENT_TERMS_ANY,
    TRANSMISSION_ANY,
} from '@/modules/cars/constants/car-filter-types.constant';
import VEHICLE_TYPE from '@/modules/cars/constants/vehicle-type.constant';
import VEHICLE_TYPE_FILTER from '@/modules/cars/constants/vehicle-type-filter.constant';
import FleetSettingsModel from '@/modules/cars/modules/fleet/models/fleet-settings.model';
import FleetDocumentModel from '../models/fleet-document.model';

@Module
export default class FleetStore extends VuexModule {
    loading: LoadingModel = new LoadingModel();
    graphType: { [provider: string]: GRAPH_TYPE } = {};
    document: FleetDocumentModel = new FleetDocumentModel();

    settings: FleetSettingsModel = {
        assessmentSettings: {
            [CAR_PRICE_TYPE.LOWEST]: [0, 0.05],
            [CAR_PRICE_TYPE.MEDIAN]: [0.01, 0.05],
            [CAR_PRICE_TYPE.HIGHEST]: [-0.05, 0],
        },

        pickUpCityCode: null,
        dataSource: null,
        dataSources: [],
        lor: null,
        pos: null,
        priceType: CAR_PRICE_TYPE.MEDIAN,
        carClasses: [],
        paymentTerms: PAYMENT_TERMS_ANY,
        doors: DOORS_ANY,
        transmission: TRANSMISSION_ANY,
        mileage: MILEAGE_ANY,
        country: null,
        competitors: [],
        currentDocumentCompetitors: null,
        currentDocumentCarClasses: null,
        dataSourceCompetitors: {},
        carClassesChange: false,
        vehicleType: {
            type: VEHICLE_TYPE.VAN,
            filter: VEHICLE_TYPE_FILTER.INCLUDE,
        },
    };
}
