import { inject, injectable } from '@/inversify';
import ASSESSMENT_TYPES from '@/modules/common/constants/assessments-types.constant';
import GRAPH_TYPE from '@/modules/markets/constants/graph-types.constant';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import type Day from '@/modules/common/types/day.type';
import MarketsStore from '@/modules/markets/store/markets.store';
import MarketsApiService, { MarketsApiServiceS, DownloadExcelForm } from '@/modules/markets/markets-api.service';
import MarketsCommonService, { MarketsCommonServiceS } from '@/modules/common/modules/markets/markets-common.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import MarketsFiltersService, { MarketsFiltersServiceS } from '@/modules/markets/markets-filters.service';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import SocketService, { SocketServiceS } from '@/modules/common/modules/socket/socket.service';
import MarketsScanModel from '@/modules/common/modules/socket/models/markets-scan.model';
import CustomNotificationService, { CustomNotificationServiceS } from '@/modules/common/modules/custom-notification/custom-notification.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import MarketSettingsModel from './models/market-settings.model';
import SCAN_STATUS from '../rates/constants/scan-status.constant';
import MarketsDocumentModel from './models/markets-document.model';
import ClusterService, { ClusterServiceS } from '../cluster/cluster.service';

export const MarketsServiceS = Symbol.for('MarketsServiceS');
@injectable()
export default class MarketsService implements Stateable {
    @inject(MarketsFiltersServiceS)
    private marketsFiltersService!: MarketsFiltersService;

    @inject(MarketsApiServiceS)
    private marketsApiService!: MarketsApiService;

    @inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @inject(UserServiceS)
    private userService!: UserService;

    @inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @inject(HelperServiceS)
    private helperService!: HelperService;

    @inject(SocketServiceS)
    private socketService!: SocketService;

    @inject(MarketsCommonServiceS)
    private marketsCommonService!: MarketsCommonService;

    @inject(CustomNotificationServiceS)
    private customNotificationService!: CustomNotificationService;

    @inject(ClusterServiceS)
    private clusterService!: ClusterService;

    readonly storeState: MarketsStore = this.storeFacade.getState('MarketsStore');
    private isForceDocumentLoad = false;

    constructor(storeState: MarketsStore | null = null) {
        if (storeState) {
            this.storeState = storeState;
        } else {
            this.storeFacade.watch(() => [
                this.documentFiltersService.storeState.settings.compsetId,
                this.marketsFiltersService.storeState.settings.provider,
                this.documentFiltersService.storeState.settings.month,
                this.documentFiltersService.storeState.settings.year,
                this.documentFiltersService.settings.pos,
                this.documentFiltersService.settings.los,
                this.userService.currentHotelId,
                this.userService.viewAs,
            ], this.storeState.loading.reset.bind(this.storeState.loading));
        }
        this.socketService.onMarketsScan(this.onScanUpdate.bind(this));
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.providersMarketsDocuments;
    }

    get settings() {
        return this.storeState.settings;
    }

    get providers(): string[] {
        if (!this.compsetsService.currentCompset) {
            return [];
        }
        return this.compsetsService.currentCompset.marketProviders;
    }

    async loadData(docSettings?: DocumentFiltersModel, providers?: string[]): Promise<boolean> {
        let marketProviders: string[] = [];
        const { currentCompset } = this.compsetsService;

        if (providers && providers.length) {
            marketProviders = providers;
        } else {
            if (currentCompset === null) {
                return false;
            }
            // eslint-disable-next-line prefer-destructuring
            marketProviders = currentCompset.marketProviders;
        }

        const settings = docSettings || { ...this.documentFiltersService.storeState.settings };

        const promises = marketProviders
            .map(provider => this.loadDocument(settings!, { provider }));
        const marketsDocuments = await Promise.all(promises);

        this.isForceDocumentLoad = false;
        this.storeState.providersMarketsDocuments = {};

        marketsDocuments.forEach(
            providersMarketsDocument => {
                this.storeState.providersMarketsDocuments = {
                    ...this.storeState.providersMarketsDocuments,
                    ...providersMarketsDocument,
                };
            },
        );

        return true;
    }

    getDocument(provider: string | null = this.marketsFiltersService.currentProvider) {
        if (!provider) {
            return null;
        }

        return this.data[provider];
    }

    getNumberOfHotels(day: Day, hotelId: number, provider?: string) {
        const currentDocument = this.getDocument(provider);
        if (!currentDocument) {
            return null;
        }
        return this.marketsCommonService.getNumberOfHotels(day, hotelId, currentDocument);
    }

    getPosition(day: Day, hotelId: number, provider?: string) {
        const currentDocument = this.getDocument(provider);
        if (!currentDocument) {
            return null;
        }

        return this.marketsCommonService.getPosition(day, hotelId, currentDocument);
    }

    getOldPosition(day: Day, hotelId: number, provider?: string) {
        const currentDocument = this.getDocument(provider);
        if (!currentDocument) {
            return null;
        }

        return this.marketsCommonService.getOldPosition(day, hotelId, currentDocument);
    }

    getMarketScore(provider: string): number| null {
        const document = this.data[provider];
        return document ? document.score : null;
    }

    getCardAssessment(day: Day, provider: string, compsetId?: string): ASSESSMENT_TYPES | null {
        const currentHotelId = this.getCurrentHotelId(compsetId);
        const currentDocument = this.getDocument(provider);
        if (!currentHotelId || !currentDocument) {
            return null;
        }
        return this.marketsCommonService.getCardAssessment(day, currentHotelId, currentDocument);
    }

    getTableAssessment(day: Day, hotelId: number, provider?: string): ASSESSMENT_TYPES | null {
        const { currentHotelId } = this.userService;
        const currentDocument = this.getDocument(provider);
        if (!currentDocument || !currentHotelId) {
            return null;
        }
        return this.marketsCommonService.getTableAssessment(day, currentHotelId, hotelId, currentDocument);
    }

    getGraphType(provider: string) {
        if (!this.storeState.marketGraphType[provider]) {
            return GRAPH_TYPE.RANGE;
        }
        return this.storeState.marketGraphType[provider];
    }

    getDocumentByProvider(provider: string) {
        return this.storeState.providersMarketsDocuments[provider];
    }

    getUpdateDate(provider: string, day: Day) {
        const doc = this.storeState.providersMarketsDocuments[provider];
        if (!doc) return null;

        return this.marketsCommonService.getUpdateDate(day, doc);
    }

    isIdExists(id: number): boolean {
        if (!this.data) {
            return false;
        }
        let check = false;
        Object
            .keys(this.data)
            .forEach(source => {
                if (this.data[source] && this.data[source]!.id === id) {
                    check = true;
                }
            });
        return check;
    }

    checkinDate(day: Day, provider?: string) {
        const currentDocument = this.getDocument(provider);
        if (!currentDocument) {
            return null;
        }
        return this.marketsCommonService.getCheckinDate(day, currentDocument);
    }

    calculateThresholds(positions: number[] | null): [number, number, number] | null {
        return this.marketsCommonService.calculateThresholds(positions);
    }

    minMaxPositions(excludeHotelId?: number, provider?: string): { min: number[], max: number[] } | null {
        const currentDocument = this.getDocument(provider);
        if (!currentDocument) {
            return null;
        }
        return this.marketsCommonService.minMaxPositions(currentDocument, excludeHotelId);
    }

    switchGraphType(provider: string) {
        const graphType = this.getGraphType(provider);
        this.storeState.marketGraphType[provider] = graphType === GRAPH_TYPE.RANGE ? GRAPH_TYPE.HOTELS : GRAPH_TYPE.RANGE;
        this.storeState.marketGraphType = { ...this.storeState.marketGraphType };
    }

    isOutOfRange(provider?: string) {
        const currentDocument = this.getDocument(provider);
        return this.marketsCommonService.isOutOfRange(currentDocument);
    }

    isNoData(day: Day, provider?: string) {
        const currentDocument = this.getDocument(provider);
        return this.marketsCommonService.isNoData(day, currentDocument);
    }

    isNA(day: Day, provider: string, hotelId: number) {
        const currentDocument = this.getDocument(provider);
        return this.marketsCommonService.isNA(day, hotelId, currentDocument);
    }

    isSoldOut(day: Day, hotelId: number, provider?: string) {
        const currentDocument = this.getDocument(provider);
        return this.marketsCommonService.isSoldOut(day, hotelId, currentDocument);
    }

    scanStatus(provider: string) {
        if (!this.data[provider]) {
            return null;
        }

        return this.data[provider]!.scanStatus;
    }

    finishScanDate(provider: string) {
        if (!this.data[provider]) {
            return null;
        }

        return this.data[provider]!.finishScanDate || null;
    }

    dayUpdateDate(provider: string, day: Day) {
        if (!this.data || !this.data[provider]) {
            return null;
        }

        return this.data[provider]!.updateDates[day];
    }

    isScanAvailable(day?: Day): boolean {
        return day ? !this.documentFiltersService.isPreviousDay(day) : !this.documentFiltersService.isPreviousMonth;
    }

    /**
     * Sends request to download excel report and returns an array with providers that doesn't have reports
     */
    async downloadExcel(settings: DownloadExcelForm, toEmail?: boolean): Promise<string[]> {
        const { providers } = settings;
        const errorProviders: string[] = [];

        const promises = providers
            .map(async provider => {
                try {
                    const excelData = await this.marketsApiService
                        .getExcelDocument({ ...settings, provider }, toEmail);

                    if (excelData && !toEmail) {
                        await this.customNotificationService
                            .handleExcel(excelData);
                    }
                } catch (err) {
                    errorProviders.push(provider as string);
                }
            });

        await Promise.all(promises);

        return errorProviders;
    }

    /**
     * Triggers on demand scan on hotel level
     * @param settings trigger options
     * @param day if not passed, whole month will be triggered
     * @returns is successful
     */
    async triggerScan(
        settings: {
            compsetId: string | null,
            provider: string | null,
            los: number | null,
            pos: string | null
        },
        day?: Day,
    ) {
        const { provider } = settings;

        if (provider === null) {
            return false;
        }

        let document = this.storeState.providersMarketsDocuments[provider];

        if (document) {
            document.scanStatus = SCAN_STATUS.IN_PROGRESS;
        } else {
            document = this.defineBlankMarketDocument();
            this.storeState.providersMarketsDocuments[provider] = document;
        }

        const docId = await this.marketsCommonService.triggerScan(settings, day);

        if (docId === null) {
            return false;
        }

        if (!document.id) {
            this.storeState.providersMarketsDocuments[provider] = {
                ...document,
                id: docId,
            };
        }

        return true;
    }

    private onScanUpdate(data: MarketsScanModel) {
        if (this.isIdExists(data.marketsDocumentId)) {
            this.isForceDocumentLoad = true;
            this.storeState.loading.reset();
        }
    }

    private async loadDocument(settings: DocumentFiltersModel, marketSettings: MarketSettingsModel) {
        const { provider } = marketSettings;

        if (!provider) {
            return null;
        }

        if (settings.compsetId === null) {
            return { [provider as any]: null };
        }

        const marketsDocument = await this.marketsApiService
            .getMarketsDocument(settings, marketSettings, this.isForceDocumentLoad);

        return { [provider as any]: marketsDocument && marketsDocument.id ? marketsDocument : null };
    }

    private getCurrentHotelId(compsetId?: string) {
        return compsetId
            ? this.clusterService.getHotelIdByCompset(compsetId)
            : this.userService.currentHotelId;
    }

    private defineBlankMarketDocument() {
        const doc = new MarketsDocumentModel();
        doc!.scanStatus = SCAN_STATUS.IN_PROGRESS;
        doc!.checkinDates = {
            1: {},
        };

        return doc;
    }

    getDayDemand(provider: string, day: Day) {
        const stat = this.storeState.providersMarketsDocuments[provider]?.dayStatistics;
        return stat && stat[day] ? stat[day].demand : null;
    }

    getDayOccupancy(provider: string, day: Day) {
        const stat = this.storeState.providersMarketsDocuments[provider]?.dayStatistics;
        return stat && stat[day] ? stat[day].occupancy : null;
    }
}
