import { Expose, Transform } from 'class-transformer';
import {
    IsNumber, IsString, IsBoolean, IsOptional,
} from 'class-validator';

export default class CarsPriceHistoryDocumentItemModel {
    @Expose()
    @Transform((_, plain) => Number(plain.price_total))
    @IsNumber()
    price!: number;

    @Expose()
    @Transform((_, plain) => plain.car_description)
    @IsString()
    brand!: string;

    @Expose()
    @Transform((_, plain) => plain.sipp_code)
    @IsString()
    sippCode!: string;

    @Expose()
    @Transform((_, plain) => !!plain.price_total)
    @IsBoolean()
    isAvailable!: boolean;

    @Expose()
    @Transform((_, plain) => plain.fuel_type)
    @IsOptional()
    fuelType!: string;

    @Expose()
    @IsString()
    transmission!: string;

    isMainCar: boolean = false;
}
