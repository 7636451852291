import { inject, injectable } from '@/inversify';
import LocationAvailabilityDocumentModel from '@/modules/cars/modules/location-availability/models/location-availability-document.model';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import LocationAvailabilityApiService, { LocationAvailabilityApiServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-api.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import LocationAvailabilityFiltersService, { LocationAvailabilityFiltersServiceS }
    from '@/modules/cars/modules/location-availability/location-availability-filters.service';
import _ from 'lodash';
import LocationAvailabilityStore from './store/location-availability.store';

export const LocationAvailabilityServiceS = Symbol.for('LocationAvailabilityServiceS');
@injectable()
export default class LocationAvailabilityService {
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(LocationAvailabilityApiServiceS) private locationAvailabilityApiService!: LocationAvailabilityApiService;
    @inject(LocationAvailabilityFiltersServiceS) private lAvailabilityFiltersService!: LocationAvailabilityFiltersService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(HelperServiceS) private helperService!: HelperService;

    readonly storeState: LocationAvailabilityStore = this.storeFacade.getState('LocationAvailabilityStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.documentFiltersService.month,
            this.storeState.settings.pickUpLocationIds,
            this.storeState.settings.pos,
        ], this.resetLoading.bind(this));

        this.storeFacade.watch(() => [
            this.storeState.settings.lor,
        ], this.calculateClosedDays.bind(this));
    }

    resetLoading() {
        const { pickUpLocationIds } = this.storeState.settings;
        if (pickUpLocationIds && pickUpLocationIds.length) {
            this.storeState.documents = null;
            this.storeState.loading.reset();
        }
    }

    async loadData(): Promise<boolean> {
        const { settings } = this.documentFiltersService.storeState;
        const locationAvailabilitySettings = this.storeState.settings;
        const { pos, pickUpLocationIds } = locationAvailabilitySettings;
        if (!pos || !pickUpLocationIds || !pickUpLocationIds.length || !settings.month) {
            return false;
        }
        const apiData = { ...locationAvailabilitySettings };
        const data = await this.locationAvailabilityApiService.getNotAvailableDocs(settings, apiData);

        const documents = {} as {
            [locationId: string] : LocationAvailabilityDocumentModel
        };

        data.forEach((location: LocationAvailabilityDocumentModel) => {
            documents[location.locationCode] = location;
        });

        this.storeState.documents = documents;
        this.calculateClosedDays();

        return true;
    }

    get documents() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.documents;
    }

    get closedDays() {
        return this.storeState.closedDays;
    }

    calculateClosedDays() {
        const closedDays = { } as {
            [location: string]: {
                [provider: string]: number
            }
        };

        const { providersList } = this.lAvailabilityFiltersService;
        const documents = this.documents || {};

        Object.keys(documents).forEach(locationId => {
            const pickupDays = documents[locationId].pickupDates;
            closedDays[locationId] = {} as any;
            if (pickupDays) {
                Object.keys(pickupDays).forEach(day => {
                    providersList.forEach(provider => {
                        if (!closedDays[locationId][provider]) {
                            closedDays[locationId][provider] = 0;
                        }
                        const status = this.getLocationsAvailability(locationId, provider, day);
                        if (status === true) {
                            closedDays[locationId][provider] += 1;
                        }
                    });
                });
            }
        });

        this.storeState.closedDays = closedDays;
    }

    getLocationsAvailability(locationId: string, provider: string, day: string) {
        const { lor } = this.storeState.settings;
        const noDataStatus = null;
        const key = `${locationId}.pickupDates.${day}.${provider}.${lor}`;
        return _.get(this.documents, key, noDataStatus) as boolean | null;
    }
}
