import { Expose, Transform } from 'class-transformer';
import {
    IsString, IsOptional, IsNumber, IsArray,
} from 'class-validator';

interface IPath {
    [dataSource: string]: {
        [pos: string]: {
            [lok: string]: boolean,
        }
    }
}

interface ITheme {
    logo: string | null;
    primaryColor: string | null;
    secondaryColor: string | null;
    icons: string | null;
}

export default class FleetFiltersModel {
    @Expose()
    @Transform((_, plain) => plain.pick_up_cities)
    @IsString({ each: true })
    @IsOptional()
    pickUpCities: string[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.data_sources.sort())
    @IsString({ each: true })
    @IsOptional()
    dataSources: string[] | null = null;

    @Expose()
    @Transform((_, plain) => (plain.lor && Array.isArray(plain.lor) ? plain.lor.map(Number).sort((a: number, b: number) => a - b) : []))
    @IsNumber({}, { each: true })
    @IsOptional()
    lor: number[] | null = null;

    @Expose()
    @IsString({ each: true })
    @IsOptional()
    pos: string[] | null = null;

    @Expose()
    @Transform((_, plain) => {
        if (!plain.locations) {
            return null;
        }

        return plain.locations.map((location: {
            // eslint-disable-next-line camelcase
            fn_location_id: string,
            // eslint-disable-next-line camelcase
            location_name: string,
            // eslint-disable-next-line camelcase
            geo_location: number[],
        }) => ({
            locationId: location.fn_location_id,
            locationName: location.location_name,
            geoLocation: location.geo_location,
        }));
    })
    @IsArray()
    locations: {
        locationId: string,
        locationName: string,
        geoLocation: number[]
    }[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.country_locations)
    countries: {
        [country: string]: string[],
    } | null = null;

    @Expose()
    @Transform((_, plain) => (
        plain.routes.map((route: {
            pos: string[],
            // eslint-disable-next-line camelcase
            fn_pick_up_location_code: string,
        }) => ({
            pos: route.pos,
            pickUpLocationId: route.fn_pick_up_location_code,
        }))
    ))
    routes: {
        pos: string[],
        pickUpLocationId: string,
    }[] | null = null;

    @Expose()
    @Transform((_, plain) => (
        plain.grouped_availability.map((group: {
            // eslint-disable-next-line no-underscore-dangle
            _id: string,
            path: IPath
        }) => ({
            // eslint-disable-next-line no-underscore-dangle
            id: group._id,
            path: group.path,
        }))
    ))
    availability: {
        path: IPath
        id: string
    }[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.mileage)
    mileage: string[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.transmission)
    transmission: string[] | null = null;

    @Expose()
    doors: string[] | null = null;

    @Expose()
    paymentTerms: string[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.car_class)
    carClass: string[] | null = null;

    @Expose()
    @Transform((_, plain) => {
        if (Object.keys(plain.theme).length === 0) {
            return null;
        }
        return plain.theme;
    })
    theme: ITheme | null = null;

    @Expose()
    allowedBrands: string[] | null = null;

    @Expose()
    @Transform((_, plain) => {
        const providers = plain.data_sources.sort().filter((item: string) => item !== 'Brand');
        return [...providers, ...plain.allowedBrands];
    })
    providers: string[] | null = null;
}
