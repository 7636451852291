import {
    Container, injectable, interfaces, decorate,
} from 'inversify';
import type { Constructor, Id } from '@/inversify.types';

const idsCache: Record<string, Id> = {};

function addIdToCache(id: Id, name: string): Id {
    const existingId = idsCache[name];

    if (existingId) {
        return existingId;
    }

    // Adds also 'I' for compatibility with interfaces
    idsCache[`I${name}`] = id;
    idsCache[name] = id;

    return id;
}

export class CachableContainer extends Container {
    addSingleton<T>(constructor: Constructor<T>, customId: Id): interfaces.BindingWhenOnSyntax<T> {
        const id = addIdToCache(customId, customId.toString());

        try {
            decorate(injectable(), constructor);
        } catch (e) {
            if ((e as Error).message !== 'Cannot apply @injectable decorator multiple times.') {
                throw e;
            }
        }

        return super.bind<T>(id).to(constructor).inSingletonScope();
    }
}

export const container = new CachableContainer({ skipBaseClassChecks: true });
