import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import { StoreS, StoreType } from '@/store/types';
import { inject, injectable } from '@/inversify';
import FleetHistoryStore from '@/modules/cars/modules/cars-price-history/store/fleet-history.store';
import { DEFAULT_GRAPH_COLORS as GRAPH_COLORS } from '@/modules/common/constants/default-graph-colors.constant';

export const FleetHistoryServiceS = Symbol.for('FleetHistoryServiceS');
@injectable()
export default class FleetHistoryService implements Stateable {
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(StoreS) private store!: StoreType;

    readonly storeState: FleetHistoryStore = this.storeFacade.getState('FleetHistoryStore');

    getBrokersGraphColor(brokerId: number) {
        return GRAPH_COLORS[brokerId];
    }
}
