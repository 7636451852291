import { Route, RouteConfig } from 'vue-router';
import { container } from '@/inversify.container';
import use from '@/router/use';
import { CommonMenuRoutes } from './common.routes';
import { ScheduledReports as RatesScheduledReports } from './hotel/rates.routes';
import { ScheduledReports as GuestReviewScheduledReports } from './hotel/ranking.routes';
import { ScheduledReports as MarketsScheduledReports } from './hotel/markets.routes';
import { ScheduledReports as DILiteScheduledReports } from './hotel/di-lite.routes';
import { ClusterScheduledReports as ClusterRatesScheduledReports } from './cluster/rates.routes';
import { ScheduledReports as ClusterGuestReviewsScheduledReports } from './cluster/ranking.routes';
import { ScheduledReports as ClusterMarketsScheduledReports } from './cluster/markets.routes';
import ViewAsMiddleware, { ViewAsMiddlewareS } from '../middlewares/view-as.middleware';

function AnalysisScheduledReports(prefix: string) {
    return {
        name: `${prefix}.scheduled-reports`,
        path: 'scheduled-reports',
        component: () => import('@/modules/rates/pages/analysis/analysis-report.modal.page.vue'),
        meta: { modal: true },
    };
}

function DownloadMappingExcel(prefix: string) {
    return {
        name: `${prefix}.download-excel-popup`,
        path: 'download-excel',
        component: () => import('@/modules/settings/components/download-mapping.popup.vue'),
        meta: { modal: true },
    };
}

export default function SettingsPageRoutes() {
    const toScheduledRoute = (method: (n: string) => RouteConfig, type: string, level: string) => {
        const child = method('');
        child.path = `edit-${type}-report/:reportId`;
        child.name = `${level}.settings.${type}-edit-report`;

        return child;
    };

    return {
        path: '/settings',
        beforeEnter: use([container.get<ViewAsMiddleware>(ViewAsMiddlewareS)]),
        component: () => import('@/modules/common/pages/transition.page.vue'),
        props: (r: Route) => ({
            hotelId: Number(r.params.hotelId),
        }),

        children: [
            {
                name: 'cluster.settings',
                component: () => import('@/modules/settings/pages/general.page.vue'),
                path: '/settings/general',
                children: [
                    ...CommonMenuRoutes('cluster.settings'),

                    toScheduledRoute(RatesScheduledReports, 'rate', 'cluster'),
                    toScheduledRoute(AnalysisScheduledReports, 'ratescomparemode', 'cluster'),
                    toScheduledRoute(GuestReviewScheduledReports, 'guestreviews', 'cluster'),
                    toScheduledRoute(MarketsScheduledReports, 'market', 'cluster'),
                    toScheduledRoute(DILiteScheduledReports, 'dilight', 'cluster'),

                    toScheduledRoute(ClusterRatesScheduledReports, 'cluster-rate', 'cluster'),
                    toScheduledRoute(ClusterGuestReviewsScheduledReports, 'cluster-guestreviews', 'cluster'),
                    toScheduledRoute(ClusterMarketsScheduledReports, 'cluster-market', 'cluster'),
                ],
            }, {
                name: 'hotel.settings',
                component: () => import('@/modules/settings/pages/hotel.page.vue'),
                path: '/settings/hotel/:hotelId',
                children: [
                    ...CommonMenuRoutes('hotel.settings'),
                    DownloadMappingExcel('hotel.settings'),

                    toScheduledRoute(RatesScheduledReports, 'rate', 'hotel'),
                    toScheduledRoute(AnalysisScheduledReports, 'ratescomparemode', 'hotel'),
                    toScheduledRoute(GuestReviewScheduledReports, 'guestreviews', 'hotel'),
                    toScheduledRoute(MarketsScheduledReports, 'market', 'hotel'),
                    toScheduledRoute(DILiteScheduledReports, 'dilight', 'hotel'),

                    toScheduledRoute(ClusterRatesScheduledReports, 'cluster-rate', 'hotel'),
                    toScheduledRoute(ClusterGuestReviewsScheduledReports, 'cluster-guestreviews', 'hotel'),
                    toScheduledRoute(ClusterMarketsScheduledReports, 'cluster-market', 'hotel'),
                ],
            },
        ],
    };
}
