import { Expose, plainToClass, Transform } from 'class-transformer';
import { IsNumber, IsOptional, Validate } from 'class-validator';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import type Day from '@/modules/common/types/day.type';
import PriceHistoryTrendDataValidator from './validators/trend-data.validator';
import ICheckinDate from '../../rates/interfaces/checkin-date.interface';

export default class RatesPriceHistoryModel {
    @Expose()
    @Transform((_, plain) => plain.day)
    @IsNumber()
    @IsOptional()
    requestDay: Day | null = null;

    @Expose()
    @Transform((_, plain) => {
        const trendData: any = {};

        if (!plain.trend_data) {
            return plain.trend_data;
        }

        Object.keys(plain.trend_data).forEach(date => {
            trendData[date] = { hotels: {} };
            const td = trendData[date];
            const plainTd = plain.trend_data[date];

            Object.keys(plainTd).forEach(hotelId => {
                if (hotelId !== 'day_statistics') {
                    td.hotels[hotelId] = { rooms: {}, link: null };
                    const hotel = td.hotels[hotelId];
                    const plainHotel = plain.trend_data[date][hotelId];

                    if (plainHotel.rooms) {
                        Object.keys(plainHotel.rooms).forEach(roomTypeId => {
                            hotel.rooms[roomTypeId] = [];
                            hotel.updateDate = plainHotel.update_date;
                            hotel.losRestriction = plainHotel.los_restricted;
                            hotel.link = plainHotel.link;
                            const ratesDocumentItems: RatesDocumentItemModel[] = [];
                            hotel.rooms[roomTypeId] = ratesDocumentItems;

                            Object
                                .entries(plainHotel.rooms[roomTypeId])
                                .forEach(([__, ratesDocumentItem]) => {
                                    const roomInstance = plainToClass(
                                        RatesDocumentItemModel,
                                        ratesDocumentItem,
                                        { excludeExtraneousValues: true },
                                    );
                                    ratesDocumentItems.push(roomInstance);
                                });
                        });
                    }
                }

                const { day_statistics: dayStatistics } = plainTd;
                td.occupancy = null;
                td.demand = null;

                if (!dayStatistics) return;

                if (Number.isFinite(dayStatistics.demand)) {
                    td.demand = dayStatistics.demand < 0 ? 0 : Math.round(dayStatistics.demand * 100);
                }

                if (Number.isFinite(dayStatistics.occupancy)) {
                    td.occupancy = dayStatistics.occupancy < 0 ? 0 : Math.round(dayStatistics.occupancy);
                }
            });
        });

        return trendData;
    })
    @Validate(PriceHistoryTrendDataValidator)
    trendData!: {
        [date: string]: ICheckinDate | null;
    };
}
