/* eslint-disable no-param-reassign */
import { Expose, Transform, plainToClass } from 'class-transformer';
import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';
import { camelCase, isDate } from 'lodash';
import { isDateString } from 'class-validator';
import DeepAnalysisDocumentModel from './deep-analysis-document.model';

export default class DeepAnalysisItemModel {
    @Expose()
    @Transform((_, plain) => {
        if (!plain.details || !Object.keys(plain.details).length) {
            return null;
        }

        const { document } = plainToClass(DeepAnalysisDocumentModel, plain.details);

        return document;
    })
    providers!: DeepAnalysisDocumentModel['document'];

    @Expose()
    @Transform((_, plain) => (plain.details && plain.details.currency ? plain.details.currency : null))
    currency!: string | null;

    @Expose()
    @Transform((_, plain) => plain.update_date)
    updateDate!: Date;
}
