import { Expose, plainToClass, Transform } from 'class-transformer';

export default class BranchesModel {
    @Expose()
    @Transform((_, plain) => {
        const subordinatesRaw = plain.subordinates;

        if (!subordinatesRaw) {
            return [];
        }

        const subordinates: BranchesModel[] = [];
        subordinatesRaw.forEach((item: BranchesModel) => {
            const data = plainToClass(
                BranchesModel,
                <BranchesModel> item,
                { excludeExtraneousValues: true },
            );
            subordinates.push(data);
        });
        return subordinates;
    })
    childChains?: BranchesModel[] | null = null;

    @Expose()
    @Transform((_, plain) => plain.chainId)
    chainId: string | null = null;

    @Expose()
    @Transform((_, plain) => plain.chainName)
    chainName: string | null = null;

    @Expose()
    @Transform((_, plain) => {
        if (plain.theme) {
            return plain.theme.logo;
        }
        return null;
    })
    logo!: Date;

    @Expose()
    @Transform((_, plain) => plain.isCurrent || false)
    isCurrent: boolean = false;

    @Expose()
    @Transform((_, plain) => plain.isBroker || false)
    isBroker: boolean = false;
}
