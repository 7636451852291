import NotFoundException from '@/modules/common/modules/exception-handler/exceptions/not-found.exception';
import { inject, injectable } from '@/inversify';
import ErrorException from '@/modules/common/modules/exception-handler/exceptions/error.exception';
import UnauthorizedException from '@/modules/common/modules/exception-handler/exceptions/unauthorized.exception';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import ForbiddenException from '@/modules/common/modules/exception-handler/exceptions/forbidden.exception';
import GatewayTimeoutException from '@/modules/common/modules/exception-handler/exceptions/gateway-timeout.exception';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import ResponseValidationException from '@/modules/common/modules/exception-handler/exceptions/response-validation.exception';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';

export const ExceptionHandlerS = Symbol.for('ExceptionHandlerS');
@injectable()
export default class ExceptionHandler {
    @inject(AuthServiceS) private authService!: AuthService;
    @inject(NotifyServiceS) private notifyService!: NotifyService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(OpenTelemetryServiceS) private otelService!: OpenTelemetryService;

    constructor() {
        this.storeFacade.subscribeAction(action => {
            this.catch(action.payload);
        });
    }

    catch(exception: ErrorException) {
        this.otelService.setErrorToActiveSpans(exception, true);
        if (exception instanceof UnauthorizedException) {
            this.authService.logout();
        } else if (exception instanceof ForbiddenException) {
            // this.authService.logout();
            // this.notifyService.error();
        } else if (exception instanceof GatewayTimeoutException) {
            this.notifyService.error();
        } else if (exception instanceof ResponseValidationException) {
            this.notifyService.error();
        } else if (exception instanceof NotFoundException) {
            this.notifyService.error(exception.message);
        } else {
            this.notifyService.error();
        }

        // eslint-disable-next-line no-console
        console.error('exception', exception);
    }
}
