import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import type Day from '../types/day.type';

/**
 * Returns scan range for API requests
 */
const getScanRange = (filterSettings: DocumentFiltersModel, day?: Day): [Date, Date | undefined] => {
    const startDate = new Date(filterSettings.year, filterSettings.month, day || 1);

    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();

    if (currentMonth === filterSettings.month && currentYear === filterSettings.year && !day) {
        today.setHours(today.getHours() + today.getTimezoneOffset() / 60);
        startDate.setDate(today.getDate());
    }

    const endDate = day
        ? new Date(startDate.getFullYear(), startDate.getMonth(), day + 1)
        : undefined;

    return [startDate, endDate];
};
export default getScanRange;
