const injectables = [
    'StoreFacade',
    'Store',
    'ApiService',
    'ExceptionHandler',
    'ConfigService',
    'HotelRateTrendsService',
    'RateValueScoreApiService',
    'RateValueScoreSerivce',
    'RVSCalendarService',
    'RVSOvertimeService',
    'RVSScoreService',
] as const;

export const KEY = Object.fromEntries(injectables.map(key => [key, Symbol.for(key)])) as Record<typeof injectables[number], symbol>;
