import { inject, injectable } from '@/inversify';
import { Route } from 'vue-router';
import { IMiddleware } from '@/router/interfaces/middleware.interface';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';

export const DownloadExcelDirectlyMiddlewareS = Symbol.for('DownloadExcelDirectlyMiddlewareS');
@injectable()
export default class DownloadExcelDirectlyMiddleware implements IMiddleware {
    @inject(RatesServiceS) private ratesService!: RatesService;
    @inject(UserServiceS) private userService!: UserService;
    @inject(AuthServiceS) private authService!: AuthService;

    private readonly homePage = '/';

    async canActivate(to: Route, from: Route) {
        await this.authService.isLogin();

        if (this.userService.isCarUser) {
            return this.homePage;
        }

        const { compsetId, dateStart, dateEnd } = to.params;
        const query = {
            providers: to.query.providers as string,
            mealTypeId: to.query.mealTypeId as string,
            priceType: to.query.priceType as string,
            roomTypeId: to.query.roomTypeId as string,
            numberOfGuests: to.query.numberOfGuests as string,
            los: to.query.los as string,
            pos: to.query.pos as string,
        };

        try {
            await this.ratesService.downloadExcelDirectly(compsetId, dateStart, dateEnd, query);
        } catch {
            return this.homePage;
        }
        return this.homePage;
    }
}
