import STATISTIC_TYPE from '@/modules/deep-analysis/constants/statistic-type.constant';

const STATISTIC_NAMES = {
    [STATISTIC_TYPE.REVENUE]: 'deepcomp.stat.revenue',
    [STATISTIC_TYPE.REVENUE_SHARE]: 'deepcomp.stat.revshare',
    [STATISTIC_TYPE.NET_ADR]: 'deepcomp.stat.netadr',
    [STATISTIC_TYPE.ROOM_NIGHTS]: 'deepcomp.stat.netnight',
    [STATISTIC_TYPE.CANCELLATION]: 'deepcomp.stat.cancel',
    [STATISTIC_TYPE.AVG_LOS]: 'deepcomp.stat.avglos',
    [STATISTIC_TYPE.PAGE_VIEWS]: 'deepcomp.stat.pageview',
    [STATISTIC_TYPE.PAGE_VIEWS_SHARE]: 'deepcomp.stat.pageviewshare',
    [STATISTIC_TYPE.GROSS_CONVERSION]: 'deepcomp.stat.grosconv',
    [STATISTIC_TYPE.PACKAGE_SHARE]: 'deepcomp.stat.packshare',
    [STATISTIC_TYPE.AVERAGE_BOOKING_WINDOW]: 'deepcomp.stat.avgbookwin',
    [STATISTIC_TYPE.CORPORATE_TRAVEL_SHARE]: 'deepcomp.stat.corpshare',
    [STATISTIC_TYPE.INTERNATIONAL_SHARE]: 'deepcomp.stat.intershare',
    [STATISTIC_TYPE.PROMOTION_SHARE]: 'deepcomp.stat.promshare',
    [STATISTIC_TYPE.CONVERSION]: 'deepcomp.stat.convbook',
};

export default STATISTIC_NAMES;
