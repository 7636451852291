import { inject, injectable } from '@/inversify';
import RatesAnalysisFiltersService, { RatesAnalysisFiltersServiceS } from '@/modules/rates/rates-analysis-filters.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import RatesStore from '@/modules/rates/store/rates.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import ASSESSMENTS_TYPES from '@/modules/common/constants/assessments-types.constant';
import NON_REQUESTABLE_FIELDS from '@/modules/rates/constants/non-request-fields.constant';
import type Day from '@/modules/common/types/day.type';
import Percent from '@/modules/common/types/percent.type';
import Price from '@/modules/common/types/price.type';
import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesApiService, { RatesApiServiceS } from '@/modules/rates/rates-api.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesService, { RatesServiceS } from '@/modules/rates/rates.service';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import UserSettingsService, { UserSettingsS } from '@/modules/user/user-settings.service';
import PRICE from '../common/modules/rates/constants/price.enum';
import HotelRooms from '../common/interfaces/hotelRooms.interface';
import PRICE_SHOWN from './constants/price-shown.constant';
import type RatesSettingsModel from './models/rates-settings.model';
import RatesDocumentItemModel from './models/rates-document-item.model';

interface RatesAnalysisServicePublicInterface {
    /**
     * The first compare document
     */
    document: RatesDocumentModel | null;

    /**
     * Settings for the first compare document
     */
    settings: RatesSettingsModel;

    /**
     * List of compare documents
     */
    data: (RatesDocumentModel | null)[];

    /**
     * Loading state of the service
     */
    isLoading: boolean;

    /**
     * Documents currency
     */
    currency: string | null;

    /**
     * True when `data` property have more than one document
     */
    isMultiDocMode: boolean;

    /**
     * Returns true if no compare data was found for the given day
     */
    isNoComparisonData(day: Day, hotelId: number): boolean;

    /**
     * Returns biggest percent of comparison difference with its document index
     */
    getBiggestComparisonDifference(day: Day, priceShown?: PRICE_SHOWN): {
        percent: number | null;
        docIndex: number;
        assessment: ASSESSMENTS_TYPES | null;
    };

    /**
     * Returns document by compare value
     */
    getDocumentByComparisonValue(value: number): RatesDocumentModel | null;

    /**
     * Returns document by compare filter
     */
    getDocumentByComparisonName(name: string): RatesDocumentModel | null;

    /**
     * Returns settings by compare value
     */
    getSettingsByComparisonValue(value: number): RatesSettingsModel;

    /**
     * Returns settings by compare filter
     */
    getSettingsByComparisonName(name: string): RatesSettingsModel;

    /**
     * Returns settings by document index
     */
    getSettings(documentIndex?: number): RatesSettingsModel;

    /**
     * Returns assessment type for the given day by price
     */
    getCardAssessment(day: Day, priceShown: PRICE_SHOWN | null, documentIndex?: number): ASSESSMENTS_TYPES | null;

    /**
     * Returns price for Hotel Room by its `hotelId` (default: current user hotel)
     */
    getPrice(day: Day, hotelId?: number | string, priceShown?: PRICE_SHOWN, documentIndex?: number): number | null;

    /**
     * Returns compset price for specified day and document (first document by default)
     */
    getCompsetPrice(day: Day, priceShown?: PRICE_SHOWN, documentIndex?: number): number | null;

    /**
     * Returns all rooms for specified day and document (first document by default)
     */
    getAllRooms(day: Day, documentIndex?: number): HotelRooms;

    /**
     * Returns all competitors rooms for specified day and document (first document by default)
     */
    getCompetitorsRooms(day: Day, documentIndex?: number): HotelRooms;

    /**
     * Returns difference between compset prices of main document and specified document (first document by default)
     */
    getCompsetDifference(day: Day, priceShown?: PRICE_SHOWN, documentIndex?: number): number;

    /**
     * Returns difference percent between compset price and price of main hotel from specified document (first document by default)
     */
    getDifferenceBetweenCompsetAndMain(day: Day, priceShown?: PRICE_SHOWN, documentIndex?: number): number;

    /**
     * Returns room difference between main document and specified document (first document by default)
     */
    getRoomDifference(day: Day, hotelId: number | string, raw?: boolean, priceShown?: PRICE_SHOWN, documentIndex?: number): number;

    /**
     * Returns room for specified day and document (first document by default)
     */
    getRoom(day: Day, hotelId: number, priceShown?: PRICE_SHOWN, documentIndex?: number): RatesDocumentItemModel | null;

    /**
     * Returns hotel's los restriction state for specified day and document (first document by default)
     */
    getHotelLosRestriction(day: Day, hotelId?: number, documentIndex?: number): number | null;

    /**
     * Returns true if all documents have no data for the given day
     */
    isAllNoData(day: Day): boolean;

    /**
     * Returns true if no data was found for the given day
     */
    isNoData(day: Day, documentIndex?: number): boolean;

    /**
     * Returns true if data not available for the given day and hotel
     */
    isNA(day: Day, hotelId: number, priceShown?: PRICE_SHOWN, documentIndex?: number): boolean;

    /**
     * Returns true room for specified hotel is sold out for specified day and document (first document by default)
     */
    isSoldOut(day: Day, hotelId: number, priceShown?: PRICE_SHOWN, documentIndex?: number): boolean;

    /**
     * Return true if specified document is empty
     */
    isOutOfRange(documentIndex?: number): boolean;

    /**
     * Return true if all documents are empty
     */
    isAllOutOfRange(): boolean;

    /**
     * Resets loading state of the service
     */
    resetLoading(): void;
}

export const RatesAnalysisServiceS = Symbol.for('RatesAnalysisServiceS');
@injectable()
export default class RatesAnalysisService implements Stateable, RatesAnalysisServicePublicInterface {
    @inject(RatesApiServiceS)
    private ratesApiService!: RatesApiService;

    @inject(RatesCommonServiceS)
    private ratesCommonService!: RatesCommonService;

    @inject(RatesServiceS)
    private ratesService!: RatesService;

    @inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @inject(UserServiceS)
    private userService!: UserService;

    @inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @inject(HelperServiceS)
    private helperService!: HelperService;

    @inject(RatesAnalysisFiltersServiceS)
    private ratesAnalysisFiltersService!: RatesAnalysisFiltersService;

    @inject(UserSettingsS)
    private userSettingsService!: UserSettingsService;

    readonly storeState: RatesStore = this.storeFacade.getState('RatesStore');

    constructor() {
        const { comparisonFilter } = this.storeState.analysis.settings;

        this.storeFacade.watch(() => [
            comparisonFilter.key,
            comparisonFilter.values,
            this.ratesService.isLoading,
        ], () => {
            this.storeState.analysis.loading.reset();
        });
    }

    get document() {
        const { comparisonValues } = this.ratesAnalysisFiltersService;

        if (comparisonValues.length) {
            return this.getDocumentByComparisonValue(comparisonValues[0].value);
        }

        return null;
    }

    get settings() {
        return this.getSettings();
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.analysis.loading, this.loadData.bind(this));
        return this.storeState.analysis.documents;
    }

    get isLoading() {
        return this.storeState.analysis.loading.isLoading();
    }

    get currency() {
        return this.data.find(doc => doc?.currency)?.currency || null;
    }

    get isMultiDocMode() {
        return this.data.length > 1
            && this.ratesAnalysisFiltersService.comparisonValues.length > 1;
    }

    isNoComparisonData(day: Day, hotelId: number) {
        const { priceShown } = this.documentFiltersService.settings;
        const { comparisonValues } = this.ratesAnalysisFiltersService;

        const mainDoc = this.ratesService.isOutOfRange()
            || this.ratesService.isNoData(day)
            || this.ratesService.isNA(day, hotelId)
            || this.ratesService.isSoldOut(day, hotelId, priceShown);

        if (mainDoc) return true;

        const compareDoc = comparisonValues.every((_, docIndex) => this.isOutOfRange(docIndex)
            || this.isNoData(day, docIndex)
            || this.isNA(day, hotelId, priceShown, docIndex)
            || this.isSoldOut(day, hotelId, priceShown, docIndex));

        return compareDoc;
    }

    getBiggestComparisonDifference(day: Day, priceShown?: PRICE_SHOWN) {
        const actualPriceShown = priceShown || this.documentFiltersService.settings.priceShown;

        const competitionPercents = this.ratesAnalysisFiltersService.comparisonValues
            .map((_, docIndex) => this.getCompetitionPercent(day, actualPriceShown, docIndex));

        if (competitionPercents.length === 1) {
            return {
                percent: competitionPercents[0],
                docIndex: 0,
                assessment: this.getCardAssessment(day, actualPriceShown, 0),
            };
        }

        let maxPercent = 0;
        let docIndex = 0;

        competitionPercents.forEach((percent, i) => {
            if (percent === null) return;

            const abs = Math.abs(percent || 0);

            if (abs < maxPercent) {
                return;
            }

            maxPercent = abs;
            docIndex = i;
        });

        const assessment = this.getCardAssessment(day, actualPriceShown, docIndex);

        return {
            percent: competitionPercents[docIndex],
            docIndex,
            assessment,
        };
    }

    getDocumentByComparisonValue(value: string | number) {
        if (NON_REQUESTABLE_FIELDS.includes(this.ratesAnalysisFiltersService.comparisonKey)) {
            return this.ratesService.data as RatesDocumentModel;
        }

        const index = this.ratesAnalysisFiltersService.comparisonValues.findIndex(x => x.value === value);
        if (index === -1) {
            return null;
        }
        return this.data && this.data[index] ? this.data[index] : null;
    }

    getDocumentByComparisonName(value: string) {
        if (NON_REQUESTABLE_FIELDS.includes(this.ratesAnalysisFiltersService.comparisonKey)) {
            return this.ratesService.data as RatesDocumentModel;
        }

        const index = this.ratesAnalysisFiltersService.comparisonValues
            .findIndex(x => x.name.toLocaleLowerCase() === value.toLocaleLowerCase());
        if (index === -1) {
            return null;
        }
        return this.data && this.data[index] ? this.data[index] : null;
    }

    getSettings(documentIndex = 0) {
        const { settings: mainSettings } = this.ratesService;
        const { comparisonKey: key } = this.ratesAnalysisFiltersService;
        const { comparisonValues } = this.ratesAnalysisFiltersService;

        if (!comparisonValues.length) {
            return mainSettings;
        }

        const { value } = this.ratesAnalysisFiltersService.comparisonValues[documentIndex];

        if (key === 'diffDays') {
            return mainSettings;
        }

        return {
            ...mainSettings,
            [key]: value,
        };
    }

    getSettingsByComparisonValue(value: string | number) {
        if (this.ratesAnalysisFiltersService.comparisonValues.length) {
            return {
                ...this.ratesService.settings,
                [this.ratesAnalysisFiltersService.comparisonKey]: value,
            };
        }
        return this.ratesService.settings;
    }

    getSettingsByComparisonName(value: string) {
        const { comparisonValues, comparisonKey } = this.ratesAnalysisFiltersService;
        const data = comparisonValues
            .find(x => x.name.toLocaleLowerCase() === value.toLocaleLowerCase());

        if (comparisonValues.length && data) {
            return {
                ...this.ratesService.settings,
                [comparisonKey]: data.value,
            };
        }

        return this.ratesService.settings;
    }

    getCardAssessment(day: Day, priceShown: PRICE_SHOWN | null, documentIndex = 0) : ASSESSMENTS_TYPES | null {
        const { currentCompset } = this.compsetsService;

        if (!currentCompset) {
            return null;
        }

        const percent = this.getCompetitionPercent(day, priceShown, documentIndex);

        if (percent === null) return null;

        return this.ratesCommonService
            .getCardAssessment(percent, currentCompset);
    }

    getPrice(day: Day, hotelId?: number | string, priceShown?: PRICE_SHOWN, documentIndex = 0) {
        const hid = hotelId || this.userService.currentHotelId;
        if (!hid || !this.settings) return null;

        const fallbackPriceShown = this.documentFiltersService.priceShown;

        const doc = this.data[documentIndex];

        return this.ratesCommonService
            .getPrice(day, hid, doc, priceShown || fallbackPriceShown);
    }

    getCompsetPrice(day: Day, priceShown?: PRICE_SHOWN, documentIndex = 0) {
        const compset = this.compsetsService.currentCompset;
        if (!compset) return null;

        const competitorRooms = this.getCompetitorsRooms(day, documentIndex);
        if (!competitorRooms) return null;

        const actualPriceShown = priceShown || this.documentFiltersService.priceShown;

        return this.ratesCommonService.getCompsetPrice(competitorRooms, compset.type, actualPriceShown);
    }

    getAllRooms(day: Day, documentIndex = 0): HotelRooms {
        const doc = this.data[documentIndex];

        return this.ratesCommonService.getAllRooms(day, doc);
    }

    getCompetitorsRooms(day: Day, documentIndex = 0) {
        const allRooms = this.getAllRooms(day, documentIndex);
        const hotelId = this.userService.currentHotelId;

        const { competitors } = this.documentFiltersService;

        if (!hotelId || !competitors) return {};

        const entries = competitors
            .map(hid => [hid, allRooms[hid]]);

        return Object.fromEntries(entries) as HotelRooms;
    }

    getDifferenceBetweenCompsetAndMain(day: Day, priceShown?: PRICE_SHOWN, documentIndex = 0) {
        const mainPrice = this.getPrice(day, undefined, priceShown);
        const analysisCompsetPrice = this.getCompsetPrice(day, priceShown, documentIndex);

        if (!mainPrice || !analysisCompsetPrice) return 0;

        return (mainPrice - analysisCompsetPrice) / (analysisCompsetPrice || 1);
    }

    getCompsetDifference(day: Day, priceShown?: PRICE_SHOWN, documentIndex = 0) {
        const currentCompsetPrice = this.ratesService.getCompsetPrice(day, undefined, undefined, priceShown);
        const analysisCompsetPrice = this.getCompsetPrice(day, priceShown, documentIndex);

        if (!currentCompsetPrice || !analysisCompsetPrice) return 0;

        return (currentCompsetPrice - analysisCompsetPrice) / (analysisCompsetPrice || 1);
    }

    getRoomDifference(day: Day, hotelId: number | string, raw: boolean = false, priceShown?: PRICE_SHOWN, documentIndex = 0) {
        const actualPriceShown = priceShown || this.documentFiltersService.priceShown;
        const currentRoom = this.ratesService.getPrice(day, hotelId, actualPriceShown);
        const analysisRoom = this.getPrice(day, hotelId, actualPriceShown, documentIndex);

        if (currentRoom === -1) return 0;
        if (analysisRoom === -1) return 0;

        if (!currentRoom || !analysisRoom) return 0;

        const difference = currentRoom - analysisRoom;
        const divider = raw
            ? 1
            : analysisRoom || 1;

        return difference / divider;
    }

    getRoom(day: Day, hotelId: number, priceShown?: PRICE_SHOWN, documentIndex = 0) {
        const doc = this.data[documentIndex];

        return this.ratesCommonService
            .getRoom(day, hotelId, doc, priceShown);
    }

    getTableAssessment(price: number, day: Day, documentIndex = 0): ASSESSMENTS_TYPES | null {
        const { currentHotelId: mainHotelId } = this.userService;
        const { currentCompset: compset } = this.compsetsService;
        const doc = this.data[documentIndex];

        return this.ratesCommonService
            .getTableAssessment(price, day, mainHotelId!, compset, doc);
    }

    getHotelLosRestriction(day: Day, hotelId?: number, documentIndex = 0) {
        const hid = hotelId || this.userService.currentHotelId;

        return this.ratesCommonService.getHotelLosRestriction(day, hid!, this.data[documentIndex]);
    }

    getHotelName(hotelId: number, docIndex: number = 0) {
        return this.data[docIndex]?.hotelNames?.[hotelId] || '';
    }

    isAllNoData(day: Day) {
        return this.data
            .every((_, index) => this.isNoData(day, index));
    }

    isNoData(day: Day, documentIndex = 0) {
        return this.ratesCommonService
            .isNoData(day, this.data[documentIndex]);
    }

    isNA(day: Day, hotelId: number, priceShown?: PRICE_SHOWN, documentIndex = 0) {
        if (this.isNoData(day, documentIndex)) return false;

        const price = this.getPrice(day, hotelId, priceShown, documentIndex);

        return false
            || price === PRICE.NA
            || price === null;
    }

    isSoldOut(day: Day, hotelId: number, priceShown?: PRICE_SHOWN, documentIndex = 0) {
        if (this.isNA(day, hotelId, undefined, documentIndex)) return false;
        if (this.isNoData(day, documentIndex)) return false;

        const price = this.getPrice(day, hotelId, priceShown, documentIndex);

        return price === PRICE.SOLD_OUT;
    }

    isOutOfRange(documentIndex = 0) {
        if (this.isLoading) return false;

        return this.ratesCommonService
            .isOutOfRange(this.data[documentIndex]);
    }

    isAllOutOfRange() {
        return this.data
            .every((_, index) => this.isOutOfRange(index));
    }

    resetLoading() {
        this.storeState.analysis.loading.reset();
    }

    private getCompetitionPercent(day: Day, priceShown?: PRICE_SHOWN | null, documentIndex = 0): Percent | null {
        const actualPriceShown = priceShown || this.documentFiltersService.priceShown;

        const doc = this.data[documentIndex] || {} as RatesDocumentModel;
        const { exchangeRate } = doc;
        const { pos: globalPos } = this.documentFiltersService.storeState.settings;
        const { comparisonValues } = this.ratesAnalysisFiltersService;

        if (!comparisonValues.length) return null;

        const isDifferentPos = globalPos !== this.ratesAnalysisFiltersService.comparisonValues[documentIndex].value;
        const isComparisonByPos = this.ratesAnalysisFiltersService.comparisonKey === 'pos';

        const hid = this.userService.currentHotelId!;

        let mainPrice = this.ratesService
            .getPrice(day, undefined, actualPriceShown);

        let comparePrice: Price | null = this.ratesCommonService
            .getPrice(day, hid, doc, actualPriceShown);

        comparePrice = comparePrice! < 0 ? null : comparePrice;
        mainPrice = mainPrice! < 0 ? null : mainPrice;

        if (!comparePrice || !mainPrice) { return null; }

        if (isComparisonByPos && isDifferentPos) {
            comparePrice *= exchangeRate;
        }

        return (mainPrice - comparePrice) / (comparePrice || 1);
    }

    private async loadData(): Promise<boolean> {
        const { settings: documentSettings } = this.documentFiltersService.storeState;
        const { settings: ratesSettings } = this.storeState;

        if (this.ratesService.isLoading) {
            return false;
        }

        if (
            documentSettings.compsetId === null
            || documentSettings.los === null
            || documentSettings.pos === null
            || ratesSettings.provider === null
        ) {
            return false;
        }

        this.storeState.analysis.documents = [];

        const { comparisonFilter } = this.storeState.analysis.settings;

        const unitedSettings = {
            ...ratesSettings,
            ...documentSettings,
        };

        const currency = this.userSettingsService.displayCurrency
            || this.ratesService.currency;

        const requestPromises = comparisonFilter.values
            .map(item => this.ratesApiService.getRatesAnalysisDocument(
                unitedSettings,
                comparisonFilter.key,
                item.value,
                currency,
            ));

        const ratesDocuments = await Promise.all(requestPromises);

        const { comparisonValues } = this.ratesAnalysisFiltersService;
        if (!comparisonValues.length) {
            this.storeState.analysis.documents = [];
            return true;
        }

        if (ratesDocuments) {
            this.storeState.analysis.documents = ratesDocuments as RatesDocumentModel[];
        }

        return true;
    }
}
