import { inject, injectable } from '@/inversify';
import _ from 'lodash';
import type Day from '../common/types/day.type';
import CarsService, { CarsServiceS } from './cars.service';
import CALENDAR_DATA_SOURCE from './constants/calendar-data-source.constant';
import { BROKER } from './constants/data-source-mode.constant';
import CarsAnalysisDocumentModel from './models/cars-analysis-document.model';
import { comparisonDays } from './models/cars-analysis.model';
import CarsDocumentItemModel from './models/cars-document-item.model';
import CarsDocumentModel from './models/cars-document.model';
import CarsPriceHistoryDocumentItemModel from './models/cars-price-history-document-item.model';

export const CarsAnalysisServiceS = Symbol.for('CarsAnalysisServiceS');
@injectable()
export default class CarsAnalysisService extends CarsService {
    @inject(CarsServiceS) private carsService!: CarsService;

    constructor() {
        super();
        this.storeFacade.watch(() => [
            this.storeState.analysis.settings.comparisonFilter.key,
            this.storeState.analysis.settings.comparisonFilter.values,
        ], () => {
            this.storeState.analysis.loading.reset();
        });
    }

    getCarDifference(day: Day, competitor: string, raw: boolean = false) {
        const currentCar = this.carsService.getPrice(day, competitor);
        const analysisCar = this.getPrice(day, competitor);
        if (!currentCar || !analysisCar) return 'NA';

        const difference = currentCar - analysisCar;
        const divider = raw
            ? 1
            : analysisCar || 1;

        return difference / divider;
    }

    async loadData() {
        if (!this.userService.isCarUser) {
            return false;
        }

        const { settings } = this.documentFiltersService.storeState;
        const carSettings = this.storeState.settings;

        if (
            settings.month === null
            || settings.year === null
            || carSettings.pickUpCityCode === null
            || carSettings.dataSource === null
            || carSettings.lor === null
            || carSettings.pos === null
        ) {
            return false;
        }
        this.storeState.analysis.documents = null;

        const chain = this.currentChain ? this.currentChain : carSettings.chain;
        const { clusterMode } = this.carFiltersStoreState.settings.features;

        const pickUpCityCodes = (carSettings.isAvgPrice && clusterMode) ? this.currentClusterLocationIds.join(',') : carSettings.pickUpCityCode;
        const categories = [...(carSettings.carClasses || [])];
        const { comparisonFilter } = this.storeState.analysis.settings;
        const carsDocument = await this.carApiService.getCarsAnalysisDocument(
            settings,
            carSettings,
            chain,
            pickUpCityCodes,
            categories,
            comparisonFilter.key,
            comparisonFilter.values.value,
        );
        if (carsDocument) {
            this.storeState.analysis.documents = carsDocument as CarsDocumentModel;
        }
        return true;
    }

    allCars(
        day: Day,
        isNeedFilter = true,
        broker?: string,
        calendarDataSource?: CALENDAR_DATA_SOURCE,
    ): { [company: string]: CarsDocumentItemModel | CarsPriceHistoryDocumentItemModel | null } | null | false {
        const dataFrom = calendarDataSource || CALENDAR_DATA_SOURCE.GROUPED;
        const currentDay = (dataFrom === CALENDAR_DATA_SOURCE.GROUPED)
            ? day
            : this.getPreviousDateByDay(day);
        const companyCars = this.filterCarClasses(currentDay, dataFrom);
        return this.getAllCarsFilters(companyCars, isNeedFilter, broker);
    }

    allCarsFromPhases(
        day: Day,
        isNeedFilter = true,
        phase: boolean,
    ): { [company: string]: CarsDocumentItemModel | CarsPriceHistoryDocumentItemModel | null } | null | false {
        const currentDay = day;
        const companyCars = this.filterCarClassesFromPhases(currentDay, phase);
        return this.getAllCarsFilters(companyCars, isNeedFilter);
    }

    getAllCarsFilters(
        companyCars: any,
        isNeedFilter: boolean,
        broker?: string,
    ): { [company: string]: CarsDocumentItemModel | CarsPriceHistoryDocumentItemModel | null } | null | false {
        const { isAvgPrice } = this.storeState.settings;
        if (!companyCars) {
            return null;
        }
        const currentCompany = this.currentCompanyCars(companyCars);
        let selectedCompetitors = this.resolveCompetitorsSelected(companyCars);

        if (currentCompany) {
            selectedCompetitors.push(currentCompany);
        }

        if (broker) {
            selectedCompetitors = this.getAllBrandsByBroker(companyCars, broker);
        }

        let filteredCars: { [company: string]: CarsDocumentItemModel[] } | null;

        if (!isAvgPrice) {
            filteredCars = this.filterCompetitors(companyCars, selectedCompetitors);
            filteredCars = this.filterTransmission(filteredCars);
        } else {
            filteredCars = this.filterCompetitors(companyCars, selectedCompetitors);
        }

        if (!filteredCars) {
            return null;
        }

        if (isNeedFilter || isAvgPrice) {
            Object.keys(filteredCars).forEach(company => {
                if (filteredCars && filteredCars[company]) {
                    filteredCars[company] = filteredCars[company].filter(car => car.priceNet || car.priceTotal || car.priceShown);
                }
            });
        }
        const allCars = (isNeedFilter) ? this.getAllCars(filteredCars, currentCompany) : this.getAllCarsPopup(filteredCars, currentCompany);
        return Object.keys(allCars).length ? allCars : false;
    }

    getAllCarsPopup(filteredCars: any, currentCompany: string | null) {
        return Object.keys(filteredCars).reduce((acc: { [company: string]: CarsDocumentItemModel | null }, company) => {
            if (filteredCars[company] && filteredCars[company].length) {
                acc[company] = filteredCars[company].reduce((
                    cheapestCar: CarsDocumentItemModel,
                    car: CarsDocumentItemModel,
                ) => {
                    if (!cheapestCar.priceNet && !cheapestCar.priceTotal && !cheapestCar.priceShown) {
                        return car;
                    }
                    if (!car.priceNet && !car.priceTotal && !car.priceShown) {
                        return cheapestCar;
                    }
                    const price = this.getCarPrice(car, company);
                    const cheapestPrice = this.getCarPrice(cheapestCar, company);
                    const selectedCar = price <= cheapestPrice ? car : cheapestCar;
                    return selectedCar;
                });
                if (acc[company]) {
                    acc[company]!.isMainCar = (company === currentCompany);
                }
            } else {
                delete acc[company];
            }
            return acc;
        }, {});
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.analysis.loading, this.loadData.bind(this));
        return this.storeState.analysis.documents;
    }

    filterCarClasses(day: Day | string, calendarDataSource?: CALENDAR_DATA_SOURCE) {
        let checkinDate: any = {};

        const { data } = this;
        if (!data) {
            return null;
        }
        const { carClasses } = this.storeState.settings;
        const { currentDocumentCarClasses } = this.storeState.settings;
        if (carClasses === null || currentDocumentCarClasses === null) {
            return null;
        }
        const selectedCarClasses = currentDocumentCarClasses.filter(item => carClasses.includes(item));

        if (typeof day === 'number') {
            checkinDate = data.checkinDates[day];
        }

        if (checkinDate === null) {
            return null;
        }

        if (!checkinDate) {
            return null;
        }

        const companyCars: { [company: string]: CarsDocumentItemModel[] } = this.getCompanyCarsFromTrends(checkinDate, selectedCarClasses);

        return companyCars;
    }

    filterCarClassesFromPhases(day: Day | string, phase: boolean) {
        let checkinDate: any = {};

        const { data } = this;
        if (!data) {
            return null;
        }
        const { carClasses } = this.storeState.settings;
        const { currentDocumentCarClasses } = this.storeState.settings;
        if (carClasses === null || currentDocumentCarClasses === null) {
            return null;
        }
        const selectedCarClasses = currentDocumentCarClasses.filter(item => carClasses.includes(item));

        if (typeof day === 'number') {
            checkinDate = data.checkinDates[day];
        }

        if (checkinDate === null) {
            return null;
        }

        if (!checkinDate) {
            return null;
        }

        const companyCars: { [company: string]: CarsDocumentItemModel[] } = this.getCompanyCarsFromGroupDocumentsFromPhases(checkinDate, selectedCarClasses, phase);

        return companyCars;
    }

    getCompanyCarsFromGroupDocumentsFromPhases(checkinDate: Record<string, any>, selectedCarClasses: any, phase: boolean) {
        if (!Object.keys(checkinDate).length || !checkinDate.phase) {
            return {};
        }
        const phases = Object.keys(checkinDate.phase).slice(-2);
        if (phase && phases.length <= 1) {
            return { };
        }

        const scanPhase = phase || phases.length === 1 ? phases[0] : phases[1];

        const checkinDateLastPhase = checkinDate.phase[scanPhase];
        const companyCars: { [company: string]: CarsDocumentItemModel[] } = Object.keys(checkinDateLastPhase).reduce((acc: { [company: string]: CarsDocumentItemModel[] }, company) => {
            acc[company] = Object.keys(checkinDateLastPhase[company]).reduce((carsDocumentItems: CarsDocumentItemModel[], companyCarClass) => {
                if (selectedCarClasses.find((carClass: string) => carClass === companyCarClass)) {
                    return carsDocumentItems.concat(checkinDateLastPhase[company][companyCarClass]);
                }
                return carsDocumentItems;
            }, []);

            return acc;
        }, {});
        return companyCars;
    }

    getCarDataTableFromPhases(day: Day, nameOfCompany: string, phase: boolean) {
        const { chainMode } = this.carFiltersStoreState.settings;
        const allCars = this.allCarsFromPhases(day, false, phase);
        let company = nameOfCompany;

        this.isNoData(day);
        const defaultValue: {price?: number, isAvailable?: boolean} = {
            price: undefined,
            isAvailable: undefined,
        };

        if (chainMode === BROKER) {
            company = _.keys(allCars).find(brand => brand.split(',')[0] === nameOfCompany) || '';
        }

        if (!allCars || !allCars[company]) {
            defaultValue.isAvailable = true;
            return defaultValue;
        }

        return allCars[company];
    }

    get shouldComparePhases() {
        const { comparisonFilter } = this.storeState.analysis.settings;
        return comparisonFilter.values.name === comparisonDays[0].name;
    }
}
