import { Expose, Transform } from 'class-transformer';

export default class ClusterDeepAnalysisStatModel {
    @Expose()
    increase!: number;

    @Expose()
    decrease!: number;

    @Expose()
    noChange!: number;

    @Expose()
    @Transform((_, plain) => (plain.increase + plain.decrease + plain.noChange))
    total!: number;
}
