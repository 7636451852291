import { inject, injectable } from '@/inversify';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import type Day from '@/modules/common/types/day.type';
import RatesPriceHistoryFilterAllModel from '@/modules/price-history/models/rates-price-history-filter-all.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import { RequestFilters } from '@/modules/rates/rates-api.service';
import RoomTypesService, { RoomTypesServiceS } from '@/modules/room-types/room-types.service';
import MealTypesService, { MealTypesServiceS } from '@/modules/meal-types/meal-types.service';
import RatesPriceHistoryModel from './models/rates-price-history.model';

export const RatesPriceHistoryApiServiceS = Symbol.for('RatesPriceHistoryApiServiceS');

@injectable()
export default class RatesPriceHistoryApiService {
    @inject(ApiServiceS)
    private apiService!: ApiService;

    @inject(RoomTypesServiceS)
    private roomTypesService!: RoomTypesService;

    @inject(MealTypesServiceS)
    mealTypesService!: MealTypesService;

    async getRatesPriceHistoryByDay(day: Day, documentId: number, settings: RatesSettingsModel, displayCurrency: string | null) {
        const query: RequestFilters = {
            statistics: true,
            'filters[price]': settings.priceType,
        };

        if (settings.roomTypeId !== -1) {
            query['filters[room_type]'] = this.roomTypesService
                .getRoomType(settings.roomTypeId)!.name;
        }

        if (settings.mealTypeId !== -1) {
            query['filters[meal_type]'] = this.mealTypesService
                .getMealType(settings.mealTypeId)!.name;
        }

        if (displayCurrency !== null) {
            query.requested_currency = displayCurrency;
        }

        const { data } = await this.apiService.get(`/rate/single-date-trend/${day}/${documentId}`, query);
        const isProviderAll = settings.provider === 'all';

        if (!data) {
            return null;
        }

        if (isProviderAll) {
            const priceHistoryDocument = plainToClass(
                RatesPriceHistoryFilterAllModel,
                <RatesPriceHistoryFilterAllModel> data,
                { excludeExtraneousValues: true },
            );
            return priceHistoryDocument;
        }
        const priceHistoryDocument = plainToClass(RatesPriceHistoryModel, <RatesPriceHistoryModel> data, { excludeExtraneousValues: true });

        // const error = await this.validatorService.validateResponse(priceHistoryDocument);
        // if (error) {
        //     throw new ResponseValidationException(error);
        // }

        return priceHistoryDocument;
    }
}
