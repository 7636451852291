import { Route } from 'vue-router';
import { container } from '@/inversify.container';

import use from '@/router/use';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';

import { CommonMenuRoutes } from '../common.routes';

export const DownloadExcelModal = (prefix: string) => ({
    name: `${prefix}.download-excel`,
    path: 'download-excel',
    component: () => import('@/modules/di-lite/pages/download-excel.page.vue'),
    meta: { modal: true },
});

export function ScheduledReports(prefix: string) {
    return {
        name: `${prefix}.scheduled-reports`,
        path: 'scheduled-reports',
        component: () => import('@/modules/di-lite/pages/di-lite-report.modal.page.vue'),
        meta: { modal: true },
    };
}

export default function DiLiteHotelLevelRoutes(basePath: string, prefix: string) {
    const userType = basePath.split('/').shift();
    const propsResolver = (route: Route) => ({
        hotelId: Number(route.params.hotelId),
        clusterId: Number(route.params.clusterId),
        userType,
    });

    return {
        path: basePath,
        component: () => import('@/modules/common/pages/hotel-transition.page.vue'),
        props: propsResolver,
        beforeEnter: use([
            container.get<FeaturesGuard>(FeaturesGuardS),
        ]),
        children: [
            {
                path: '',
                name: prefix,
                component: () => import('@/modules/di-lite/pages/di-lite.page.vue'),
                children: [
                    DownloadExcelModal(prefix),
                    ...CommonMenuRoutes(prefix),
                    ScheduledReports(prefix),
                ],
            },
        ],
    };
}
