/* eslint-disable camelcase */

import { Expose, plainToClass, Transform } from 'class-transformer';
import type Day from '@/modules/common/types/day.type';
import { IsNumber, IsOptional, Validate } from 'class-validator';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import PriceHistoryTrendDataValidator from './validators/trend-data.validator';

export default class RatesPriceHistoryFilterAllModel {
    @Expose()
    @Transform((_, plain) => plain.day)
    @IsNumber()
    @IsOptional()
    requestDay: Day | null = null;

    @Expose()
    @Transform((_, plain) => {
        const trendData: any = {};

        if (!plain.trend_data) {
            return plain.trend_data;
        }

        Object.keys(plain.trend_data).forEach(date => {
            trendData[date] = {};
            // CORD-1651 TODO: Remove it when will be ready fix on the backend
            const wrongProviders = ['expedia_mobile_app_mobile_app', 'booking_mobile_app_mobile_app', 'booking_basic_mobile_app_mobile_app'];

            Object.keys(plain.trend_data[date]).forEach(provider => {
                const { rooms, link, update_date } = plain.trend_data[date][provider];

                if (rooms && !wrongProviders.includes(provider)) {
                    const firstRoomTypeId = Object.keys(rooms)[0] || null;
                    const firstRoom = firstRoomTypeId && rooms[firstRoomTypeId][0];
                    const room = plainToClass(RatesDocumentItemModel, <RatesDocumentItemModel> firstRoom, { excludeExtraneousValues: true });
                    trendData[date][provider] = { link, room };
                    trendData[date][provider].updateDate = new Date(update_date);
                }
            });
        });

        return trendData;
    })
    @Validate(PriceHistoryTrendDataValidator)
    trendData!: {
        [date: string]: {
            [provider: string]: {
                link: string | null,
                room: RatesDocumentItemModel,
                updateDate: Date,
            }
        } | null
    };
}
