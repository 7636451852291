import { inject, injectable } from '@/inversify';
import { io, Socket } from 'socket.io-client';
import ConfigService, { ConfigServiceS } from '@/modules/config/config.service';
import ErrorException from '@/modules/common/modules/exception-handler/exceptions/error.exception';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import RatesScanModel from '@/modules/common/modules/socket/models/rates-scan.model';
import MarketsScanModel from '@/modules/common/modules/socket/models/markets-scan.model';
import { plainToClass } from 'class-transformer';
import NotificationSocketModel from '@/modules/common/modules/custom-notification/models/notification-socket.model';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import ExcelProgressSocketModel from '@/modules/common/modules/custom-notification/models/excel-progress-socket.model';
import PromotionsScanModel from '@/modules/promotions/models/promotions-scan.model';
import RatesScanProgressModel from './models/rates-scan-progress.model';

export const SocketServiceS = Symbol.for('SocketServiceS');
@injectable()
export default class SocketService {
    @inject(ConfigServiceS) private configService!: ConfigService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(UserServiceS) userService!: UserService;

    private readonly socket: Socket;
    private readonly reconnectMaxCount = 10;
    private reconnectCount = 0;

    constructor() {
        this.socket = io(this.configService.socketUrl || '', {
            transports: ['websocket'],
        });

        this.socket.on('connect_error', async () => {
            if (this.reconnectCount >= this.reconnectMaxCount) {
                await this.storeFacade.dispatchException(new ErrorException('SocketService connect_error'));
            }
        });
        // TODO: Remove if it's unnecessary
        // this.socket.on('disconnect', async () => {
        //     await this.storeFacade.dispatchException(new ErrorException('SocketService disconnected'));
        // });
    }

    sendHandshake(hotelId: number, token: string) {
        this.socket.emit('handshake', {
            fornovaId: hotelId,
            fornovaUserId: this.userService.id,
            token,
        });
    }

    onConnect(cb: () => any) {
        this.socket.on('connect', async () => {
            try {
                await cb();
                this.reconnectCount = 0;
            } catch (error) {
                await this.storeFacade.dispatchException(error as Error);
            }
        });
    }

    onDisconnect(cb: () => any) {
        this.socket.on('disconnect', async () => {
            this.reconnectCount += 1;

            if (this.reconnectCount <= this.reconnectMaxCount) {
                try {
                    await cb();
                } catch (error) {
                    if (this.reconnectCount >= this.reconnectMaxCount) {
                        await this.storeFacade.dispatchException(error as Error);
                    }
                }
            }
        });
    }

    onRatesScan(cb: (data: RatesScanModel) => any) {
        if (!this.socket) {
            return;
        }

        this.socket.on('DailyDataUpdate', async (data: Partial<RatesScanModel>) => {
            try {
                await cb(plainToClass(RatesScanModel, <RatesScanModel> data, { excludeExtraneousValues: true }));
            } catch (error) {
                await this.storeFacade.dispatchException(error as Error);
            }
        });
    }

    onRatesScanProcessUpdate(cb: (data: RatesScanProgressModel) => void) {
        if (!this.socket) {
            return;
        }

        this.socket.on('ScanShopsProcessUpdate', async (data: Partial<RatesScanProgressModel>) => {
            try {
                await cb(plainToClass(RatesScanProgressModel, data, { excludeExtraneousValues: true }));
            } catch (error) {
                await this.storeFacade.dispatchException(error as Error);
            }
        });
    }

    onPromotionsScan(cb: (data: PromotionsScanModel) => any) {
        if (!this.socket) return;

        this.socket.on('DailyDataUpdate', async (data: Partial<PromotionsScanModel>) => {
            try {
                await cb(plainToClass(PromotionsScanModel, <PromotionsScanModel> data, { excludeExtraneousValues: true }));
            } catch (error) {
                await this.storeFacade.dispatchException(error as Error);
            }
        });
    }

    onMarketsScan(cb: (data: MarketsScanModel) => any) {
        if (!this.socket) {
            return;
        }

        this.socket.on('DailyDataUpdate', async (data: Partial<MarketsScanModel>) => {
            try {
                await cb(plainToClass(MarketsScanModel, <MarketsScanModel> data, { excludeExtraneousValues: true }));
            } catch (error) {
                await this.storeFacade.dispatchException(error as Error);
            }
        });
    }

    onExcelReady(cb: (data: NotificationSocketModel) => any) {
        if (!this.socket) {
            return;
        }

        this.socket.on('reportGenerateDone', async (data: Partial<NotificationSocketModel>) => {
            try {
                await cb(plainToClass(NotificationSocketModel, <NotificationSocketModel> data, { excludeExtraneousValues: true }));
            } catch (error) {
                await this.storeFacade.dispatchException(error as Error);
            }
        });
    }

    onUpdateProgressExcel(cb: (data: ExcelProgressSocketModel) => any) {
        if (!this.socket) {
            return;
        }

        this.socket.on('updateProgress', async (data: Partial<ExcelProgressSocketModel>) => {
            try {
                await cb(plainToClass(ExcelProgressSocketModel, <ExcelProgressSocketModel> data, { excludeExtraneousValues: true }));
            } catch (error) {
                await this.storeFacade.dispatchException(error as Error);
            }
        });
    }

    emit(name: string, payload: any) {
        this.socket.emit(name, payload);
    }
}
