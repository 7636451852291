import { inject, injectable } from '@/inversify';
import Stateable from '../common/interfaces/stateable.interface';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import DocumentFiltersService, { DocumentFiltersServiceS } from '../document-filters/document-filters.service';
import CarsFiltersService, { CarsFiltersServiceS } from './cars-filters.service';
import { comparisonDays } from './models/cars-analysis.model';
import CarsStore from './store/cars.store';

type Item = { value: string | number, name: string };

export const CarsAnalysisFiltersServiceS = Symbol.for('CarsAnalysisFiltersServiceS');
@injectable()
export default class CarsAnalysisFiltersService implements Stateable {
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(CarsFiltersServiceS) private carsFilterService!: CarsFiltersService;
    @inject(DocumentFiltersServiceS) protected documentFiltersService!: DocumentFiltersService;

    readonly storeState: CarsStore = this.storeFacade.getState('CarsStore');

    comparisonFieldsDictionary = new Map([
        ['diffDays', 'Past Period'],
    ]);

    constructor() {
        this.storeFacade.watch(() => [
            this.storeState.settings.pickUpCityCode,
            this.storeState.settings.dataSource,
            this.storeState.settings.lor,
            this.storeState.settings.pos,
            this.documentFiltersService.storeState.settings.year,
            this.documentFiltersService.storeState.settings.month,
        ], () => { this.storeState.analysis.loading.reset(); });
        this.storeFacade.watch(() => [
            this.storeState.analysis.settings.comparisonFilter.key,
        ], () => { this.selectComparisonKey.bind(this); });
    }

    private selectComparisonKey() {
        const comparisonValues: { [k: string]: () => any } = {
            diffDays: () => comparisonDays[0].value,
        };
        const value = comparisonValues[this.comparisonKey]();
        const name = this.getComparisonValueLabel(value);
        this.comparisonValues = { name, value };
    }

    get mainFilterLabel() {
        const { settings } = this.carsFilterService;
        const { comparisonKey } = this;

        if (comparisonKey === 'diffDays') {
            return 'Today';
        }

        const value = settings[comparisonKey as keyof typeof settings] as string | number;

        return this.getComparisonValueLabel(value);
    }

    get comparisonKey() {
        return this.storeState.analysis.settings.comparisonFilter.key;
    }

    set comparisonKey(value: string) {
        this.storeState.analysis.settings.comparisonFilter.key = value;
        const [defaultComparisonValues] = comparisonDays;
        this.comparisonValues = defaultComparisonValues;
    }

    get comparisonValues() {
        return this.storeState.analysis.settings.comparisonFilter.values;
    }

    set comparisonValues(values: { value: string | number, name: string }) {
        this.storeState.analysis.settings.comparisonFilter.values = values;
    }

    get currentFilterItems(): Item[] {
        return this.filterItems[this.comparisonKey]();
    }

    get comparisonDayItems(): Item[] {
        return comparisonDays;
    }

    get mainCompareTitle() {
        return 'Today';
    }

    get filterItems(): {[k: string]: (wholeList?: boolean) => Item[]} {
        const itemLists: { [k: string]: () => Item[] } = {
            diffDays: () => this.comparisonDayItems,
        };

        return itemLists;
    }

    get filterList(): Item[] {
        return Array
            .from(this.comparisonFieldsDictionary)
            .filter(([key]) => this.filterItems[key]().length)
            .map(([value, name]) => ({ value, name }));
    }

    getComparisonValueLabel(needle: string | number) {
        const { comparisonKey } = this;

        const valueTypes: { [k: string]: () => any } = {
            diffDays: () => this.getItemLabel(needle),
        };

        return valueTypes[comparisonKey]();
    }

    private getItemLabel(needle: number | string) {
        const item = this.currentFilterItems.find(x => x.value === needle);
        return item ? item.name : null;
    }
}
