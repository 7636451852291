import type { ProviderData } from '@/modules/di-lite/interfaces/provider-data.interface';
import AllChannelsSettingsModel from '@/modules/di-lite/models/all-channels-settings.model';
import { Module, VuexModule } from 'vuex-module-decorators';
import BmlDataModel from '@/modules/di-lite/models/market-bml-data.model';
import LoadingModel from '@/modules/common/models/loading.model';

@Module
export default class DiLiteStore extends VuexModule {
    settings: AllChannelsSettingsModel = new AllChannelsSettingsModel();

    currency: string | null= null;

    demandData: (number | null)[] = [];

    pricesData: {
        [date: string]: {
            [provider: string]: ProviderData
        }
    } | null = null;

    loading: LoadingModel = new LoadingModel(undefined);
    excelLoading: LoadingModel = new LoadingModel(true);

    rcaData: {[name: string]: number} | null = null;
    rcaLoading: LoadingModel = new LoadingModel();

    trData: {[name: string]: number} | null = null;
    trLoading: LoadingModel = new LoadingModel();

    bmlData: BmlDataModel = { meet: null, beat: null, lose: null };
    bmlLoading: LoadingModel = new LoadingModel();

    provider: string | null = null;
}
