import { Expose, Transform } from 'class-transformer';
import {
    IsNotEmpty, Length, NotEquals,
} from 'class-validator';

export default class RoomTypeModel {
    @Expose()
    @Transform((_, plain) => plain.id)
    @IsNotEmpty()
    id!: number;

    @Expose()
    @Transform((_, plain) => plain.name)
    @Length(2, 20)
    @IsNotEmpty()
    @NotEquals('Any')
    name!: string;

    @Expose()
    @Transform((_, plain) => (plain.name === 'Any' ? 'filters.any' : plain.name))
    @IsNotEmpty()
    displayName!: string;
}
