import { inject, injectable } from '@/inversify';
import HomeFiltersModel from '@/modules/home/models/home-filters.model';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import HomeStore from '@/modules/home/store/home.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import ProvidersService, { ProvidersServiceS } from '../providers/providers.service';
import ProvidersModel from '../providers/models/providers.model';

export const HomeFiltersServiceS = Symbol.for('HomeFiltersServiceS');
@injectable()
export default class HomeFiltersService implements Stateable {
    @inject(CompsetsServiceS) private compsetsService!: CompsetsService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(ProvidersServiceS) private providersService!: ProvidersService;

    readonly storeState: HomeStore = this.storeFacade.getState('HomeStore');

    constructor() {
        this.storeFacade.watch(
            () => [
                this.compsetsService.storeState.compsets,
                this.providersService.storeState.providerList,
            ],
            this.reset.bind(this),
        );

        this.reset();
    }

    get ratesPos() {
        return this.storeState.settings.ratesPos;
    }

    set ratesPos(pos: string | null) {
        this.storeState.settings.ratesPos = pos;
    }

    get isCugOfMobileApp() {
        const { ratesProvider } = this.storeState.settings;
        if (ratesProvider) {
            const provider = ratesProvider.toLowerCase();
            return provider.includes('cug') || (provider.includes('mobile') && provider.includes('app'));
        }
        return false;
    }

    get ratesProvider() {
        return this.storeState.settings.ratesProvider;
    }
    set ratesProvider(provider: string | null) {
        this.storeState.settings.ratesProvider = provider;
        if (this.isCugOfMobileApp && this.compsetsService.compsets && provider) {
            const firstCompsetByProvider = this.compsetsService.compsets.find(x => x.rateProviders.includes(provider));
            this.storeState.settings.ratesPos = firstCompsetByProvider ? firstCompsetByProvider.mainPos || firstCompsetByProvider.pos[0] : null;
        }
    }

    get posItems() {
        return this.compsetsService.compsets
            ? [...new Set(
                ...this.compsetsService.compsets.map(compset => {
                    const providerPos = compset.providersPos && compset.providersPos.find(item => item.name === this.ratesProvider);
                    if (!providerPos) {
                        return [...compset.pos, compset.mainPos];
                    }

                    return providerPos.pos;
                }),
            )]
            : [];
    }

    reset() {
        this.resetCompset();
        this.resetRatesProvider();
        [this.ratesPos] = this.compsetsService.poses;
    }

    async resetCompset() {
        if (!this.compsetsService.compsets || !this.compsetsService.compsets.length) {
            return;
        }
        await this.updateCompset(this.compsetsService.compsets[0].id);
    }

    updateProvider(provider: string) {
        this.ratesProvider = provider;

        if (!this.posItems.includes(this.ratesPos as string)) {
            const [firstPos] = this.posItems;
            this.ratesPos = firstPos;
        }
    }

    async resetRatesProvider(afterProviderRequest?: boolean): Promise<void> {
        const { providers } = this.providersService.storeState;

        if (providers) {
            this.setDefaultProvider();
        } else if (!afterProviderRequest) {
            await this.providersService.loadData();
            this.resetRatesProvider(true);
        }
    }

    async updateCompset(compsetId: string) {
        const compset = this.compsetsService.getCompset(compsetId);

        if (!compset) {
            return;
        }

        const settings = { ...this.storeState.settings };
        const { marketProviders } = compset;

        settings.visibilityCompsetId = compsetId;

        if (settings.visibilityProvider === null || !marketProviders.find(item => item === settings.visibilityProvider)) {
            settings.visibilityProvider = marketProviders && marketProviders.length ? marketProviders[0] : settings.visibilityProvider;
        }

        this.storeState.settings = { ...settings };
    }

    async saveFilters(settings: HomeFiltersModel): Promise<void> {
        this.storeState.settings = { ...settings };
    }

    private setDefaultProvider() {
        if (!this.possibleProviders.length) {
            return;
        }

        const bookingProvider = this.possibleProviders.find(p => p.name === 'booking');

        if (bookingProvider) {
            this.ratesProvider = bookingProvider.name;
            return;
        }

        this.ratesProvider = this.possibleProviders[0].name;
    }

    /** All possible providers that may be included in any compset */
    get possibleProviders(): ProvidersModel[] {
        const { providerList } = this.providersService.storeState;
        const { compsets } = this.compsetsService.storeState;

        if (!providerList || !compsets) return [];

        const possibleProviders = Array.from(new Set(
            compsets.map(compset => [...compset.rateProviders]).flat(),
        ));

        const findProviderByName = (pName: string) => providerList.find(p => p.name === pName);
        const providers = possibleProviders.map(findProviderByName);

        const exceptAllChannels = (provider: ProvidersModel | undefined) => provider!.name !== 'all';
        const onlyValidProviders = (provider: ProvidersModel | undefined) => !!provider;

        return providers
            .filter(onlyValidProviders)
            .filter(exceptAllChannels) as ProvidersModel[];
    }
}
