import { inject, injectable } from '@/inversify';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import HotelsService, { HotelsServiceS } from '@/modules/hotels/hotels.service';
import type Day from '@/modules/common/types/day.type';
import GRAPH_TYPE from '@/modules/cars/modules/fleet/constants/graph-types.constant';
import FleetStore from '@/modules/cars/modules/fleet/store/fleet.store';
import { DEFAULT_GRAPH_COLORS as GRAPH_COLORS } from '@/modules/common/constants/default-graph-colors.constant';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import FleetApiService, { FleetApiServiceS } from '@/modules/cars/modules/fleet/fleet-api.service';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import StoreFacade, { StoreFacadeS } from '../../../common/services/store-facade';
import {
    MILEAGE_ANY, PAYMENT_TERMS_ANY, TRANSMISSION_ANY, COUNTRIES_ANY, POS_ANY,
} from '../../constants/car-filter-types.constant';

export const FleetServiceS = Symbol.for('FleetServiceS');
@injectable()
export default class FleetService implements Stateable {
    @inject(UserServiceS) private userService!: UserService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(FleetApiServiceS) private fleetApiService!: FleetApiService;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(HotelsServiceS) private hotelsService!: HotelsService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;

    readonly storeState: FleetStore = this.storeFacade.getState('FleetStore');
    constructor() {
        this.storeFacade.watch(
            () => [
                this.storeState.settings.pickUpCityCode,
                this.storeState.settings.lor,
                this.storeState.settings.pos,
                this.documentFiltersService.storeState.settings.year,
                this.documentFiltersService.storeState.settings.month,
            ],
            this.resetFleetLoading.bind(this),
        );
    }

    resetFleetLoading() {
        this.storeState.loading.reset();
    }

    switchGraphType(provider: string) {
        const graphType = this.getGraphType(provider);
        this.storeState.graphType[provider] = graphType === GRAPH_TYPE.RANGE ? GRAPH_TYPE.BROKERS : GRAPH_TYPE.RANGE;
        this.storeState.graphType = { ...this.storeState.graphType };
    }

    get fleetDocument() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.document;
    }

    async loadData() {
        const { year, month } = this.documentFiltersService;
        if (!this.userService.isCarUser) {
            return false;
        }

        if (!this.dataSourcesFromFilter || !year || month === null) {
            return false;
        }

        if (!this.storeState.settings.lor || !this.storeState.settings.pos) {
            return false;
        }

        this.storeState.document = await this.fleetApiService.getFleet(this.storeState.settings, this.dataSourcesFromFilter, year, month);

        return true;
    }

    get dataSourcesFromFilter() {
        return this.storeState.settings.dataSources;
    }

    get dataSources() {
        if (!this.fleetDocument.providers) {
            return [];
        }
        return Object.keys(this.fleetDocument.providers);
    }

    isExistSource(sources: string) {
        if (!this.dataSources) {
            return false;
        }
        return this.dataSources.includes(sources);
    }

    getPickUpDateByProvider(provider: string) {
        if (!this.storeState.document) {
            return false;
        }
        if (!this.storeState.document.providers) {
            return false;
        }
        if (!this.storeState.document.providers[provider]) {
            return false;
        }
        return this.storeState.document.providers[provider].pickupDates;
    }

    carCategoriesByDay(day: Day, provider?: string) {
        const { providers } = this.storeState.document;
        if (!providers) {
            return [];
        }

        let categories: string[] = [];
        const pickUpDate = this.getPickUpDateByProvider(provider as string);

        if (!pickUpDate) {
            return [];
        }

        const currentData = pickUpDate[day];

        if (!currentData) {
            return [];
        }

        Object.keys(currentData).forEach(vendor => {
            categories = categories.concat(Object.keys(currentData[vendor]));
        });

        return [...new Set(categories)].filter(item => this.carClasses.includes(item));
    }

    getGraphType(provider: string) {
        if (!this.storeState.graphType[provider]) {
            return GRAPH_TYPE.RANGE;
        }
        return this.storeState.graphType[provider];
    }

    getNumberCarByBroker(day: Day, broker: string) {
        if (!this.dataSources) {
            return 0;
        }

        if (!this.dataSource) {
            return 0;
        }

        let total = 0;
        const count = this.getNumberCurrentBroker(day, this.dataSource, broker);
        total += count;

        return total;
    }

    getNumberCurrentBroker(day: Day, source: string, broker: string) {
        const sourceData = this.getPickUpDateByProvider(source);

        if (!sourceData) {
            return 0;
        }
        const pickupDate = sourceData[day];
        if (!pickupDate) {
            return 0;
        }
        const company = pickupDate[broker];
        if (!company) {
            return 0;
        }

        let count = 0;
        Object.keys(company).forEach(carClass => {
            if (this.carClasses.includes(carClass)) {
                const pickupDateCarClass = company[carClass];
                const currentTransmission = (this.transmission === TRANSMISSION_ANY)
                    ? Object.keys(pickupDateCarClass || []) : [this.transmission];

                currentTransmission.forEach(trans => {
                    const pickupDateTransmission = pickupDateCarClass[trans];
                    const currentMileagePolicy = (this.mileage === MILEAGE_ANY)
                        ? Object.keys(pickupDateTransmission || []) : [this.mileage];

                    currentMileagePolicy.forEach(mileagePolicy => {
                        if (pickupDateTransmission) {
                            const pickupDateMileagePolicy = pickupDateTransmission[mileagePolicy];
                            const currentPaymentTerms = (this.paymentTerms === PAYMENT_TERMS_ANY)
                                ? Object.keys(pickupDateMileagePolicy || []) : [this.paymentTerms];

                            currentPaymentTerms.forEach(paymentTerms => {
                                if (pickupDateMileagePolicy) {
                                    const pickupDatePaymentTerms = pickupDateMileagePolicy[paymentTerms];
                                    if (pickupDatePaymentTerms) {
                                        count += pickupDatePaymentTerms;
                                    }
                                }
                            });
                        }
                    });
                });
            }
        });

        return count;
    }

    getNumberCar(day: Day, source: any) {
        const sourceData = this.getPickUpDateByProvider(source);

        if (!sourceData) {
            return 0;
        }

        const pickupDate = sourceData[`${day}`];

        if (!pickupDate) {
            return 0;
        }

        const { currentCompany } = this.userService;

        if (!currentCompany) {
            return 0;
        }

        let count = 0;
        const pickupDateCompany = pickupDate[currentCompany];
        Object.keys(pickupDateCompany).forEach(carClass => {
            if (this.carClasses.includes(carClass)) {
                const pickupDateCarClass = pickupDateCompany[carClass];
                const currentTransmission = (this.transmission === TRANSMISSION_ANY)
                    ? Object.keys(pickupDateCarClass || []) : [this.transmission];

                currentTransmission.forEach(trans => {
                    const pickupDateTransmission = pickupDateCarClass[trans];
                    const currentMileagePolicy = (this.mileage === MILEAGE_ANY)
                        ? Object.keys(pickupDateTransmission || []) : [this.mileage];

                    currentMileagePolicy.forEach(mileagePolicy => {
                        if (pickupDateTransmission) {
                            const pickupDateMileagePolicy = pickupDateTransmission[mileagePolicy];
                            const currentPaymentTerms = (this.paymentTerms === PAYMENT_TERMS_ANY)
                                ? Object.keys(pickupDateMileagePolicy || []) : [this.paymentTerms];

                            currentPaymentTerms.forEach(paymentTerms => {
                                if (pickupDateMileagePolicy) {
                                    const pickupDatePaymentTerms = pickupDateMileagePolicy[paymentTerms];
                                    if (pickupDatePaymentTerms) {
                                        count += pickupDatePaymentTerms;
                                    }
                                }
                            });
                        }
                    });
                });
            }
        });

        return count;
    }

    isNa(day: Day, source: any) {
        const sourceData = this.getPickUpDateByProvider(source);
        const { currentCompany } = this.userService;

        if (!sourceData) {
            return 0;
        }

        const pickupDate = sourceData[`${day}`];

        if (!pickupDate) {
            return 0;
        }

        const { competitors } = this;
        const selectedCompetitors = new Set(competitors);
        if (currentCompany) {
            selectedCompetitors.add(currentCompany);
        }
        const competitorsArray = [...selectedCompetitors];

        let count = 0;
        Object.keys(pickupDate).forEach(company => {
            if (competitorsArray.includes(company)) {
                const pickupDateCompany = pickupDate[company];
                Object.keys(pickupDateCompany).forEach(carClass => {
                    if (this.carClasses.includes(carClass)) {
                        const pickupDateCarClass = pickupDateCompany[carClass];
                        const currentTransmission = (this.transmission === TRANSMISSION_ANY)
                            ? Object.keys(pickupDateCarClass || []) : [this.transmission];

                        currentTransmission.forEach(trans => {
                            const pickupDateTransmission = pickupDateCarClass[trans];
                            const currentMileagePolicy = (this.mileage === MILEAGE_ANY)
                                ? Object.keys(pickupDateTransmission || []) : [this.mileage];

                            currentMileagePolicy.forEach(mileagePolicy => {
                                if (pickupDateTransmission) {
                                    const pickupDateMileagePolicy = pickupDateTransmission[mileagePolicy];
                                    const currentPaymentTerms = (this.paymentTerms === PAYMENT_TERMS_ANY)
                                        ? Object.keys(pickupDateMileagePolicy || []) : [this.paymentTerms];

                                    currentPaymentTerms.forEach(paymentTerms => {
                                        if (pickupDateMileagePolicy) {
                                            const pickupDatePaymentTerms = pickupDateMileagePolicy[paymentTerms];
                                            if (pickupDatePaymentTerms) {
                                                count += pickupDatePaymentTerms;
                                            }
                                        }
                                    });
                                }
                            });
                        });
                    }
                });
            }
        });

        return count;
    }

    isOutOfRange(day: Day, source: any) {
        if (!this.fleetDocument.providers) {
            return true;
        }
        const sourceData = this.getPickUpDateByProvider(source);

        if (!sourceData) {
            return true;
        }

        const pickupDate = sourceData[`${day}`];

        if (!pickupDate) {
            return true;
        }

        return false;
    }

    isSoldOut(day: Day, source: any) {
        if (!this.fleetDocument.providers) {
            return true;
        }
        const data = this.getPickUpDateByProvider(source);
        const jsonData = JSON.stringify(data);
        const sourceData = JSON.parse(jsonData);
        if (!sourceData) {
            return true;
        }

        let pickupDate = sourceData[`${day}`];

        if (!pickupDate) {
            return true;
        }
        pickupDate = this.filterCompetitors(pickupDate);

        Object.keys(pickupDate).forEach(company => {
            this.filterCarClasses(pickupDate[company]);
            const pickupDateCompany = pickupDate[company];
            Object.keys(pickupDateCompany).forEach(carClass => {
                const pickupDateCarClass = pickupDateCompany[carClass];
                this.filterTransmission(pickupDateCarClass);
                Object.keys(pickupDateCarClass).forEach(transmission => {
                    const mileageData = pickupDateCarClass[transmission];
                    this.filterMileage(mileageData);
                    Object.keys(mileageData).forEach(mileage => {
                        const paymentsData = mileageData[mileage];
                        this.filterPayment(paymentsData);
                        if (Object.keys(paymentsData).length === 0) {
                            delete mileageData[mileage];
                        }
                    });
                    if (Object.keys(mileageData).length === 0) {
                        delete pickupDateCarClass[transmission];
                    }
                });
                if (Object.keys(pickupDateCarClass).length === 0) {
                    delete pickupDateCompany[carClass];
                }
            });
            if (Object.keys(pickupDateCompany).length === 0) {
                delete pickupDate[company];
            }
        });

        const company = this.userService.currentCompany;
        if (!company) {
            return true;
        }

        if (!pickupDate[company]) {
            return true;
        }

        if (Object.keys(pickupDate[company]).length === 0) {
            return true;
        }

        return false;
    }

    filterCompetitors(data: any) {
        const { currentCompany } = this.userService;
        const competitorsData = data;
        const { competitors } = this;
        const selectedCompetitors = new Set(competitors);
        if (currentCompany) {
            selectedCompetitors.add(currentCompany);
        }
        const competitorsArray = [...selectedCompetitors];
        Object.keys(competitorsData).forEach(company => {
            if (!competitorsArray.includes(company)) {
                delete competitorsData[company];
            }
        });

        return competitorsData;
    }

    filterCarClasses(data: any) {
        const classesData = data;
        Object.keys(classesData).forEach(carClasses => {
            if (!this.carClasses.includes(carClasses)) {
                delete classesData[carClasses];
            }
        });

        return classesData;
    }

    filterTransmission(data: any) {
        const transmissionData = data;
        Object.keys(transmissionData).forEach(transmission => {
            if (this.transmission !== transmission && this.transmission !== TRANSMISSION_ANY) {
                delete transmissionData[transmission];
            }
        });

        return transmissionData;
    }

    filterMileage(data: any) {
        const mileageData = data;
        Object.keys(mileageData).forEach(mileage => {
            if (this.mileage !== mileage && this.mileage !== MILEAGE_ANY) {
                delete mileageData[mileage];
            }
        });

        return mileageData;
    }

    filterPayment(data: any) {
        const paymentData = data;
        Object.keys(paymentData).forEach(payment => {
            if (this.paymentTerms !== payment && this.paymentTerms !== PAYMENT_TERMS_ANY) {
                delete paymentData[payment];
            }
        });

        return paymentData;
    }

    getCountByBroker(provider: string, broker: string) {
        const data: any = [];
        this.documentFiltersService.days.forEach(item => {
            const count = this.carsBrokersCountDay(item, provider, broker);
            data.push(count);
        });

        return data;
    }

    async triggerScan(day: Day | undefined) {
        return '';
    }

    getTotal(broker: string) {
        if (!this.dataSource) {
            return 0;
        }

        let total = 0;
        const countArray = this.getCountByBroker(this.dataSource, broker);
        const reducer = (accumulator: number, currentValue: number) => accumulator + currentValue;
        total += countArray.reduce(reducer);
        return total;
    }

    get brokers() {
        return this.dataSources;
    }

    get companies() {
        const { providers } = this.storeState.document;
        if (!providers) {
            return [];
        }

        let allCompanies: string[] = [];
        this.dataSources.forEach((source: string) => {
            const companies = this.getCompaniesByProvider(source);
            allCompanies = [...companies, ...allCompanies];
        });

        return [...new Set(allCompanies)];
    }

    getCompaniesByProvider(provider: string) {
        const dates = this.getPickUpDateByProvider(provider);

        if (!dates) {
            return [];
        }
        let companies: string[] = [];
        Object.entries(dates).forEach(([day, index]) => {
            companies = [...Object.keys(dates[day]), ...companies];
        });
        companies = [...new Set(companies)];
        return companies;
    }

    currentCompanyCount(day: Day, broker: string) {
        const { currentCompany } = this.userService;
        const brokerData = this.getPickUpDateByProvider(broker);

        if (!currentCompany) {
            return null;
        }

        if (!brokerData) {
            return null;
        }

        const pickDate = brokerData[day];

        if (!pickDate) {
            return null;
        }

        const currentCompanyData = pickDate[currentCompany];

        if (!currentCompanyData) {
            return null;
        }

        let count = 0;
        Object.keys(currentCompanyData).forEach(carClass => {
            if (this.carClasses.includes(carClass)) {
                const pickupDateCarClass = currentCompanyData[carClass];
                const currentTransmission = (this.transmission === TRANSMISSION_ANY)
                    ? Object.keys(pickupDateCarClass || []) : [this.transmission];

                currentTransmission.forEach(trans => {
                    const pickupDateTransmission = pickupDateCarClass[trans];
                    const currentMileagePolicy = (this.mileage === MILEAGE_ANY)
                        ? Object.keys(pickupDateTransmission || []) : [this.mileage];

                    currentMileagePolicy.forEach(mileagePolicy => {
                        if (pickupDateTransmission) {
                            const pickupDateMileagePolicy = pickupDateTransmission[mileagePolicy];
                            const currentPaymentTerms = (this.paymentTerms === PAYMENT_TERMS_ANY)
                                ? Object.keys(pickupDateMileagePolicy || []) : [this.paymentTerms];

                            currentPaymentTerms.forEach(paymentTerms => {
                                if (pickupDateMileagePolicy) {
                                    const pickupDatePaymentTerms = pickupDateMileagePolicy[paymentTerms];
                                    if (pickupDatePaymentTerms) {
                                        count += pickupDatePaymentTerms;
                                    }
                                }
                            });
                        }
                    });
                });
            }
        });

        return count;
    }

    carsCountDay(day: Day, broker: string): number[] | null {
        const brokerData = this.getPickUpDateByProvider(broker);

        if (!brokerData) {
            return null;
        }

        if (!brokerData[day]) {
            return null;
        }

        const pickDate = brokerData[day];

        if (!pickDate) {
            return null;
        }

        if (!pickDate) {
            return null;
        }

        const brokersData: any = {};
        Object.keys(pickDate).forEach(company => {
            Object.keys(pickDate[company]).forEach((carClass: string) => {
                brokersData[company] = brokersData[company] || 0;
                if (this.carClasses.includes(carClass)) {
                    // @ts-ignore
                    const pickupDateCarClass = pickDate[company][carClass];
                    const currentTransmission = (this.transmission === TRANSMISSION_ANY)
                        ? Object.keys(pickupDateCarClass || []) : [this.transmission];

                    currentTransmission.forEach(trans => {
                        const pickupDateTransmission = pickupDateCarClass[trans];
                        const currentMileagePolicy = (this.mileage === MILEAGE_ANY)
                            ? Object.keys(pickupDateTransmission || []) : [this.mileage];

                        currentMileagePolicy.forEach(mileagePolicy => {
                            if (pickupDateTransmission) {
                                const pickupDateMileagePolicy = pickupDateTransmission[mileagePolicy];
                                const currentPaymentTerms = (this.paymentTerms === PAYMENT_TERMS_ANY)
                                    ? Object.keys(pickupDateMileagePolicy || []) : [this.paymentTerms];

                                currentPaymentTerms.forEach(paymentTerms => {
                                    if (pickupDateMileagePolicy) {
                                        const pickupDatePaymentTerms = pickupDateMileagePolicy[paymentTerms];
                                        if (pickupDatePaymentTerms) {
                                            brokersData[company] += pickupDatePaymentTerms;
                                        }
                                    }
                                });
                            }
                        });
                    });
                }
            });
        });
        return Object.values(brokersData);
    }

    carsBrokersCountDay(day: Day, provider?: string, broker?: string) {
        if (!this.fleetDocument.providers) {
            return null;
        }

        const brokerData = this.getPickUpDateByProvider(provider as string);

        if (!brokerData) {
            return null;
        }

        if (!brokerData[day]) {
            return null;
        }

        const pickDate = brokerData[day];

        if (!pickDate) {
            return null;
        }

        const company = pickDate[broker as string];

        if (!company) {
            return null;
        }

        let count = 0;
        Object.keys(company).forEach(carClass => {
            if (this.carClasses.includes(carClass)) {
                const pickupDateCarClass = company[carClass];
                const currentTransmission = (this.transmission === TRANSMISSION_ANY)
                    ? Object.keys(pickupDateCarClass || []) : [this.transmission];

                currentTransmission.forEach(trans => {
                    const pickupDateTransmission = pickupDateCarClass[trans];
                    const currentMileagePolicy = (this.mileage === MILEAGE_ANY)
                        ? Object.keys(pickupDateTransmission || []) : [this.mileage];

                    currentMileagePolicy.forEach(mileagePolicy => {
                        if (pickupDateTransmission) {
                            const pickupDateMileagePolicy = pickupDateTransmission[mileagePolicy];
                            const currentPaymentTerms = (this.paymentTerms === PAYMENT_TERMS_ANY)
                                ? Object.keys(pickupDateMileagePolicy || []) : [this.paymentTerms];

                            currentPaymentTerms.forEach(paymentTerms => {
                                if (pickupDateMileagePolicy) {
                                    const pickupDatePaymentTerms = pickupDateMileagePolicy[paymentTerms];
                                    if (pickupDatePaymentTerms) {
                                        count += pickupDatePaymentTerms;
                                    }
                                }
                            });
                        }
                    });
                });
            }
        });

        return count;
    }

    brokerMax(day: Day, broker: string) {
        const count = this.carsCountDay(day, broker);

        if (!count) {
            return null;
        }

        return Math.max(...count);
    }

    brokerMin(day: Day, broker: string) {
        const count = this.carsCountDay(day, broker);

        if (!count) {
            return null;
        }

        return Math.min(...count);
    }

    get currentBroker() {
        return this.userService.currentCompany;
    }

    get currentBrokerId() {
        return 1;
    }

    get finishScanDate() {
        return new Date();
    }

    getBrokersGraphColor(brokerId: number) {
        return GRAPH_COLORS[brokerId];
    }

    brokersDataByDay(day: Day, provider?: string) {
        const { currentCompany } = this.userService;
        if (!this.fleetDocument.providers || !currentCompany) {
            return [];
        }

        let companies = provider ? this.getCompaniesByProvider(provider) : this.companies;
        const { competitors } = this;
        const selectedCompetitors = new Set(competitors);
        if (currentCompany) {
            selectedCompetitors.add(currentCompany);
        }
        const competitorsArray = [...selectedCompetitors];
        companies = companies.filter(item => competitorsArray.includes(item));

        const brokersData: any = [];
        companies.forEach(item => {
            const classesData = this.carClassesData(item, day, provider);
            let total = 0;
            Object.keys(classesData).forEach(className => {
                total += classesData[className];
            });
            brokersData.push({
                ...classesData,
                brokerName: item,
                total,
                myBroker: item === this.userService.currentCompany,
            });
        });

        return brokersData;
    }

    carClassesData(broker: string, day: Day, provider?: string) {
        const { providers } = this.storeState.document;
        if (!providers) {
            return {};
        }

        if (!provider) {
            return {};
        }

        const fleetData = this.getPickUpDateByProvider(provider);
        const jsonData = JSON.stringify(fleetData);
        const sourceData = JSON.parse(jsonData);

        if (!fleetData) {
            return {};
        }

        let pickupDate = sourceData[`${day}`];

        if (!pickupDate) {
            return true;
        }

        pickupDate = this.filterCompetitors(pickupDate);
        Object.keys(pickupDate).forEach(company => {
            this.filterCarClasses(pickupDate[company]);
            const pickupDateCompany = pickupDate[company];
            Object.keys(pickupDateCompany).forEach(carClass => {
                const pickupDateCarClass = pickupDateCompany[carClass];
                this.filterTransmission(pickupDateCarClass);
                Object.keys(pickupDateCarClass).forEach(transmission => {
                    const mileageData = pickupDateCarClass[transmission];
                    this.filterMileage(mileageData);
                    Object.keys(mileageData).forEach(mileage => {
                        const paymentsData = mileageData[mileage];
                        this.filterPayment(paymentsData);
                        if (Object.keys(paymentsData).length === 0) {
                            delete mileageData[mileage];
                        }
                    });
                    if (Object.keys(mileageData).length === 0) {
                        delete pickupDateCarClass[transmission];
                    }
                });
                if (Object.keys(pickupDateCarClass).length === 0) {
                    delete pickupDateCompany[carClass];
                }
            });
            if (Object.keys(pickupDateCompany).length === 0) {
                delete pickupDate[company];
            }
        });
        const carCategoriesByDay = this.carCategoriesByDay(day, provider);
        const categoriesData: any = {};
        carCategoriesByDay.forEach(item => {
            const pickupData = pickupDate;
            if (pickupData) {
                const company = pickupData[broker];
                if (company) {
                    const classData = company[item];
                    if (classData) {
                        categoriesData[item] = this.getCountByClass(classData);
                    }
                }
            }
        });
        return categoriesData;
    }

    getCountByClass(classData: any) {
        let count = 0;
        Object.entries(classData).forEach(([transmission, item]) => {
            Object.entries(classData[transmission]).forEach(([paymentType, data]) => {
                Object.entries(classData[transmission][paymentType]).forEach(([type, paymentData]) => {
                    // @ts-ignore
                    count += paymentData;
                });
            });
        });
        return count;
    }

    get country() {
        return this.storeState.settings.country || COUNTRIES_ANY;
    }

    get pickupLocation() {
        return this.storeState.settings.pickUpCityCode || '';
    }

    get lor() {
        return this.storeState.settings.lor || '';
    }

    get pos() {
        return this.storeState.settings.pos || POS_ANY;
    }

    get transmission() {
        return this.storeState.settings.transmission || TRANSMISSION_ANY;
    }

    get carClasses() {
        return this.storeState.settings.carClasses || [];
    }

    get competitors() {
        return this.storeState.settings.competitors || [];
    }

    get mileage() {
        return this.storeState.settings.mileage || MILEAGE_ANY;
    }

    get paymentTerms() {
        return this.storeState.settings.paymentTerms || PAYMENT_TERMS_ANY;
    }

    get dataSource() {
        return this.storeState.settings.dataSource;
    }
}
