import { inject, injectable } from '@/inversify';
import moment from 'moment';

import type Day from '@/modules/common/types/day.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';

import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS }
    from '@/modules/price-history/rates-price-history-common.service';

import RatesDocumentItemAllModel from '@/modules/rates/models/rates-document-item-all.model';
import RatesPriceHistoryStore from '@/modules/price-history/store/rates-price-history.store';
import RatesPriceHistoryFilterAllModel from '@/modules/price-history/models/rates-price-history-filter-all.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import { PRICE_SHOWN } from '@/modules/rates/constants';

export const RatesPriceHistoryAllServiceS = Symbol.for('RatesPriceHistoryAllServiceS');
@injectable()
export default class RatesPriceHistoryAllService implements Stateable {
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(RatesPriceHistoryCommonServiceS) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    readonly storeState: RatesPriceHistoryStore = this.storeFacade.getState('RatesPriceHistoryStore');

    private addAverageToHistory(history: { [provider: string]: RatesDocumentItemAllModel }[]) {
        history.map(item => {
            const historyItem = item;
            const [netPrice, totalPrice, shownPrice] = [PRICE_SHOWN.NET, PRICE_SHOWN.TOTAL, PRICE_SHOWN.SHOWN]
                .map(priceShown => {
                    let numberOfPrices = 0;
                    const providers = Object.keys(historyItem).filter(provider => provider !== 'average');
                    const price = providers.reduce((totalPrice, provider) => {
                        const price = this.ratesCommonService.switchPrice(historyItem[provider]?.room || null, priceShown);
                        const isValidPrice = price && price !== -1;

                        if (!isValidPrice) {
                            return totalPrice;
                        }

                        numberOfPrices += 1;
                        return totalPrice + price!;
                    }, 0);

                    if (numberOfPrices === 0) {
                        return null;
                    }

                    return price / numberOfPrices;
                });

            historyItem.average = {
                room: {
                    price: {
                        netPrice,
                        totalPrice,
                        shownPrice,
                    },
                },
                link: '',
            };
            return historyItem;
        });

        return history;
    }

    get lastScanDate() {
        return this.ratesPriceHistoryCommonService.lastScanDate;
    }

    get selectedTrendDate() {
        const { dayIndex } = this.ratesPriceHistoryCommonService;
        const { lastScanDate } = this;

        if (!lastScanDate) {
            return null;
        }

        const d = new Date(lastScanDate);
        d.setDate(d.getDate() - dayIndex);

        return d;
    }

    get selectedScanDate() {
        if (!this.selectedTrendDate) {
            return null;
        }

        return this.getLastUpdateDate(this.selectedTrendDate);
    }

    get providers() {
        const doc = this.ratesPriceHistoryCommonService.documents.main as RatesPriceHistoryFilterAllModel;

        if (!doc || !doc.trendData) {
            return [];
        }

        const providers = Object
            .entries(doc.trendData!)
            .map(([, el]) => (el ? Object.keys(el) : []))
            .flat();

        if (!providers.includes('average')) {
            providers.push('average');
        }

        const filteredProviders = [...new Set(providers)]
            .filter(item => item !== 'day_statistics');

        return filteredProviders.length
            ? filteredProviders
            : null;
    }

    private getLastUpdateDate(trendDate: Date) {
        const key = moment(trendDate).format('DD-MM-YYYY');
        const doc = this.ratesPriceHistoryCommonService.documents.main as RatesPriceHistoryFilterAllModel;

        if (!doc || !doc.trendData || !doc.trendData[key]) {
            return null;
        }

        const trendData = doc.trendData[key];

        if (!trendData) {
            return null;
        }

        return Object.values(trendData).reduce((prev, next) => {
            const updateDate = next?.updateDate || null;
            return updateDate && updateDate > prev ? updateDate : prev;
        }, new Date(0));
    }

    getSuitableProviderByDay(dayParam: number) {
        const filteredProviders = {} as {[provider: string] : RatesDocumentItemAllModel};
        const doc = this.ratesPriceHistoryCommonService.documents.main as RatesPriceHistoryFilterAllModel;

        if (!doc) {
            return filteredProviders;
        }

        if (!this.lastScanDate) {
            return filteredProviders;
        }

        const dateInstance = new Date(this.lastScanDate);
        dateInstance.setMinutes(dateInstance.getMinutes() + dateInstance.getTimezoneOffset());
        dateInstance.setDate(dateInstance.getDate() - dayParam);

        const day = moment(dateInstance).format('DD');
        const month = moment(dateInstance).format('MM');
        const year = moment(dateInstance).format('YYYY');
        const data = doc.trendData?.[`${day}-${month}-${year}`];

        if (!data) {
            return filteredProviders;
        }

        return data;
    }

    getPriceHistoryProvidersData(provider: string, priceShown?: PRICE_SHOWN) {
        const { docDay } = this.ratesPriceHistoryCommonService;
        const actualPriceShown = priceShown || this.documentFiltersService.priceShown;

        const priceHistory = docDay
            ? this.getPriceHistory(docDay as Day) as Record<string, RatesDocumentItemAllModel>[]
            : null;

        if (!priceHistory) return [];

        return priceHistory
            .map(day => {
                if (!day || !Object.keys(day).length || !day[provider]) {
                    return null;
                }

                return this.ratesCommonService
                    .switchPrice(day[provider].room, actualPriceShown) || null;
            })
            .reverse();
    }

    getPriceHistory(actualDay: Day | null): {[provider: string] : RatesDocumentItemAllModel}[] | null {
        this.ratesPriceHistoryCommonService.setData(actualDay);

        const newDays = this.ratesPriceHistoryCommonService.sortedDaysList
            .map((_: number, index: number) => this.getSuitableProviderByDay(index)
                || {} as {[provider: string]: RatesDocumentItemAllModel});
        return this.addAverageToHistory(newDays);
    }
}
