/* eslint-disable camelcase */
import { Expose, Transform, plainToClass } from 'class-transformer';
import { IsString, IsNumber, IsDate } from 'class-validator';
import DeepAnalysisDocumentModel from '@/modules/deep-analysis/models/deep-analysis-document.model';
import ClusterHotelsModel from './cluster.model';

interface StatisticData {
    comp_set?: number;
    last_year?: number;
    my_hotel?: number;
    absolute_comp_set?: number;
    absolute_last_year?: number;
    performance_comp_set?: number;
    performance_last_year?: number;
    performance_my_hotel?: number;
    performance_calculated_comp_set?: number;
    performance_calculated_last_year?: number;
}

interface DayStats {
    [days: number]: {
        [statistic: string]: StatisticData
    };
}

interface DeepAnalysisClusterResponse {
    details: {
        [providerKey: string]: {
            currency: string;
            customer_id: string;
            last: DayStats;
            next: DayStats
        };
    };
    update_date: string;
    hotel_name: string;
    fornova_id: number;
}

export default class ClusterDeepAnalysisModel extends ClusterHotelsModel {
    @Expose()
    @Transform((_, plain: DeepAnalysisClusterResponse) => plain.update_date)
    updateDate!: string;

    @Expose()
    @Transform((_, plain: DeepAnalysisClusterResponse) => {
        if (!plain.details || !Object.keys(plain.details).length) {
            return null;
        }

        const { document } = plainToClass(DeepAnalysisDocumentModel, plain.details, { excludeExtraneousValues: true });

        return document;
    })
    compsetMain!: DeepAnalysisDocumentModel['document'];
}
