import { inject, injectable } from '@/inversify';
import { Route } from 'vue-router';
import { IGuard } from '@/router/interfaces/guard.interface';
import UserNavigationService, { UserNavigationServiceS } from '@/modules/user/user-navigation.service';

export const FeaturesGuardS = Symbol.for('FeaturesGuardS');
@injectable()
export default class FeaturesGuard implements IGuard {
    @inject(UserNavigationServiceS) private userNavigationService!: UserNavigationService;

    async canActivate(to: Route) {
        return this.userNavigationService.checkIsRouteAllowed(to.fullPath);
    }
}
