import ASSESSMENT_TYPES from './assessments-types.constant';
import { COLOR_NAME } from './color-names';

export const ASSESSMENT_COLORS = {
    [ASSESSMENT_TYPES.GOOD]: COLOR_NAME.GREEN,
    [ASSESSMENT_TYPES.NORMAL]: COLOR_NAME.YELLOW,
    [ASSESSMENT_TYPES.BAD]: COLOR_NAME.RED,
    [ASSESSMENT_TYPES.SOLD_OUT]: COLOR_NAME.GRAY,
    [ASSESSMENT_TYPES.NO_DATA]: COLOR_NAME.WHITE,
    [ASSESSMENT_TYPES.OUT_OF_RANGE]: COLOR_NAME.WHITE,
    [ASSESSMENT_TYPES.NA]: COLOR_NAME.RED,
} as Record<ASSESSMENT_TYPES, COLOR_NAME>;
