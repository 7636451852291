import { inject, injectable } from '@/inversify';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import ClusterService, { ClusterServiceS } from '@/modules/cluster/cluster.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import ClusterStore from './store/cluster.store';
import PAGES from '../common/constants/pages.constant';
import ClusterInsightsModel from './models/cluster-insights.model';
import InsightFiltersService, { InsightFiltersServiceS } from '../document-filters/insight-filters.service';

export const ClusterInsightsServiceS = Symbol.for('ClusterInsightsServiceS');
@injectable()
export default class ClusterInsightsService implements Stateable {
    @inject(StoreFacadeS) storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(ClusterServiceS) private clusterService!: ClusterService;
    @inject(InsightFiltersServiceS) private insightFiltersService!: InsightFiltersService;

    readonly storeState: ClusterStore = this.storeFacade.getState('ClusterStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.storeState.provider,
            this.insightFiltersService.settings,

        ], (n, o) => {
            if (JSON.stringify(o) === JSON.stringify(n)) return;
            this.clusterService.resetLoading();
        });
    }

    get hotels() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.INSIGHTS),
        );
        return this.storeState.clusterHotels as ClusterInsightsModel[];
    }

    get hotelsCount() {
        this.helperService.dynamicLoading(
            this.storeState.loading,
            this.clusterService.loadData.bind(this.clusterService, PAGES.INSIGHTS),
        );
        return this.storeState.totalCount as number;
    }
}
