import { SCAN_DEFAULT_ESTIMATION, SCAN_DEFAULT_ESTIMATION_ONE_DAY } from '../constants';
import SCAN_STATUS from '../constants/scan-status.constant';

export type RatesDocumentScanValues = {
    id: string | null;
    documentId: string | null;
    startTime: string | null;
    endTime: string | null;
    estimation: number;
    estimationOneDay: number;
    ratio: number;
    status: SCAN_STATUS;
};

const defaultValues = {
    id: null,
    documentId: null,
    startTime: null,
    endTime: null,
    estimation: SCAN_DEFAULT_ESTIMATION,
    estimationOneDay: SCAN_DEFAULT_ESTIMATION_ONE_DAY,
    ratio: 0,
    status: SCAN_STATUS.FINISHED,
};

export default class RatesDocumentScanModel {
    values = { ...defaultValues } as RatesDocumentScanValues;

    start(estimation?: number) {
        this.update({
            startTime: (new Date()).toISOString(),
            endTime: null,
            ratio: 0,
            status: SCAN_STATUS.IN_PROGRESS,
            estimation: estimation !== undefined ? estimation : this.values.estimation,
        });
    }

    finish() {
        this.update({
            endTime: (new Date()).toISOString(),
            status: SCAN_STATUS.FINISHED,
            ratio: 1,
        });
    }

    update(values: Partial<RatesDocumentScanValues>) {
        this.values = {
            ...this.values,
            ...values,
        };
    }

    reset() {
        this.values = { ...defaultValues } as RatesDocumentScanValues;
    }
}
