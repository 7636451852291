export const DEFAULT_GRAPH_COLORS = [
    '#555555',
    '#ED4B82',
    '#FFD7E4',
    '#9DFFDC',
    '#5D6D7E',
    '#69E6B9',
    '#81D55A',
    '#B193CB',
    '#D97A79',
    '#FFD205',
    '#9BA3AC',
];

export const hexToRgb = (hexColor: string) => {
    if (hexColor.length === 4) {
        return `${parseInt(hexColor[1] + hexColor[1], 16)},${parseInt(hexColor[2] + hexColor[2], 16)},${parseInt(hexColor[3] + hexColor[3], 16)}`;
    }

    if (hexColor.length === 7) {
        return `${parseInt(hexColor[1] + hexColor[2], 16)},${parseInt(hexColor[3] + hexColor[4], 16)},${parseInt(hexColor[5] + hexColor[6], 16)}`;
    }

    return '0,0,0';
};
