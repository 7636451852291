import { Expose, Transform } from 'class-transformer';
import {
    IsString, IsEnum, IsInt, IsNumber,
} from 'class-validator';

export default class SippModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id)
    @IsString()
    id!: string;

    @Expose()
    @Transform((_, plain) => plain.car_category)
    @IsString()
    carCategory!: string;

    @Expose()
    @Transform((_, plain) => plain.normalized_car_category)
    @IsString()
    categoryName!: string;

    @Expose()
    @Transform((_, plain) => plain.car_description)
    @IsString()
    carDescription!: string;

    @Expose()
    @Transform((_, plain) => plain.country_code)
    @IsString()
    countryCode!: string;

    @Expose()
    @Transform((_, plain) => plain.creation_timestamp)
    creationTimestamp!: string;

    @Expose()
    @Transform((_, plain) => plain.customer_sipp_code)
    customerSippCode!: string | null;

    @Expose()
    @Transform((_, plain) => plain.fn_sipp_code)
    fnSippCode!: string;

    @Expose()
    @Transform((_, plain) => plain.transmission)
    transmission!: string;

    @Expose()
    @Transform((_, plain) => plain.provider_name)
    provider!: string;

    @Expose()
    @Transform((_, plain) => plain.brand_name)
    brand!: string;

    @Expose()
    @Transform((_, plain) => (plain.vehicle_type ? plain.vehicle_type : ''))
    vehicleType!: string;

    @Expose()
    @Transform((_, plain) => plain.country_name)
    countryName!: string;

    @Expose()
    @Transform((_, plain) => plain.fuel_type)
    fuelType!: string;
}
