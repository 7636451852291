import { Expose, Transform } from 'class-transformer';

export default class KpisDataModel {
    @Expose()
    @Transform((_, plain) => plain.severeIssues)
    severeIssues!: number;

    @Expose()
    @Transform((_, plain) => plain.allocationIssues)
    allocationIssues!: number;

    @Expose()
    @Transform((_, plain) => plain.soldOut)
    soldOut!: number;
}
