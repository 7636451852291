import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import ProvidersModel from '@/modules/providers/models/providers.model';

@Module
export default class ProvidersStore extends VuexModule {
    loading: LoadingModel = new LoadingModel();
    providerList: ProvidersModel[] | null = null;
    loadingFilter: LoadingModel = new LoadingModel();

    // FIXME Rename to ratesProviders
    providers: string[] = [];
    marketProviders: string[] = [];
    promotionProviders: string[] = ['booking', 'expedia', 'traveloka'];
}
