import Vue from 'vue';
import Vuex from 'vuex';
import RatesStore from '@/modules/rates/store/rates.store';
import RankingStore from '@/modules/ranking/store/ranking.store';
import MarketsStore from '@/modules/markets/store/markets.store';
import DocumentFiltersStore from '@/modules/document-filters/store/document-filters.store';
import UserStore from '@/modules/user/store/user.store';
import AuthStore from '@/modules/auth/store/auth.store';
import UserSettings from '@/modules/user/store/user-settings.store';
import EventsStore from '@/modules/events/store/events.store';
import RoomTypesStore from '@/modules/room-types/store/room-types.store';
import CompsetsStore from '@/modules/compsets/store/compsets.store';
import DeepAnalysisStore from '@/modules/deep-analysis/store/deep-analysis.store';
import HotelsStore from '@/modules/hotels/store/hotels.store';
import NumberOfGuestsStore from '@/modules/number-of-guests/store/number-of-guests.store';
import MealTypesStore from '@/modules/meal-types/store/meal-types.store';
import CarsStore from '@/modules/cars/store/cars.store';
import CarsAlertStore from '@/modules/cars/store/cars.alert.store';
import CarsRateAlertStore from '@/modules/cars/store/cars.rate-alert.store';
import CarsFiltersStore from '@/modules/cars/store/cars-filters.store';
import AlertsStore from '@/modules/alerts/store/alerts-store';
import CarsNotificationsStore from '@/modules/cars/alerts/store/cars-notifications-store';
import HotelsRoomTypeStore from '@/modules/rooms-type-manager/store/hotels-room-type.store';
import HomeFiltersStore from '@/modules/home/store/home-filters.store';
import HomeStore from '@/modules/home/store/home.store';
import ExceptionHandlerStore from '@/modules/common/modules/exception-handler/store/exception-handler.store';
import ProvidersStore from '@/modules/providers/store/providers.store';
import RatesPriceHistoryStore from '@/modules/price-history/store/rates-price-history.store';
import CarsTableauStore from '@/modules/cars/modules/tableau/store/cars-tableau.store';
import ScoreStore from '@/modules/score/store/score.store';
import HotelCatalogStore from '@/modules/hotels/modules/hotel-catalog/store/hotel-catalog.store';
import CarsCategoryStore from '@/modules/cars-category-manager/store/cars-category.store';
import ParityStore from '@/modules/cars/modules/parity/store/parity.store';
import LocationAvailabilityStore from '@/modules/cars/modules/location-availability/store/location-availability.store';
import SippStore from '@/modules/cars/modules/sipp/store/sipp.store';
import FleetStore from '@/modules/cars/modules/fleet/store/fleet.store';
import FleetFiltersStore from '@/modules/cars/modules/fleet/store/fleet-filters.store';
import FleetHistoryStore from '@/modules/cars/modules/cars-price-history/store/fleet-history.store';
import ClusterStore from '@/modules/cluster/store/cluster.store';
import MarketsHistoryStore from '@/modules/common/modules/markets-history/store/markets-history.store';
import RankingHistoryStore from '@/modules/common/modules/ranking-history/store/ranking-history.store';
import CarsPriceHistoryStore from '@/modules/cars/store/cars-price-history.store';
import DiLiteStore from '@/modules/di-lite/store/di-lite.store';
import NotificationStore from '@/modules/common/modules/custom-notification/store/notification.store';
import ClusterScoreStore from '@/modules/cluster/modules/score/store/score.store';
import MarketStore from '@/modules/market/store/market.store';
import ChainRatesStore from '@/modules/chain/modules/rates/chain-rates.store';
import ChainMarketsStore from '@/modules/chain/modules/markets/chain-markets.store';
import ChainStore from '@/modules/chain/chain.store';
import PromotionsStore from '@/modules/promotions/promotions.store';
import ReportsStore from '@/modules/scheduled-reports/store/reports.store';
import InsightsStore from '@/modules/insights/insights.store';
import TranslationsStore from '@/modules/translations/translations.store';
import InsightFiltersStore from '@/modules/document-filters/store/insight-fiters.store';
import RVSStore from '@/modules/rvs/rvs.store';

Vue.use(Vuex);

let store;
export default store = new Vuex.Store({
    modules: {

        // Common
        AlertsStore,
        AuthStore,
        EventsStore,
        ExceptionHandlerStore,
        NotificationStore,
        UserStore,

        // CI
        CompsetsStore,
        DeepAnalysisStore,
        DiLiteStore,
        DocumentFiltersStore,
        InsightFiltersStore,
        HomeFiltersStore,
        HomeStore,
        HotelCatalogStore,
        HotelsRoomTypeStore,
        HotelsStore,
        MarketsHistoryStore,
        MarketsStore,
        MarketStore,
        MealTypesStore,
        NumberOfGuestsStore,
        PromotionsStore,
        ProvidersStore,
        RankingHistoryStore,
        RankingStore,
        RatesPriceHistoryStore,
        RatesStore,
        ReportsStore,
        RoomTypesStore,
        ScoreStore,
        UserSettings,
        InsightsStore,
        TranslationsStore,
        RVSStore,

        // cluster
        ClusterStore,
        ClusterScoreStore,

        // chain
        ChainMarketsStore,
        ChainRatesStore,
        ChainStore,

        // Cars
        CarsCategoryStore,
        CarsFiltersStore,
        CarsPriceHistoryStore,
        CarsStore,
        CarsTableauStore,
        FleetFiltersStore,
        FleetHistoryStore,
        FleetStore,
        LocationAvailabilityStore,
        ParityStore,
        SippStore,
        CarsAlertStore,
        CarsNotificationsStore,
        CarsRateAlertStore,
    },
    strict: false,
});
