import { inject, injectable } from '@/inversify';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';

export const CustomNotificationApiServiceS = Symbol.for('CustomNotificationApiServiceS');
@injectable()
export default class CustomNotificationApiService {
    @inject(ApiServiceS) private apiService!: ApiService;

    async getExcelDocument(reportId: number) {
        const res = await this.apiService.get(
            `report/download/${reportId}`,
            {},
            { responseType: 'blob' },
        ).catch(() => null);

        if (!res || !res.data) {
            return null;
        }

        return res.data as Blob;
    }
}
