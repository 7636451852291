enum PRICE_TYPE {
    LOWEST = 'lowest',
    LOWEST_FLEX = 'lowest_flex',
    BEST_FLEX = 'best_flex',
    NON_REFUNDABLE = 'non_refundable',
}

export const PRICE_TYPE_SHORT = {
    [PRICE_TYPE.LOWEST]: 'L',
    [PRICE_TYPE.LOWEST_FLEX]: 'LF',
    [PRICE_TYPE.BEST_FLEX]: 'BF',
    [PRICE_TYPE.NON_REFUNDABLE]: 'NRF',
};

export default PRICE_TYPE;
