import { Expose, plainToClass, Transform } from 'class-transformer';
import {
    Validate, IsNumber, IsOptional,
} from 'class-validator';
import type ICheckinDate from '@/modules/rates/interfaces/checkin-date.interface';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import SCAN_STATUS from '@/modules/rates/constants/scan-status.constant';
import RatesCheckinDatesValidator from '@/modules/rates/models/validators/checkin-dates.validator';
import { SCAN_DEFAULT_ESTIMATION, SCAN_DEFAULT_ESTIMATION_ONE_DAY } from '../constants';
import { RatesDocumentScanValues } from './rates-document-scan.model';

export default class RatesDocumentModel {
    @Expose()
    // eslint-disable-next-line
    @Transform((_, plain) => plain._id)
    @IsNumber()
    id!: number;

    @Expose()
    @Transform((_, plain) => {
        const checkinDates: any = {};

        if (!plain.checkin_dates) {
            return plain.checkin_dates;
        }

        Object.keys(plain.checkin_dates).forEach(day => {
            checkinDates[day] = {
                hotels: {}, demand: null, updateDate: null, occupancy: null,
            };
            Object.keys(plain.checkin_dates[Number(day)]).forEach(hotelId => {
                if (hotelId !== 'day_statistics') {
                    if (plain.checkin_dates[Number(day)][hotelId].rooms) {
                        checkinDates[day].hotels[hotelId] = { rooms: {}, updateDate: undefined, link: null };

                        Object.keys(plain.checkin_dates[Number(day)][hotelId].rooms).forEach(roomTypeId => {
                            checkinDates[day].hotels[hotelId].rooms[roomTypeId] = [];
                            const ratesDocumentItems: RatesDocumentItemModel[] = [];
                            Object.entries(plain.checkin_dates[Number(day)][hotelId].rooms[roomTypeId]).forEach(([__, ratesDocumentItem]) => {
                                ratesDocumentItems.push(
                                    plainToClass(
                                        RatesDocumentItemModel,
                                        <RatesDocumentItemModel> ratesDocumentItem,
                                        { excludeExtraneousValues: true },
                                    ),
                                );
                            });

                            const hotelData = checkinDates[Number(day)].hotels[hotelId];

                            hotelData.rooms[roomTypeId] = ratesDocumentItems;
                            hotelData.updateDate = plain.checkin_dates[Number(day)][hotelId].update_date;
                            hotelData.link = plain.checkin_dates[Number(day)][hotelId].link;
                            hotelData.losRestriction = plain.checkin_dates[Number(day)][hotelId].los_restricted;
                            hotelData.isSmartShopping = !!plain.checkin_dates[Number(day)][hotelId].is_smart_shopping;
                            hotelData.taskId = plain.checkin_dates[Number(day)][hotelId].task_id;
                            hotelData.screenshot = plain.checkin_dates[Number(day)][hotelId].screenshot || null;
                        });
                    }
                }

                checkinDates[Number(day)].updateDate = plain.checkin_dates[Number(day)].update_date
                    ? plain.checkin_dates[Number(day)].update_date
                    : null;

                const { day_statistics: dayStatistics } = plain.checkin_dates[Number(day)];
                checkinDates[Number(day)].demand = null;
                checkinDates[Number(day)].occupancy = null;

                if (!dayStatistics) {
                    return;
                }

                if (Number.isFinite(dayStatistics.demand)) {
                    if (dayStatistics.demand < 0) {
                        checkinDates[Number(day)].demand = 0;
                    } else {
                        checkinDates[Number(day)].demand = Math.round(dayStatistics.demand * 100);
                    }
                }

                if (Number.isFinite(dayStatistics.occupancy)) {
                    if (dayStatistics.occupancy < 0) {
                        checkinDates[Number(day)].occupancy = 0;
                    } else {
                        checkinDates[Number(day)].occupancy = Math.round(dayStatistics.occupancy);
                    }
                }
            });
        });

        return checkinDates;
    })
    @IsOptional()
    @Validate(RatesCheckinDatesValidator)
    checkinDates?: { [day: number]: ICheckinDate | null };

    @Expose()
    @Transform((_, plain) => plain.update_date && new Date(plain.update_date))
    updateDate!: Date | null;

    @Expose()
    @Transform((_, plain) => plain.currency)
    currency?: string;

    @Expose()
    @Transform((_, plain) => plain.provider_name)
    providerName!: string;

    @Expose()
    @Transform((_, plain) => plain.hotelsName)
    hotelNames!: { [hotelId: number]: string };

    @Expose()
    @Transform((_, plain) => ({
        id: (plain.scan_id && plain.scan_id.length) ? plain.scan_id[plain.scan_id.length - 1] : null,
        status: plain.status || SCAN_STATUS.FINISHED,
        estimation: plain.ui_notify?.estimated_time_one_day_sec
            ? plain.ui_notify.estimated_time_one_day_sec * plain.ui_notify.last_trigger_days
            : SCAN_DEFAULT_ESTIMATION,
        estimationOneDay: plain.ui_notify?.estimated_time_one_day_sec || SCAN_DEFAULT_ESTIMATION_ONE_DAY,
        startTime: plain.status === SCAN_STATUS.IN_PROGRESS
            ? plain.ui_notify?.last_trigger_time || (new Date()).toISOString()
            : null,
        endTime: plain.update_date || null,
        ratio: 0,
    }))
    scan!: Omit<RatesDocumentScanValues, 'documentId'>;

    /**
     * Pass 'requested_pos' query parameter to get this property
     */
    @Expose()
    @Transform((_, plain) => plain.exchange_rate || 1)
    exchangeRate!: number;

    @Expose()
    @Transform((_, plain) => plain.pos)
    pos!: string;

    @Expose()
    @Transform(() => [1, 2, 3, 4, 5, 6, 7, 8, 9, 10])
    numberOfGuestsOptions!: number[];
}
