export const defaultPercentageVal = [
    { name: '5', value: 5 },
    { name: '10', value: 10 },
    { name: '15', value: 15 },
    { name: '20', value: 20 },
];

export enum CarAlertType { SIPP_CODE = 'sipp_code', CAR_CATEGORY = 'car_category', RATE_DIFF = 'rate_diff' }

export enum CarAlertStatusType { ACTIVE = 'active', INACTIVATE = 'inactive' }
