import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import SippItemModel from '@/modules/cars/modules/sipp/models/sipp-item.model';
import SippSettingsModel from '@/modules/cars/modules/sipp/models/sipp-settings.model';
import {
    TRANSMISSION_ANY, PROVIDER_ANY, VEHICLE_TYPE_ANY, COUNTRIES_ANY, FUEL_TYPE_ANY, BRAND_ANY,
} from '@/modules/cars/constants/car-filter-types.constant';
import SippDiffModel from '@/modules/cars/modules/sipp/models/sipp-diff.model';
import SippFiltersModel from '@/modules/cars/modules/sipp/models/sipp-filters.model';
import SippLogItemModel from '@/modules/cars/modules/sipp/models/sipp-log-item.model';
import SippLogChangeItemModel from '@/modules/cars/modules/sipp/models/sipp-log-change-item.model';

@Module
export default class SippStore extends VuexModule {
    documents: SippItemModel[] | null = null;
    previewDocuments: SippItemModel[] | null = null;
    historyLogs: {
        logs: SippLogItemModel[] | null,
        version: number | null,
        changes: SippLogChangeItemModel[] | null,
        loading: LoadingModel,
        showChangesOnly: boolean;
    } = {
        logs: null,
        version: null,
        changes: null,
        loading: new LoadingModel(),
        showChangesOnly: false,
    };
    totalCount: number | null = null;
    totalPages: number = 1;
    page: number = 1;

    settings: SippSettingsModel = {
        carName: '',
        standardCode: '',
        customCode: '',
        categoryName: '',
        transmission: TRANSMISSION_ANY,
        carClasses: '',
        pos: '',
        provider: PROVIDER_ANY,
        vehicleType: VEHICLE_TYPE_ANY,
        countryName: COUNTRIES_ANY,
        fuelType: FUEL_TYPE_ANY,
        carBrand: BRAND_ANY,
    };

    filters: SippFiltersModel = {
        carName: '',
        standardCode: '',
        customCode: '',
        transmission: null,
        carClasses: null,
        pos: null,
        providers: null,
        vehicleTypes: null,
        fuelTypes: null,
        carBrands: null,
    };
    newCategories: string[] = [];
    diffDocuments: SippDiffModel[] = [];

    loading: LoadingModel = new LoadingModel();
}
