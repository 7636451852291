import NOTIFICATION_TYPES from '@/modules/common/modules/custom-notification/constants/notification-types.constant';

export default class NotificationItemModel {
    id: string = '';
    position: string = 'top-middle';
    reportId: number = 0;
    fileName: string = '';
    isReady: boolean = false;
    progress: number = 0;
    type: NOTIFICATION_TYPES = NOTIFICATION_TYPES.EXCEL;
    message?: string = '';
    className?: string;
}
