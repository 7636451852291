import { inject, injectable } from '@/inversify';
import _ from 'lodash';
import moment from 'moment';

import type Day from '@/modules/common/types/day.type';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import { PRICE_SHOWN } from '@/modules/rates/constants';

import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';

import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import RatesPriceHistoryStore from '@/modules/price-history/store/rates-price-history.store';
import RatesCommonService, { RatesCommonServiceS } from '@/modules/common/modules/rates/rates-common.service';
import RatesAnalysisService, { RatesAnalysisServiceS } from '@/modules/rates/rates-analysis.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import RatesPriceHistoryCommonService, { RatesPriceHistoryCommonServiceS } from './rates-price-history-common.service';
import RatesPriceHistoryModel from './models/rates-price-history.model';

export type RoomDictionary = { [hotelId: number]: RatesDocumentItemModel };

interface RatesPriceHistoryPublicInterface {
    /**
     * Sets document key that will be used to get data from specific document
     * Initial value - `main`
     */
    setDataKey(key: string): void;

    /**
     * Returns dictionary with hotels and their rooms for the specified day
     *
     * @param day - which history day (from the `lastScanDate`) the data should be taken from
     * @param diffDays - **For Compare Mode Only** - days ago from the `day` param
     */
    getSuitableRoomByDay(day: Day, diffDays: number): RoomDictionary;

    /**
     * Choosen currency
     */
    currency: string | null;

    /**
     * Last scan date
     */
    readonly lastScanDate: Date | null;

    /**
     * Hotel ids list
     */
    readonly hotels: number[] | null;
}

export const RatesPriceHistoryServiceS = Symbol.for('RatesPriceHistoryServiceS');
@injectable()
export default class RatesPriceHistoryService implements Stateable, RatesPriceHistoryPublicInterface {
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(RatesCommonServiceS) private ratesCommonService!: RatesCommonService;
    @inject(RatesAnalysisServiceS) private ratesAnalysisService!: RatesAnalysisService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;
    @inject(RatesPriceHistoryCommonServiceS) private ratesPriceHistoryCommonService!: RatesPriceHistoryCommonService;

    readonly storeState: RatesPriceHistoryStore = this.storeFacade.getState('RatesPriceHistoryStore');

    currentDataKey: string = 'main';

    get currency() {
        return this.ratesPriceHistoryCommonService.currency;
    }

    set currency(value: string | null) {
        this.ratesPriceHistoryCommonService.currency = value;
    }

    get lastScanDate() {
        return this.ratesPriceHistoryCommonService.lastScanDate;
    }

    get hotels() {
        let hotelIds: number[] = [];
        const { compset } = this.ratesPriceHistoryCommonService;

        if (compset?.competitors && compset.ownerHotelId) {
            const competitorsFilters = this.documentFiltersService.competitors;

            const filteredCompetitors = competitorsFilters
                ? compset.competitors.filter((value: number) => competitorsFilters.includes(value))
                : compset.competitors;

            hotelIds = [...filteredCompetitors, compset.ownerHotelId];
        }

        return hotelIds.length ? hotelIds : null;
    }

    private set documents(value) {
        this.storeState.trendDocuments = value;
    }

    private get documents() {
        return this.storeState.trendDocuments;
    }

    getDateByHistoryDay(day: number) {
        if (!this.lastScanDate) {
            return null;
        }

        const dateInstance = new Date(this.lastScanDate);
        dateInstance.setMinutes(dateInstance.getMinutes() + dateInstance.getTimezoneOffset());

        dateInstance.setDate(dateInstance.getDate() - day);

        return moment(dateInstance).format('DD-MM-YYYY');
    }

    setDataKey(value: string) {
        this.currentDataKey = value;
        return this;
    }

    getSuitableRoomByDay(dayParam: Day | number, diffDays = 0): RoomDictionary {
        const { documents, hotels: hotelList, lastScanDate } = this;
        const EMPTY_DICTIONARY = {} as RoomDictionary;

        if (!this.currentDataKey) {
            this.currentDataKey = 'main';
        }

        const isParametersNotValid = false
            || !hotelList
            || !documents
            || !documents[this.currentDataKey]
            || !lastScanDate;

        if (isParametersNotValid) {
            return EMPTY_DICTIONARY;
        }

        const { trendData } = documents[this.currentDataKey]!;

        if (!trendData) {
            return EMPTY_DICTIONARY;
        }

        const date = this.getDateByHistoryDay(dayParam + diffDays)!;
        const data = trendData[date];

        if (!data) {
            if (diffDays && diffDays < 14) {
                return this.getSuitableRoomByDay(dayParam, diffDays + 1);
            }

            return EMPTY_DICTIONARY;
        }

        let hotels = {};

        if (this.ratesPriceHistoryCommonService.isAllChannels) {
            hotels = data;
        } else {
            ({ hotels } = data);
        }

        const allHotels = this.ratesCommonService.getAllRoomsByHotels(hotels);

        const byValidRooms = ([__, room]: any) => !!room;

        const filteredHotelsEntries = Object
            .entries(allHotels)
            .map(([hotelId, room]) => {
                const hid = +hotelId || hotelId;
                const isHotelExists = true
                    && this.hotels
                    && this.hotels.includes((hid) as number);

                if (isHotelExists) {
                    return [hid, room];
                }

                return [-1, null];
            })
            .filter(byValidRooms);

        const filteredHotels = Object.fromEntries(filteredHotelsEntries);

        return filteredHotels as RoomDictionary;
    }
}
