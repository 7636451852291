/* eslint-disable no-underscore-dangle, camelcase */
import { Expose, Transform } from 'class-transformer';

import { IsOptional } from 'class-validator';

interface Location {
    coordinates: [number, number],
    // eslint-disable-next-line camelcase
    distance_from_hotel :number,
    city: string
}

export default class EventsDTO {
    @Expose()
    @Transform((_, plain) => plain.marketId)
    marketId!: number;

    @Expose()
    @Transform((_, plain) => plain.name)
    name!: string;

    @Expose()
    @Transform((_, plain) => plain.type)
    type!: string;

    @Expose()
    @Transform((_, plain) => plain.startDate)
    starts!: string;

    @Expose()
    @Transform((_, plain) => plain.endDate)
    ends!: string;

    @Expose()
    @Transform((_, __) => ({
        coordinates: [32.065070, 34.762880],
        distance_from_hotel: 300,
        city: 'Tel Aviv',
    }))
    location!: Location;

    @Expose()
    @Transform((_, plain) => plain.numberOfGuests)
    no_of_guests!: number;

    @Expose()
    @Transform((_, plain) => plain.description)
    description!: string;

    @Expose()
    @Transform((_, plain) => plain.url)
    url!: URL;

    @Expose()
    @IsOptional()
    @Transform((_, plain) => plain.entityId)
    entity_id?: string | number;

    @Expose()
    @IsOptional()
    @Transform((_, plain) => plain.entityType)
    entity_type?: string;
}

export class EventsDTOUpdate {
    @Expose()
    @Transform((_, plain) => plain.marketId)
    marketId!: number;

    @Expose()
    @Transform((_, plain) => plain.name)
    name!: string;

    @Expose()
    @Transform((_, plain) => plain.type)
    type!: string;
    @Expose()
    @Transform((_, plain) => plain.startDate)
    starts!: string;

    @Expose()
    @Transform((_, plain) => plain.endDate)
    ends!: string;

    @Expose()
    @Transform((_, __) => ({
        coordinates: [32.065070, 34.762880],
        distance_from_hotel: 300,
        city: 'Tel Aviv',
    }))
    location!: Location;

    @Expose()
    @Transform((_, plain) => plain.numberOfGuests)
    no_of_guests!: number;

    @Expose()
    @Transform((_, plain) => plain.description)
    description!: string;

    @Expose()
    @Transform((_, plain) => plain.url)
    url!: URL;
}
