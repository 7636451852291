import { Expose, Transform } from 'class-transformer';
import { IsString } from 'class-validator';

export default class SippLogChangeItemModel {
    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id)
    @IsString()
    id!: string;

    @Expose()
    @Transform((_, plain) => plain.current_customer_sipp_code)
    currentCode!: string;

    @Expose()
    @Transform((_, plain) => plain.new_customer_sipp_code)
    newCode!: string;

    @Expose()
    @Transform((_, plain) => plain.current_customer_normalized_category)
    currentCategoryName!: string;

    @Expose()
    @Transform((_, plain) => plain.new_customer_normalized_category)
    newCategoryName!: string;

    @Expose()
    @Transform((_, plain) => plain.current_vehicle_type)
    currentVehicleType!: string;

    @Expose()
    @Transform((_, plain) => plain.new_vehicle_type)
    newVehicleType!: string;
}
