enum ALERT_TYPES {
    RATE_DROP = 'RateDrop',
    RATE_CHANGE = 'RateChange',
    POSITION_CHANGE = 'PositionChange',
    POSITION_DROP = 'PositionDrop',
    SCHEDULED_REPORT = 'ScheduledReports',
    HTML_REPORT = 'HTMLReports',
    PROMOTION_PERCENT_CHANGE = 'PromotionsDetectionsDealsPercentageChange',
    PROMOTION_DETECITON = 'PromotionDetection',
    SPECIAL_DATES = 'IntradaySpecialDates',
    TREND_DETECTION = 'TrendDetected',
    VISIBILITY_DRAMATIC_CHANGE = 'VisibilityDramaticChange',
}

export default ALERT_TYPES;
