import {
    ValidatorConstraint,
    ValidatorConstraintInterface,
    ValidationArguments,
    validate,
} from 'class-validator';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import { inject } from '@/inversify';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';

interface ITrendData {
    [date: string]: {
        [hotelId: number]: {
            rooms: {
                [roomTypeId: number]: RatesDocumentItemModel[],
            }
        }
    } | null
}

@ValidatorConstraint({ name: 'TrendData', async: true })
export default class PriceHistoryTrendDataValidator implements ValidatorConstraintInterface {
    @inject(ValidatorServiceS) private validatorService!: ValidatorService;

    private errorMessage = 'Price history trend_data not valid';

    private async validateDocumentItem(documentItems: RatesDocumentItemModel[][]) {
        let documentItemErrors: string[] = [];

        // Validate all CarsDocumentItems and collect erros
        for (let i = 0; i < documentItems.length; i += 1) {
            // eslint-disable-next-line no-await-in-loop
            documentItemErrors = documentItemErrors.concat(await Promise.all(documentItems[i].map(item => (
                this.validatorService.validateResponse(item)
            )).filter(errorItem => errorItem)) as string[]);
        }

        return Array.from(new Set(documentItemErrors)).join('; ');
    }

    async validate(trendData: ITrendData | null, args: ValidationArguments) {
        let isValid = true;

        let ratesDocumentItems: RatesDocumentItemModel[][] = [];

        if (!trendData) {
            return isValid;
        }

        Object.keys(trendData).forEach(date => {
            if (!date) {
                this.errorMessage += ', date is not defined';
                isValid = false;
            }

            Object.keys(trendData[date]!).forEach(hotelId => {
                if (Number.isNaN(Number(hotelId))) {
                    this.errorMessage += ', hotelId is not a number';
                    isValid = false;
                }
                ratesDocumentItems = ratesDocumentItems.concat(
                    Object.entries(trendData[date]![Number(hotelId)].rooms).map(([roomTypeId, ratesItemDocument]) => {
                        if (Number.isNaN(Number(roomTypeId))) {
                            this.errorMessage += ', roomTypeId is not a number';
                            isValid = false;
                        }
                        return ratesItemDocument;
                    }),
                );
            });
        });

        if (!ratesDocumentItems.length) {
            return isValid;
        }

        const errors = await this.validateDocumentItem(ratesDocumentItems);

        if (!errors) {
            return isValid;
        }

        isValid = false;
        this.errorMessage += `, ${errors}`;

        return isValid;
    }

    defaultMessage(args: ValidationArguments) {
        return this.errorMessage;
    }
}
