/* eslint-disable no-underscore-dangle */
import { Expose, Transform } from 'class-transformer';

export default class SpecialDateModel {
    @Expose()
    @Transform((_, plain) => plain._id)
    id!: string;

    @Expose()
    @Transform((_, plain) => plain.date_name)
    name!: string;

    @Expose()
    @Transform((_, plain) => plain.start_date)
    start!: string;

    @Expose()
    @Transform((_, plain) => plain.end_date)
    end!: string;
}
