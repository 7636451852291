import { Module, VuexModule } from 'vuex-module-decorators';

import CompsetModel from '@/modules/compsets/models/compset.model';
import { ExtendedCompetitorInterface } from '@/modules/compsets/interfaces';
import LoadingModel from '@/modules/common/models/loading.model';
import HotelCatalogItemModel from '@/modules/hotels/modules/hotel-catalog/models/hotel-catalog-item.model';
import { CompsetCreationBody } from '../interfaces/compset-creation-body.interface';
import COMPSET_TYPE from '../constants/compset-type.constant';

@Module
export default class CompsetsStore extends VuexModule {
    compsets: CompsetModel[] | null = null;
    localCompsets: CompsetModel[] | null = null;

    onboarding = {
        data: {
            customerHotelFornovaId: -1,
            competitors: [] as number[],
            pos: ['US'],
            los: [2],
            rateProviders: ['booking', 'expedia'],
            marketProviders: ['booking', 'expedia'],
            type: COMPSET_TYPE.MEDIAN,
            name: 'Median',
            mainPos: 'US',
            dailyScans: 30,
            dayHour: 9,
            dayWeek: 'MON',
            onDemand: true,
            onboarded: true,
        } as CompsetCreationBody,

        choosedHotel: null as (HotelCatalogItemModel | null),
        choosedCompetitors: [] as HotelCatalogItemModel[],
    };

    loading: LoadingModel = new LoadingModel();

    settingsActiveCompsetId: string | null = null;
    settingsExtendedCompetitors: ExtendedCompetitorInterface[] | null = null;
}
