const DOORS_ANY = 'Any';
const MILEAGE_ANY = 'Any';
const TRANSMISSION_ANY = 'Any';
const CAR_CLASS_ANY = 'Any';
const CAR_CLASS_BLANK = 'Blank';
const PROVIDER_ANY = 'Any';
const PROVIDER_NONE = 'None';
const POS_ANY = 'Any';
const PAYMENT_TERMS_ANY = 'Any';
const COUNTRIES_ANY = 'Any';
const FUEL_TYPE_ANY = 'Any';
const VEHICLE_TYPE_ANY = 'Any';
const BRAND_AS_BROKER_ANY = 'Any';
const BROKER_TO_BRAND = 'BrokerToBrand';
const BROKER_TO_BROKER = 'BrokerToBroker';
const BRAND_BLANK = 'Blank';
const BRAND_ANY = 'Any';

export {
    BRAND_ANY,
    DOORS_ANY,
    MILEAGE_ANY,
    TRANSMISSION_ANY,
    CAR_CLASS_ANY,
    POS_ANY,
    PAYMENT_TERMS_ANY,
    COUNTRIES_ANY,
    PROVIDER_ANY,
    PROVIDER_NONE,
    CAR_CLASS_BLANK,
    VEHICLE_TYPE_ANY,
    BRAND_AS_BROKER_ANY,
    BROKER_TO_BROKER,
    BROKER_TO_BRAND,
    FUEL_TYPE_ANY,
    BRAND_BLANK,
};
