import { Expose, Transform, Type } from 'class-transformer';

export default class CountryModel {
    @Expose()
    capital!: string;

    @Expose()
    continentName!: string;

    @Expose()
    countryCode!: string;

    @Expose()
    countryCodeIso3!: string;

    @Expose({ name: 'countryNames' })
    @Transform((value: string[]) => value.pop())
    countryName!: string;

    @Expose()
    currencyCode!: string;

    @Expose()
    @Type(() => Number)
    population!: number;
}
