import { Expose, Transform } from 'class-transformer';

export default class RatesScanProgressModel {
    @Expose()
    @Transform((_, plain) => plain.ratio)
    ratio!: number;

    @Expose()
    @Transform((_, plain) => Number(plain.groupedDocsIds[plain.groupedDocsIds.length - 1]))
    ratesDocumentId!: number;
}
