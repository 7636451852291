import { inject, injectable } from '@/inversify';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';

export const NumberOfGuestsApiServiceS = Symbol.for('NumberOFGuestsApiServiceS');
@injectable()
export default class NumberOfGuestsApiService {
    @inject(ApiServiceS) private apiService!: ApiService;

    async getNumbersOfGuests(fornovaId: number): Promise<number[] | null> {
        const res = await this.apiService.get(`users/compsets/${fornovaId}`);

        if (!res || !res.data || !res.data.occupancy || !res.data.occupancy.length) {
            return null;
        }

        return res.data.occupancy;
    }
}
