import FleetAvailabilityTrendsDocumentModel from '@/modules/cars/models/fleet-availability-trends-document.model';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import CarRatesPriceHistoryModel from '../models/car-rates-price-history.model';

@Module
export default class CarsPriceHistoryStore extends VuexModule {
    document: CarRatesPriceHistoryModel | null = null ;
    occupancyDocument: FleetAvailabilityTrendsDocumentModel | null = null;
    loading: LoadingModel = new LoadingModel();
    loadingOccupancy: LoadingModel = new LoadingModel();
}
