export enum PromotionGraphType {
    DEALS = 'deal',
    PROGRAMS = 'programs',
}

export default class PromotionsSettings {
    provider: string | null = null;
    programView: string = '';
    graphType: PromotionGraphType = PromotionGraphType.PROGRAMS;
    competitors: number[] = [];
}
