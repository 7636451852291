import VueRouter from 'vue-router';
import initRoutes from '@/router/routes';

export const routerData = {
    router: null as unknown as VueRouter,
};

export function initRouter() {
    const router = new VueRouter({
        mode: 'history',
        routes: initRoutes(),
        scrollBehavior(to, from, savedPosition) {
            if (to.meta?.modal && !from.meta?.modal) {
                return savedPosition;
            }
            if (!to.meta?.modal && from.meta?.modal) {
                return savedPosition;
            }
            return { x: 0, y: 0 };
        },
    });

    routerData.router = router;

    return router;
}
