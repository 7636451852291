import { Expose, Transform } from 'class-transformer';

export default class NotificationSocketModel {
    @Expose()
    @Transform((_, plain) => plain.report_id)
    reportId: number = 0;

    @Expose()
    @Transform((_, plain) => plain.file_name)
    fileName: string = '';
}
