import { inject, injectable } from '@/inversify';
import _ from 'lodash';
import ParityStore from '@/modules/cars/modules/parity/store/parity.store';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import LocationAvailabilityStore from './modules/location-availability/store/location-availability.store';
import FleetStore from './modules/fleet/store/fleet.store';
import CarsStore from './store/cars.store';
import CarsFiltersStore from './store/cars-filters.store';
import DocumentFiltersService, { DocumentFiltersServiceS } from '../document-filters/document-filters.service';
import Month from '../common/types/month.type';

export const CarsSharedServiceS = Symbol.for('CarsSharedServiceS');

@injectable()
export default class CarsSharedService {
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(DocumentFiltersServiceS) protected documentFiltersService!: DocumentFiltersService;

    readonly parityStoreState: ParityStore = this.storeFacade.getState('ParityStore');
    readonly locationAvailabilityStoreState: LocationAvailabilityStore = this.storeFacade.getState('LocationAvailabilityStore');
    readonly fleetStoreState: FleetStore = this.storeFacade.getState('FleetStore');
    readonly carsStoreState: CarsStore = this.storeFacade.getState('CarsStore');
    readonly carsFilterStoreState: CarsFiltersStore = this.storeFacade.getState('CarsFiltersStore');

    savePickUpCity(value: string | null, path?: string) {
        this.fleetStoreState.settings.pickUpCityCode = value;
        this.carsStoreState.settings.pickUpCityCode = value;
        this.parityStoreState.settings.pickUpCityCodes = value ? [value] : [];
        this.locationAvailabilityStoreState.settings.pickUpLocationIds = value ? [value] : [];

        this.saveCountryByCallerService(path);
    }

    savePickUpCities(locationIds: string[] | null, path?: string) {
        if (locationIds) {
            const [firstSelectionId] = locationIds;
            this.fleetStoreState.settings.pickUpCityCode = firstSelectionId;
            this.carsStoreState.settings.pickUpCityCode = firstSelectionId;
            this.parityStoreState.settings.pickUpCityCodes = locationIds;
            this.locationAvailabilityStoreState.settings.pickUpLocationIds = locationIds;

            this.saveCountryByCallerService(path);
        }
    }

    saveCountryByCallerService(path?: string) {
        if (!path) {
            return;
        }
        switch (path) {
            case 'parity': {
                this.saveCountry(this.parityStoreState.settings.country);
                break;
            }
            case 'location-availability': {
                this.saveCountry(this.locationAvailabilityStoreState.settings.country);
                break;
            }
            case 'cars-rates': {
                this.saveCountry(this.carsStoreState.settings.country);
                break;
            }
            case 'fleet-density': {
                this.saveCountry(this.fleetStoreState.settings.country);
                break;
            }
            default: {
                break;
            }
        }
    }

    saveCountry(value: string | null) {
        this.fleetStoreState.settings.country = value;
        this.carsStoreState.settings.country = value;
        this.locationAvailabilityStoreState.settings.country = value;
        this.parityStoreState.settings.country = value;
    }

    saveCluster(value: string | null) {
        this.carsStoreState.settings.cluster = value;
        this.carsStoreState.settings.isAvgPrice = !!value;
    }

    getLocationNameById(locationId: string) {
        return this.carsFilterStoreState.settings.locations
            ? this.carsFilterStoreState.settings.locations.find(loc => loc.locationId === locationId)
            : null;
    }

    isLocationsFilterLoaded() {
        return !!this.carsFilterStoreState.settings.locations;
    }

    savePos(value: string) {
        this.fleetStoreState.settings.pos = value;
        this.carsStoreState.settings.pos = value;
        this.locationAvailabilityStoreState.settings.pos = value;
    }

    updateFiltersFromSessionStorageIfExist() {
        const sessionData = sessionStorage.getItem('sessionData');
        if (sessionData) {
            const sessionDataObj = JSON.parse(sessionData);
            const [dataSource] = sessionDataObj.data_source;
            const carSettings = this.carsStoreState.settings;

            carSettings.cluster = null;
            carSettings.country = 'Any';
            carSettings.isAvgPrice = false;
            carSettings.dataSource = dataSource;
            carSettings.lor = Number(sessionDataObj.lor);
            carSettings.pos = sessionDataObj.pos;
            carSettings.pickUpCityCode = sessionDataObj.location;
            carSettings.carClasses = sessionDataObj.carCategories;
            carSettings.carFuelTypes = sessionDataObj.fuelTypes;
            carSettings.carClassesChange = true;
            carSettings.carFuelTypeChange = true;

            this.documentFiltersService.storeState.settings.year = sessionDataObj.year;
            this.documentFiltersService.storeState.settings.month = (sessionDataObj.month - 1) as Month;

            sessionStorage.removeItem('sessionData');
        }
    }
}
