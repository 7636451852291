import { Expose, plainToClass, Transform } from 'class-transformer';
import type ICheckinDatesHotels from '../interfaces/checkin-dates-hotels.interface';
import RatesDocumentItemModel from './rates-document-item.model';

export default class RatesDocumentIntradayModel {
    @Expose()
    @Transform((_, plain) => (
        Object
            .entries(plain)
            .reduce((acc, [hotelId, value]) => {
                const rooms = Object
                    .entries((value as { rooms: {} }).rooms || {})
                    .reduce((roomsAcc, [roomTypeId, rooms]) => {
                        const res = {
                            ...roomsAcc,

                            [roomTypeId]: (rooms as {}[])
                                .map(room => plainToClass(RatesDocumentItemModel, room, { excludeExtraneousValues: true })),
                        };

                        return res;
                    }, {} as { [roomTypeId: string]: RatesDocumentItemModel[] });

                acc[+hotelId] = {
                    rooms,
                    link: null,
                    screenshot: null,
                };

                return acc;
            }, {} as ICheckinDatesHotels)
    ))
    hotels!: ICheckinDatesHotels;
}
