import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import { InsightFilterOptionsModel, InsightFilterSettingsModel } from '../models/insight-filters.model';

@Module
export default class InsightFiltersStore extends VuexModule {
    settings: InsightFilterSettingsModel = new InsightFilterSettingsModel();
    options: InsightFilterOptionsModel = new InsightFilterOptionsModel();
    loading: {
        insightTypes: LoadingModel,
    } = {
        insightTypes: new LoadingModel(),
    };
}
