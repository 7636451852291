import { inject as __inject, injectable as __injectable } from 'inversify';
import type { Constructor, Id } from '@/inversify.types';
import { container } from '@/inversify.container';

function isParameterDecorator(index: number): boolean {
    return index !== undefined ? typeof index === 'number' : false;
}

function injectParameterDecorator(
    constructor: Constructor,
    methodName: string,
    index: number,
    id: Id,
): void {
    return __inject(id)(constructor, methodName, index);
}

function injectPropertyDecorator(
    target: any,
    methodName: string,
    descriptor: PropertyDescriptor,
    id: Id,
) {
    if (descriptor) {
        // eslint-disable-next-line no-param-reassign
        descriptor.get = () => container.get(id);
    } else {
        Reflect.deleteProperty(target, methodName);
        Reflect.defineProperty(target, methodName, {
            get() {
                return container.get(id);
            },
            set(value) {
                return value;
            },
        });
    }

    return __inject(id)(target, methodName);
}

export function inject(id: Id) {
    return (target: any, methodName: string, indexOrDescriptor?: number | PropertyDescriptor) => {
        if (typeof indexOrDescriptor === 'number' && isParameterDecorator(indexOrDescriptor)) {
            return injectParameterDecorator(target, methodName, indexOrDescriptor, id);
        }

        return injectPropertyDecorator(target, methodName, indexOrDescriptor as PropertyDescriptor, id);
    };
}
