import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import TranslationsModel from './translations.model';

@Module
export default class TranslationsStore extends VuexModule {
    translations: TranslationsModel | null = null;
    loading: LoadingModel = new LoadingModel();
}
