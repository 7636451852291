import _ from 'lodash';

export const keysToCamel = (obj: any, ignore: string[] = []) => _
    .mapKeys(obj, (__, k) => {
        if (k.toUpperCase() === k) return k;
        return ignore.find(item => (k.search(item)) !== -1) ? k : _.camelCase(k);
    });

export const objectToCamel = (obj: any, ignore: string[] = []) => {
    const source = keysToCamel(obj, ignore);

    Object
        .entries(source)
        .forEach(([key, value]) => {
            if (typeof value === 'object') {
                source[key] = objectToCamel(value, ignore);
            }
        });

    return source;
};
