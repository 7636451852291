import { inject, injectable } from '@/inversify';
import { Route } from 'vue-router';
import { IMiddleware } from '@/router/interfaces/middleware.interface';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import { USER_LEVELS } from '@/modules/user/constants';

export const ViewAsMiddlewareS = Symbol.for('ViewAsMiddlewareS');
@injectable()
export default class ViewAsMiddleware implements IMiddleware {
    @inject(UserServiceS) private userService!: UserService;
    @inject(AuthServiceS) private authService!: AuthService;

    async canActivate(to: Route) {
        await this.authService.isLogin();

        const level = to.name?.split('.')[0] as USER_LEVELS;
        const hotelId = level === USER_LEVELS.HOTEL
            ? parseInt(to.params.hotelId, 10)
            : undefined;

        if (!hotelId) {
            return true;
        }

        await this.userService.setViewAs(USER_LEVELS.HOTEL, hotelId);

        return true;
    }
}
