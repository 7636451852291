import { inject, injectable } from '@/inversify';
import ScanDisabledProviders from '@/modules/common/modules/rates/constants/scan-disabled-providers.enum';
import Stateable from '@/modules/common/interfaces/stateable.interface';
import ProvidersStore from '@/modules/providers/store/providers.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import ProvidersApiService, { ProvidersApiServiceS } from '@/modules/providers/providers-api.service';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import CompsetsStore from '../compsets/store/compsets.store';
import DocumentFiltersStore from '../document-filters/store/document-filters.store';
import UserService, { UserServiceS } from '../user/user.service';
import { i18n } from '../translations/translations.service';

export const ProvidersServiceS = Symbol.for('ProvidersServiceS');

@injectable()
export default class ProvidersService implements Stateable {
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(ProvidersApiServiceS) private providersApiService!: ProvidersApiService;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(UserServiceS) private userService!: UserService;

    readonly storeState: ProvidersStore = this.storeFacade.getState('ProvidersStore');
    readonly compsetsStore: CompsetsStore = this.storeFacade.getState('CompsetsStore');
    readonly documentStore: DocumentFiltersStore = this.storeFacade.getState('DocumentFiltersStore');

    async loadFilter(): Promise<boolean> {
        this.storeState.providers = await this.providersApiService.getFilterProviders();
        return true;
    }

    get data() {
        this.helperService
            .dynamicLoading(this.storeState.loading, this.loadData.bind(this));

        return this.storeState.providerList;
    }

    get isLoading() {
        return this.storeState.loadingFilter.isLoading();
    }

    get ratesProviders() {
        this.helperService.dynamicLoading(
            this.storeState.loadingFilter,
            this.loadFilter.bind(this),
        );
        return this.storeState.providers;
    }

    get chainRatesProviders() {
        this.helperService
            .dynamicLoading(this.storeState.loadingFilter, this.loadChainProviders.bind(this));

        return this.storeState.providers;
    }

    get marketProviders() {
        const { compsetId } = this.documentStore.settings;
        const { compsets } = this.compsetsStore;

        if (!compsets) return [];
        const currentCompset = compsets.find(compset => compset.id === compsetId);

        if (!currentCompset) return [];

        return currentCompset.marketProviders;
    }

    get chainMarketsProviders() {
        this.helperService
            .dynamicLoading(
                this.storeState.loadingFilter,
                this.loadChainProviders.bind(this),
            );

        return this.storeState.marketProviders;
    }

    get promotionsProviders() {
        return ['booking', 'expedia', 'traveloka'];
    }

    async loadData(): Promise<boolean> {
        this.storeState.providerList = await this.providersApiService.getProviders();
        return true;
    }

    async loadChainProviders() {
        const { levelName, chainId } = this.userService;
        if (!chainId) return true;

        const dictionary = await this.providersApiService.getChainProviders(chainId, levelName);
        if (!dictionary) return true;

        this.storeState.marketProviders = dictionary.markets;
        this.storeState.providers = dictionary.rates;

        return true;
    }

    getDIName(provider: string) {
        if (!this.data) {
            return null;
        }

        const find = this.data.find((element: any) => (element.name === provider));
        if (!find) {
            return null;
        }

        return find.diName;
    }

    getMaxScore(provider: string) {
        if (!this.data) {
            return null;
        }

        const find = this.data.find((element: any) => (element.name === provider));
        if (!find) {
            return null;
        }

        return find.maxScore;
    }

    getProviderLabel(providerName: string) {
        if (!this.data) {
            return providerName;
        }

        const provider = this.data
            .find(({ name }) => name === providerName);

        if (!provider) {
            return '';
        }

        const translateableValues = ['all', 'cheapest'];
        const value = provider.label || provider.name || '';

        return translateableValues.includes(providerName)
            ? i18n.tc(value)
            : value;
    }

    toItemsList(providerList: string[], ignoreList: string[] = []) {
        return providerList
            .filter(provider => !ignoreList.includes(provider))
            .map(provider => ({
                value: provider,
                name: this.getProviderLabel(provider),
                disabled: false,
            }));
    }

    isDisabledProvider(provider: string | null) {
        const foundProviders = Object
            .values(ScanDisabledProviders)
            .some(value => (provider
                ? provider.includes(value)
                : false));

        return foundProviders;
    }
}
