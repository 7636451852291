import { inject, injectable } from '@/inversify';
import { plainToClass } from 'class-transformer';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import type Day from '@/modules/common/types/day.type';
import ResponseValidationException from '@/modules/common/modules/exception-handler/exceptions/response-validation.exception';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import RankingHistoryModel from './models/ranking-history.model';

export const RankingHistoryApiServiceS = Symbol.for('RankingHistoryApiServiceS');
@injectable()
export default class RankingHistoryApiService {
    @inject(ApiServiceS) private apiService!: ApiService;
    @inject(ValidatorServiceS) private validatorService!: ValidatorService;

    async getRankingHistory(compsetId: string) {
        const { data } = await this.apiService.get(`/market/compset/${compsetId}/history-customer-review`);

        if (!data) {
            return null;
        }

        const historyDocument = plainToClass(RankingHistoryModel, <RankingHistoryModel> data, { excludeExtraneousValues: true });

        // const error = await this.validatorService.validateResponse(priceHistoryDocument);
        // if (error) {
        //     throw new ResponseValidationException(error);
        // }

        return historyDocument;
    }
}
