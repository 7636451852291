import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import CarsRateAlertConfigurationModel from '../models/cars-rate-alert-configuration.model';

export interface CarRateAlertSettings {
    alertType: String[]
}

@Module
export default class CarsRateAlertStore extends VuexModule {
    document: CarsRateAlertConfigurationModel[] | null = null;
    loading: LoadingModel = new LoadingModel();
    saveRequestLoading = new LoadingModel();
    settings: CarRateAlertSettings = {
        alertType: [],
    };
}
