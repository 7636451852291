
import { Component, Vue, Prop } from 'vue-property-decorator';

export type Point = {
    x: number;
    y: number;
};

export type Rect = Point & {
    width: number;
    height: number;
};

export enum TOOLTIP_DIRECTION {
    LEFT_RIGHT = 'left-right',
    TOP_BOTTOM = 'top-bottom',
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
    BOTTOM = 'bottom',
}

/**
 * Component for any tooltip in CI
 * All content passed via slots
 * @prop {HTMLElement | DOMRect | Rect | Point | null} [pos=null] - position of hoverable element, shown only if pos !== null
 * @prop {HTMLElement | DOMRect | Rect | null} [parentPos=null] - position of bounding element, uses viewport in case parentPos === null
 * @prop {number} [width=300] - width of the tooltip an px (300px default)
 * @prop {number} [offset=10] - distance from hoverable element to tooltip
 * @prop {TOOLTIP_DIRECTION} [defaultDirrection=TOOLTIP_DIRECTION.LEFT_RIGHT] - from which side will appear
 *
 * !!! Don't Inject any modules! Have to be used as pure component!
 */
@Component
export default class CustomTooltipV2 extends Vue {
    /**
     * @pos position of an element, around which tooltip will be shown
     * Could be passed any html element,
     * BoundingClientRect of element,
     * or coordiantes of any position on a screen.
     * In case if passed as Point, rectangle will have
     * width and height of 5px.
     */
    @Prop({
        type: [HTMLElement, DOMRect, Object],
        default: null,
    })
    pos!: HTMLElement | DOMRect | Rect | Point | null;

    /**
     * Position of a parent element,
     * inside which tooltip will be shown
     * pos must be inside parentPos for correct work.
     * If not specified, viewport will be used instead
     */
    @Prop({
        type: [HTMLElement, DOMRect, Object],
        default: null,
    })
    parentPos!: HTMLElement | DOMRect | Rect | null;

    /**
     * Width of the tooltip in px
     */
    @Prop({
        type: Number,
        default: 300,
    })
    width!: number;

    /**
     * Distance from tooltip to hoverable element
     */
    @Prop({
        type: Number,
        default: 10,
    })
    offset!: number;

    /**
     * @defaultDirection defines from which side tooltip will appear
     * left-right - means that tooltip will be shown from the left side, if
     * hoverable element located from the right side of a parent and tooltip will be shown from
     * the right side, if hoverable element located from the left side of a paretn
     * left - tooltip will be always from the left side, until it out of parent element bounds.
     */
    @Prop({
        default: TOOLTIP_DIRECTION.LEFT_RIGHT,
    })
    defaultDirection!: TOOLTIP_DIRECTION;

    // Is used to keep last hoverableRect, to make transitions smooth after passing null to this.pos
    private lastHoverable: Rect | null = null;
    private isHoverableUpdated = false;
    private lastTooltipHeight = 0;
    tooltipPos: {
        top?: string;
        bottom?: string;
        left: string;
        width: string;
    } | null = null;

    mounted() {
        const { tooltip } = this.$refs;
        const { height } = (tooltip as HTMLElement).getBoundingClientRect();
        this.lastTooltipHeight = height;
        this.tooltipPos = this.getTooltipPos(height);
    }

    updated() {
        const { tooltip } = this.$refs;
        const { height } = (tooltip as HTMLElement).getBoundingClientRect();
        if (height !== this.lastTooltipHeight || this.isHoverableUpdated) {
            this.lastTooltipHeight = height;
            this.tooltipPos = this.getTooltipPos(height);
            this.isHoverableUpdated = false;
        }
    }

    get hoverableRect() {
        if (!this.pos) {
            return this.lastHoverable;
        }

        this.isHoverableUpdated = true;

        if (this.pos instanceof HTMLElement) {
            const {
                x, y, width, height,
            } = this.pos.getBoundingClientRect();
            this.lastHoverable = {
                x, y, width, height,
            };
        } else if (this.pos instanceof DOMRect) {
            const {
                x, y, width, height,
            } = this.pos;
            this.lastHoverable = {
                x, y, width, height,
            };
        } else if ('width' in this.pos) {
            this.lastHoverable = { ...this.pos };
        } else {
            this.lastHoverable = {
                ...this.pos,
                width: 5,
                height: 5,
            } as Rect;
        }

        return this.lastHoverable;
    }

    // Make Rect from any this.parentPos
    get parentBoundingRect() {
        if (this.parentPos === null) {
            return {
                x: 0,
                y: 0,
                height: window.innerHeight,
                width: window.innerWidth,
            };
        }

        if (this.parentPos instanceof HTMLElement) {
            const {
                x, y, width, height,
            } = this.parentPos.getBoundingClientRect();
            return {
                x, y, width, height,
            };
        }

        if (this.parentPos instanceof DOMRect) {
            const {
                x, y, width, height,
            } = this.parentPos;
            return {
                x, y, width, height,
            };
        }

        return { ...this.parentPos };
    }

    // true in case tooltip have to flip to another side (i.e. from left to right)
    get isFlipped() {
        if (!this.hoverableRect) {
            return false;
        }

        const arrowSize = 15;

        const markerCenterX = this.hoverableRect.x + this.hoverableRect.width / 2;
        const markerCenterY = this.hoverableRect.y + this.hoverableRect.height / 2;

        const boundingRectCenterX = this.parentBoundingRect.x + this.parentBoundingRect.width / 2;
        const boundingRectCenterY = this.parentBoundingRect.y + this.parentBoundingRect.height / 2;

        switch (this.defaultDirection) {
            case TOOLTIP_DIRECTION.LEFT_RIGHT: {
                return markerCenterX < boundingRectCenterX;
            }

            case TOOLTIP_DIRECTION.TOP_BOTTOM: {
                const bottom = this.parentBoundingRect.y + this.parentBoundingRect.height;

                if (this.parentBoundingRect.y > 0 && bottom < window.innerHeight) {
                    return markerCenterY < boundingRectCenterY;
                }

                if (this.parentBoundingRect.y <= 0 && bottom < window.innerHeight) {
                    return markerCenterY < (this.parentBoundingRect.height + this.parentBoundingRect.y) / 2;
                }

                if (this.parentBoundingRect.y > 0 && bottom >= window.innerHeight) {
                    return markerCenterY < this.parentBoundingRect.y + (window.innerHeight - this.parentBoundingRect.y) / 2;
                }

                return markerCenterY < window.innerHeight / 2;
            }

            case TOOLTIP_DIRECTION.LEFT: {
                const left = this.hoverableRect.x - this.offset - this.width - arrowSize / 2;
                return left < this.parentBoundingRect.x;
            }

            case TOOLTIP_DIRECTION.RIGHT: {
                const right = this.hoverableRect.x + this.hoverableRect.width + this.offset + this.width + arrowSize / 2;
                return right > this.parentBoundingRect.x + this.parentBoundingRect.width;
            }

            case TOOLTIP_DIRECTION.TOP: {
                // FIXME `tooltip` and `height` can be defined outide of switch
                const { tooltip } = this.$refs;
                const { height } = (tooltip as HTMLElement).getBoundingClientRect();

                const top = this.hoverableRect.y - this.offset - height - arrowSize / 2;
                return top < this.parentBoundingRect.y - window.scrollY || top < 0;
            }

            case TOOLTIP_DIRECTION.BOTTOM: {
                const { tooltip } = this.$refs;
                const { height } = (tooltip as HTMLElement).getBoundingClientRect();
                const bottom = this.hoverableRect.y + this.hoverableRect.height + this.offset + height + arrowSize / 2;
                return bottom > this.parentBoundingRect.y + this.parentBoundingRect.height - window.scrollY || bottom > window.innerHeight;
            }
            default: {
                return false;
            }
        }
    }

    getTooltipPos(tooltipHeight: number) {
        if (!this.hoverableRect) {
            return null;
        }

        const arrowSize = 15;

        const markerCenterX = this.hoverableRect.x + this.hoverableRect.width / 2;
        const markerCenterY = this.hoverableRect.y + this.hoverableRect.height / 2;

        if (!this.hoverableRect) {
            return null;
        }

        const tooltipCenteredTop = `${markerCenterY - tooltipHeight / 2}px`;
        let tooltipCenteredLeft = `${markerCenterX - this.width / 2}px`;

        switch (this.defaultDirection) {
            case TOOLTIP_DIRECTION.LEFT_RIGHT:
            case TOOLTIP_DIRECTION.LEFT: {
                let top = tooltipCenteredTop === null ? `${this.hoverableRect.y}px` : tooltipCenteredTop;

                if (parseInt(top, 10) < this.parentBoundingRect.y) {
                    top = `${this.parentBoundingRect.y}px`;
                } else if (parseInt(top, 10) + tooltipHeight > this.parentBoundingRect.y + this.parentBoundingRect.height) {
                    top = `${this.parentBoundingRect.y + this.parentBoundingRect.height - tooltipHeight}px`;
                }

                if (this.isFlipped) {
                    const left = `${this.hoverableRect.x + this.hoverableRect.width + arrowSize / 2}px`;
                    const width = `${this.width}px`;
                    return {
                        top,
                        left,
                        width,
                    };
                }

                const left = `${this.hoverableRect.x - this.width - arrowSize / 2}px`;
                const width = `${this.width}px`;
                return {
                    top,
                    left,
                    width,
                };
            }
            case TOOLTIP_DIRECTION.RIGHT: {
                // FIXME This code til `if (this.isFlipped)` is the same as in the case above
                let top = tooltipCenteredTop === null ? `${this.hoverableRect.y}px` : tooltipCenteredTop;

                if (parseInt(top, 10) < this.parentBoundingRect.y) {
                    top = `${this.parentBoundingRect.y}px`;
                } else if (parseInt(top, 10) + tooltipHeight > this.parentBoundingRect.y + this.parentBoundingRect.height) {
                    top = `${this.parentBoundingRect.y + this.parentBoundingRect.height - tooltipHeight}px`;
                }

                if (this.isFlipped) {
                    const left = `${this.hoverableRect.x - this.width - arrowSize / 2}px`;
                    const width = `${this.width}px`;
                    return {
                        top,
                        left,
                        width,
                    };
                }
                const left = `${this.hoverableRect.x + this.hoverableRect.width + arrowSize / 2}px`;
                const width = `${this.width}px`;
                return {
                    top,
                    left,
                    width,
                };
            }
            case TOOLTIP_DIRECTION.TOP_BOTTOM:
            case TOOLTIP_DIRECTION.TOP: {
                if (parseInt(tooltipCenteredLeft, 10) < this.parentBoundingRect.x) {
                    tooltipCenteredLeft = `${this.parentBoundingRect.x}px`;
                } else if (parseInt(tooltipCenteredLeft, 10) + this.width > this.parentBoundingRect.x + this.parentBoundingRect.width) {
                    tooltipCenteredLeft = `${this.parentBoundingRect.x + this.parentBoundingRect.width - this.width}px`;
                }

                if (this.isFlipped) {
                    const width = `${this.width}px`;
                    const top = `${this.hoverableRect.y + this.hoverableRect.height + arrowSize / 2}px`;

                    return {
                        top,
                        left: tooltipCenteredLeft,
                        width,
                    };
                }

                const width = `${this.width}px`;
                const bottom = `${window.innerHeight - this.hoverableRect.y + arrowSize / 2}px`;

                return {
                    bottom,
                    left: tooltipCenteredLeft,
                    width,
                };
            }
            case TOOLTIP_DIRECTION.BOTTOM: {
                // FIXME This code til `if (this.isFlipped)` is the same as in the case above
                if (parseInt(tooltipCenteredLeft, 10) < this.parentBoundingRect.x) {
                    tooltipCenteredLeft = `${this.parentBoundingRect.x}px`;
                } else if (parseInt(tooltipCenteredLeft, 10) + this.width > this.parentBoundingRect.x + this.parentBoundingRect.width) {
                    tooltipCenteredLeft = `${this.parentBoundingRect.x + this.parentBoundingRect.width - this.width}px`;
                }

                if (this.isFlipped) {
                    const width = `${this.width}px`;
                    const bottom = `${window.innerHeight - this.hoverableRect.y + arrowSize / 2}px`;

                    return {
                        bottom,
                        left: tooltipCenteredLeft,
                        width,
                    };
                }

                const width = `${this.width}px`;
                const top = `${this.hoverableRect.y + this.hoverableRect.height + arrowSize / 2}px`;

                return {
                    top,
                    left: tooltipCenteredLeft,
                    width,
                };
            }
            default:
                return null;
        }
    }

    get wrapperStyles() {
        if (!this.pos) {
            return { transform: 'translate(0, 0)' };
        }

        switch (this.defaultDirection) {
            case TOOLTIP_DIRECTION.LEFT_RIGHT:
            case TOOLTIP_DIRECTION.LEFT: {
                if (this.isFlipped) {
                    return { transform: `translate(${this.offset}px, 0)` };
                }
                return { transform: `translate(-${this.offset}px, 0)` };
            }
            case TOOLTIP_DIRECTION.RIGHT: {
                if (this.isFlipped) {
                    return { transform: `translate(-${this.offset}px, 0)` };
                }
                return { transform: `translate(${this.offset}px, 0)` };
            }
            case TOOLTIP_DIRECTION.TOP_BOTTOM:
            case TOOLTIP_DIRECTION.TOP: {
                if (this.isFlipped) {
                    return { transform: `translate(0, ${this.offset}px)` };
                }

                return { transform: `translate(0, -${this.offset}px)` };
            }
            case TOOLTIP_DIRECTION.BOTTOM: {
                if (this.isFlipped) {
                    return { transform: `translate(0, -${this.offset}px)` };
                }

                return { transform: `translate(0, ${this.offset}px)` };
            }
            default:
                return {};
        }
    }

    get arrowPos() {
        if (!this.hoverableRect) {
            return null;
        }

        const arrowSize = 15;

        const markerCenterX = this.hoverableRect.x + this.hoverableRect.width / 2;
        const markerCenterY = this.hoverableRect.y + this.hoverableRect.height / 2;
        const arrowCenterX = markerCenterX - arrowSize / 2;
        const arrowCenterY = markerCenterY - arrowSize / 2;

        switch (this.defaultDirection) {
            case TOOLTIP_DIRECTION.LEFT_RIGHT:
            case TOOLTIP_DIRECTION.LEFT: {
                let top = `${arrowCenterY}px`;

                if (parseInt(top, 10) < this.parentBoundingRect.y) {
                    top = `${this.parentBoundingRect.y + arrowSize / 2}px`;
                } else if (parseInt(top, 10) + arrowSize > this.parentBoundingRect.y + this.parentBoundingRect.height) {
                    top = `${this.parentBoundingRect.y + this.parentBoundingRect.height - arrowSize - arrowSize / 2}px`;
                }

                if (this.isFlipped) {
                    return {
                        top,
                        left: `${this.hoverableRect.x + this.hoverableRect.width}px`,
                        transform: 'rotate(45deg)',
                    };
                }
                return {
                    top,
                    left: `${this.hoverableRect.x - arrowSize}px`,
                    transform: 'rotate(-135deg)',
                };
            }
            case TOOLTIP_DIRECTION.RIGHT: {
                // FIXME This code til `if (this.isFlipped)` is the same as in the case above
                let top = `${arrowCenterY}px`;

                if (parseInt(top, 10) < this.parentBoundingRect.y) {
                    top = `${this.parentBoundingRect.y + arrowSize / 2}px`;
                } else if (parseInt(top, 10) + arrowSize > this.parentBoundingRect.y + this.parentBoundingRect.height) {
                    top = `${this.parentBoundingRect.y + this.parentBoundingRect.height - arrowSize - arrowSize / 2}px`;
                }

                if (this.isFlipped) {
                    return {
                        top,
                        left: `${this.hoverableRect.x - arrowSize}px`,
                        transform: 'rotate(-135deg)',
                    };
                }
                return {
                    top,
                    left: `${this.hoverableRect.x + this.hoverableRect.width}px`,
                    transform: 'rotate(45deg)',
                };
            }
            case TOOLTIP_DIRECTION.TOP_BOTTOM:
            case TOOLTIP_DIRECTION.TOP: {
                let left = `${arrowCenterX}px`;

                if (parseInt(left, 10) < this.parentBoundingRect.x) {
                    left = `${this.parentBoundingRect.x + arrowSize / 2}px`;
                } else if (parseInt(left, 10) + arrowSize > this.parentBoundingRect.x + this.parentBoundingRect.width) {
                    left = `${this.parentBoundingRect.x + this.parentBoundingRect.width - arrowSize - arrowSize / 2}px`;
                }

                if (this.isFlipped) {
                    const top = `${this.hoverableRect.y + this.hoverableRect.height}px`;

                    return {
                        top,
                        left,
                        transform: 'rotate(135deg)',
                    };
                }

                const bottom = `${window.innerHeight - this.hoverableRect.y}px`;

                return {
                    bottom,
                    left,
                    transform: 'rotate(-45deg)',
                };
            }
            case TOOLTIP_DIRECTION.BOTTOM: {
                // FIXME This code til `if (this.isFlipped)` is the same as in the case above
                let left = `${arrowCenterX}px`;

                if (parseInt(left, 10) < this.parentBoundingRect.x) {
                    left = `${this.parentBoundingRect.x}px`;
                } else if (parseInt(left, 10) + arrowSize > this.parentBoundingRect.x + this.parentBoundingRect.width + arrowSize / 2) {
                    left = `${this.parentBoundingRect.x + this.parentBoundingRect.width - arrowSize - arrowSize / 2}px`;
                }

                if (this.isFlipped) {
                    const bottom = `${window.innerHeight - this.hoverableRect.y}px`;

                    return {
                        bottom,
                        left,
                        transform: 'rotate(-45deg)',
                    };
                }

                const top = `${this.hoverableRect.y + this.hoverableRect.height}px`;

                return {
                    top,
                    left,
                    transform: 'rotate(135deg)',
                };
            }
            default:
                return null;
        }
    }
}
