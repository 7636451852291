import { container } from '@/inversify.container';
import { Route } from 'vue-router';
import RatesFiltersMiddleware, { RatesFiltersMiddlewareS } from '@/router/middlewares/rates-filters.middleware';
import use from '@/router/use';
import type Day from '@/modules/common/types/day.type';
import FeaturesGuard, { FeaturesGuardS } from '@/router/guards/features.guard';
import { CommonMenuRoutes } from '../common.routes';

const DayRatePopup = (prefix: string) => ({
    name: `${prefix}.day-rate`,
    path: 'day/:day',
    props: (r: Route) => ({ day: parseInt(r.params.day, 10) as Day }),
    component: () => import('@/modules/price-history/pages/hotel-rates-day.modal.vue'),
    meta: { modal: true },
});

export const DownloadExcelPopup = (prefix: string) => ({
    name: `${prefix}.download-excel`,
    path: 'download-excel',
    meta: { modal: true },
    component: () => import('@/modules/rates/pages/download-excel.page.vue'),
});

export function ScheduledReports(prefix: string) {
    return {
        name: `${prefix}.scheduled-reports`,
        path: 'scheduled-reports',
        component: () => import('@/modules/rates/pages/rates-report.modal.page.vue'),
        meta: { modal: true },
    };
}

export default function RatesHotelLevelRoutes(basePath: string, prefix: string) {
    const userType = basePath.split('/').shift();
    const CommonRoutes = (pref: string) => [
        DownloadExcelPopup(pref),
        ScheduledReports(pref),
        ...CommonMenuRoutes(pref),
    ];

    return {
        path: basePath,
        component: () => import('@/modules/common/pages/rates-hotel-transition.page.vue'),
        props: (r: Route) => ({
            hotelId: Number(r.params.hotelId),
            clusterId: Number(r.params.clusterId),
            userType,
        }),
        beforeEnter: use([
            container.get<RatesFiltersMiddleware>(RatesFiltersMiddlewareS),
            container.get<FeaturesGuard>(FeaturesGuardS),
        ]),
        children: [
            {
                name: prefix,
                path: '',
                component: () => import('@/modules/rates/pages/rates-calendar.page.vue'),
                children: [
                    ...CommonRoutes(prefix),
                    DayRatePopup(prefix),
                ],
            },

            {
                name: `${prefix}.table`,
                path: 'table',
                component: () => import('@/modules/rates/pages/rates-table.page.vue'),
                children: [
                    ...CommonRoutes(`${prefix}.table`),
                    DayRatePopup(`${prefix}.table`),
                ],
            },
            {
                name: `${prefix}.graph-compset`,
                path: 'graph-compset',
                component: () => import('@/modules/rates/pages/rates-graph-compset.page.vue'),
                children: [
                    ...CommonRoutes(`${prefix}.graph-compset`),
                    DayRatePopup(`${prefix}.graph-compset`),
                ],
            },
            {
                name: `${prefix}.graph-hotels`,
                path: 'graph-hotels',
                component: () => import('@/modules/rates/pages/rates-graph-hotels.page.vue'),
                children: [
                    ...CommonRoutes(`${prefix}.graph-hotels`),
                    DayRatePopup(`${prefix}.graph-hotels`),
                ],
            },
            {
                name: `${prefix}.day-rate`,
                path: 'day/:day',
                props: (r: Route) => ({ day: parseInt(r.params.day, 10) as Day }),
                component: () => import('@/modules/price-history/pages/hotel-rates-day.modal.vue'),
                meta: { modal: true },
            },
            {
                name: `${prefix}.analysis`, // NOTE: Default calendar mode
                path: 'analysis',
                component: () => import('@/modules/rates/pages/analysis/rates-analysis-calendar.page.vue'),
                children: [
                    {
                        name: `${prefix}.analysis.scheduled-reports`,
                        path: 'scheduled-reports',
                        component: () => import('@/modules/rates/pages/analysis/analysis-report.modal.page.vue'),
                        meta: { modal: true },
                    },
                    {
                        name: `${prefix}.analysis.day-rate`,
                        path: 'day/:day',
                        props: (r: Route) => ({ day: parseInt(r.params.day, 10) as Day }),
                        component: () => import('@/modules/price-history/pages/hotel-rates-day.modal.vue'),
                        meta: { modal: true },
                    },
                    DownloadExcelPopup(`${prefix}.analysis`),
                    ...CommonMenuRoutes(`${prefix}.analysis`),
                ],
            },
            {
                name: `${prefix}.analysis.table`,
                path: 'analysis/table',
                component: () => import('@/modules/rates/pages/analysis/rates-analysis-table.page.vue'),
                children: [
                    {
                        name: `${prefix}.analysis.table.scheduled-reports`,
                        path: 'scheduled-reports',
                        component: () => import('@/modules/rates/pages/analysis/analysis-report.modal.page.vue'),
                        meta: { modal: true },
                    },
                    {
                        name: `${prefix}.analysis.table.day-rate`,
                        path: 'day/:day',
                        props: (r: Route) => ({ day: parseInt(r.params.day, 10) as Day }),
                        component: () => import('@/modules/price-history/pages/hotel-rates-day.modal.vue'),
                        meta: { modal: true },
                    },
                    DownloadExcelPopup(`${prefix}.analysis.table`),
                    ...CommonMenuRoutes(`${prefix}.analysis.table`),
                ],
            },
        ],
    };
}
