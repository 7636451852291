import { Expose, plainToClass, Transform } from 'class-transformer';
import { IsString, IsInt, IsNumber } from 'class-validator';
import CompsetModel from '@/modules/compsets/models/compset.model';

export default class ClusterHotelsModel {
    @Expose()
    @Transform((_, plain) => plain.hotel_name)
    @IsString()
    hotelName!: string;

    @Expose()
    @Transform((_, plain) => plain.fornovaId || plain.fornova_id)
    @IsInt()
    hotelId!: number;

    @Expose()
    @IsNumber()
    @Transform((_, plain) => Math.round(Number(plain.total_score)))
    totalScore!: number;

    @Expose()
    @IsNumber()
    @Transform((_, plain) => Math.round(Number(plain.rate_score)))
    ratesScore!: number;

    @Expose()
    @IsNumber()
    @Transform((_, plain) => plain.diff_score)
    newTotalScore!: number;

    @Expose()
    @Transform((_, plain) => plainToClass(CompsetModel, <CompsetModel[]>plain.compsets, { excludeExtraneousValues: true }))
    compsets!: CompsetModel[];

    @Expose()
    @Transform((_, plain) => {
        const doc = plain.compsetMain || plain.document;
        if (!doc || !plain.compsets) return null;

        const compset = plain.compsets
            .find((comp: any) => comp._id === doc.compset_id);

        if (!compset) return null;

        return plainToClass(CompsetModel, compset, { excludeExtraneousValues: true });
    })
    shownCompset!: CompsetModel;

    @Expose()
    @Transform((_, plain) => plain.hotelsNames)
    hotelNames!: { [hotelId: number]: string };

    documentLoaded: boolean = false;
}
