import { inject, injectable } from '@/inversify';
import PromotionsApiService, { PromotionsApiServiceS } from './promotions-api.service';
import PromotionsStore from './promotions.store';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import DocumentFiltersService, { DocumentFiltersServiceS } from '../document-filters/document-filters.service';
import PromotionsService, { PromotionsServiceS } from './promotions.service';

export const PromotionsHistoryServiceS = Symbol.for('PromotionsHistoryServiceS') as unknown as string;

@injectable()
export default class PromotionsHistoryService {
    @inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @inject(PromotionsApiServiceS)
    private promotionsApiService!: PromotionsApiService;

    @inject(PromotionsServiceS)
    private promotionsService!: PromotionsService;

    @inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    private storeState: PromotionsStore = this.storeFacade.getState('PromotionsStore');

    public async loadTrendData(day: number) {
        const { data } = this.promotionsService;

        if (!data) return;

        const historyDoc = await this.promotionsApiService
            .getTrendData(data.documentId, day);

        this.storeState.historyDoc = historyDoc;
    }

    public getProgramData(
        currentDay: number,
        daysAgo: number,
        provider: string,
        hotelId: number,
        program: string,
    ) {
        const date = this.getDateByDaysAgo(currentDay, daysAgo);
        const trendData = this.getTrendData(date);

        if (!trendData) return null;

        return (trendData[provider]
            && trendData[provider]![hotelId]
            && trendData[provider]![hotelId]![program]!) || null;
    }

    private getTrendData(date: string) {
        const { historyDoc } = this.storeState;

        if (!historyDoc) {
            return null;
        }

        return historyDoc.trendData[date];
    }

    private getDateByDaysAgo(currentDay: number, daysAgo: number) {
        const lastScan = this.promotionsService.getLastScanDate(currentDay);

        if (!lastScan) return '';

        const daysAgoDate = new Date(lastScan);
        daysAgoDate.setDate(daysAgoDate.getDate() - daysAgo);

        return daysAgoDate
            .toISOString()
            .split('T')
            .shift()!
            .split('-')
            .reverse()
            .join('-');
    }

    public getLastScanDay(currentDay: number, provider: string, program: string) {
        const { data } = this.promotionsService;
        const { days } = this.documentFiltersService;
        if (!data) return 0;

        const { hotels } = data;
        let daysAgo = 0;

        const validProgramData = (hotelId: number) => {
            const programData = this.getProgramData(
                currentDay,
                daysAgo,
                provider,
                hotelId,
                program,
            );

            return !!programData && programData.status;
        };

        for (daysAgo = 0; daysAgo < days.length; daysAgo++) {
            const isHaveProgramData = !!hotels.find(validProgramData);

            if (isHaveProgramData) break;
        }

        return Math.abs(currentDay - daysAgo) - Math.min(0, currentDay - daysAgo);
    }
}
