
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class NotificationWrapper extends Vue {
    handleClose() {
        this.$emit('close');
    }

    mounted() {
        (this.$el as HTMLDivElement).style
            .setProperty('--base-height', `${this.$el.scrollHeight}px`);
    }
}
