import {
    ValidatorConstraint,
    ValidatorConstraintInterface,
    ValidationArguments,
    validate,
} from 'class-validator';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import { inject } from '@/inversify';
import CarsDocumentItemModel from '../cars-document-item.model';

interface ICheckinDates {
    [day: number]: {
        [company: string]: {
            [carClass: string]: CarsDocumentItemModel[]
        }
    } | null
}

@ValidatorConstraint({ name: 'CheckinDates', async: true })
export default class CarsCheckinDatesValidator implements ValidatorConstraintInterface {
    @inject(ValidatorServiceS) private validatorService!: ValidatorService;

    private errorMessage = 'Cars checkinDates not valid';

    private async validateDocumentItem(documentItems: CarsDocumentItemModel[][]) {
        let documentItemErrors: string[] = [];

        // Validate all CarsDocumentItems and collect erros
        for (let i = 0; i < documentItems.length; i += 1) {
            // eslint-disable-next-line no-await-in-loop
            documentItemErrors = documentItemErrors.concat(await Promise.all(documentItems[i].map(item => (
                this.validatorService.validateResponse(item)
            )).filter(errorItem => errorItem)) as string[]);
        }

        return Array.from(new Set(documentItemErrors)).join('; ');
    }

    async validate(checkinDates: ICheckinDates | null, args: ValidationArguments) {
        let isValid = true;

        let carsDocumentItems: CarsDocumentItemModel[][] = [];

        if (!checkinDates) {
            return isValid;
        }

        Object.keys(checkinDates).forEach(day => {
            if (Number.isNaN(Number(day))) {
                this.errorMessage += ', day is not a number';
                isValid = false;
            }

            if (checkinDates[Number(day)]) {
                Object.keys(checkinDates[Number(day)]!).forEach(company => {
                    if (!company) {
                        this.errorMessage += ', company name is required';
                        isValid = false;
                    }
                    carsDocumentItems = carsDocumentItems.concat(
                        Object.entries(checkinDates[Number(day)]![company]).map(([carClass, carItemDocument]) => {
                            if (!carClass) {
                                this.errorMessage += ', carClass is required';
                                isValid = false;
                            }
                            return carItemDocument;
                        }),
                    );
                });
            }
        });

        if (!carsDocumentItems.length) {
            return isValid;
        }

        const errors = await this.validateDocumentItem(carsDocumentItems);

        if (!errors) {
            return isValid;
        }

        isValid = false;
        this.errorMessage += `, ${errors}`;

        return isValid;
    }

    defaultMessage(args: ValidationArguments) {
        return this.errorMessage;
    }
}
