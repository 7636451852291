import { inject, injectable } from '@/inversify';
import LoadingModel from '@/modules/common/models/loading.model';
import getSymbolFromCurrency from 'currency-symbol-map';
import StoreFacade, { StoreFacadeS } from './store-facade';

export const HelperServiceS = Symbol.for('HelperServiceS');
@injectable()
export default class HelperService {
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;

    dynamicLoading(loading: LoadingModel, loadFunc: () => Promise<boolean>) {
        if (loading.startDate !== null || loading.finishDate !== null) {
            return;
        }

        loading.start();

        loadFunc()
            .then(status => {
                if (!status) return;
                loading.finish();
            })
            .catch(err => {
                loading.finish();
                this.storeFacade.dispatchException(err);
            });
    }

    currencySymbol(currency: string | null): string | null {
        switch (currency) {
            case 'S$':
            case 'SGD':
                return 'S$';
            case 'HK':
            case 'HK$':
            case 'HKD':
                return 'HK$';
            case 'CA':
            case 'CAD':
                return 'c$';
            case 'CLP':
                return 'CLP';
            case 'CNY':
                return 'CNY';
            case 'JPY':
                return 'JPY';
            case 'MX':
            case 'MXN':
                return 'MXN$';
            case 'AUD':
            case 'AU':
                return 'AUD';
            case 'AED':
                return 'AED';
            case 'SAR':
                return 'SAR';
            default:
                return getSymbolFromCurrency(currency) || currency;
        }
    }

    providerLogo(providerName: string) {
        const lowerCaseName = providerName.trim().toLowerCase();

        const images = require.context('@/modules/common/assets/', true, /\.png$|\.jpg$|\.svg$/);
        switch (lowerCaseName) {
            case 'booking':
            case 'booking_mobile_app':
            case 'booking_basic':
            case 'booking_basic_mobile_app':
            case 'booking_cug':
                return images('./provider-logos/booking-logo.svg');
            case 'expedia':
            case 'expedia_mobile_app':
                return images('./provider-logos/expedia-logo.svg');
            case 'google':
                return images('./provider-logos/google-logo.svg');
            case 'agoda':
                return images('./provider-logos/agoda-logo.svg');
            case 'tripadvisor':
                return images('./provider-logos/tripadvisor-logo.svg');
            case 'ctrip':
            case 'ctrip_mobile_web':
                return images('./provider-logos/ctrip-logo.svg');
            case 'trip':
                return images('./provider-logos/trip-logo.svg');
            case 'hotels':
                return images('./provider-logos/hotels.com-logo.svg');
            case 'hoteltonight':
                return images('./provider-logos/hoteltonight-logo.svg');
            case 'hrs':
                return images('./provider-logos/hrs-logo.svg');
            case 'makemytrip':
                return images('./provider-logos/makemytrip-logo.svg');
            case 'priceline':
                return images('./provider-logos/priceline-logo.svg');
            case 'qantas':
                return images('./provider-logos/qantas-logo.svg');
            case 'rakuten':
                return images('./provider-logos/rakuten-logo.svg');
            case 'tiket':
                return images('./provider-logos/tiket-logo.svg');
            case 'traveloka':
                return images('./provider-logos/traveloka-logo.svg');
            case 'bcom':
            case 'brand_com':
            case 'brand':
                return images('./provider-logos/brand-logo.svg');
            case 'issta':
                return images('./provider-logos/issta-logo.svg');
            case 'eshet':
                return images('./provider-logos/eshet-logo.svg');
            default:
                return images('./provider-logos/logo-placeholder.svg');
        }
    }

    fill(length: number = 1, symbol: string = '&nbsp;') {
        return symbol.repeat(length);
    }
}
