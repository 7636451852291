/* eslint-disable camelcase */
import { Expose, plainToClass, Transform } from 'class-transformer';
import PRICE_TYPE, { PRICE_TYPE_SHORT } from '@/modules/document-filters/constants/price-type.constant';
import { DayRateInsightData } from '../types';

interface BEHotel {
    missingTypes: string[];
    newRate: string;
    rooms: {
        [roomTypeId: number]: {
            price: { shown_price: number };
            price_type: string;
            room_name: string;
            meal_type_id: string;
            currency: string;
        }[]
    }
}

export default class InsightDayRateModel {
    @Expose()
    @Transform((_, plain) => {
        const dayRateData = {} as Record<string, DayRateInsightData>;
        Object.entries<BEHotel>(plain.todayData).forEach(([fId, hotel]) => {
            if (!hotel.rooms) {
                return;
            }

            const room = Object.keys(hotel.rooms).length ? hotel.rooms[parseInt(Object.keys(hotel.rooms)[0], 10)][0] : null;
            const price = room?.price.shown_price;

            dayRateData[fId] = {
                name: plain.hotelNames[fId] || fId,
                price: Number.isFinite(price) ? price as number : null,
                currency: room?.currency || null,
                filters: {
                    roomName: room?.room_name || null,
                    mealTypeId: Number.isFinite(room?.meal_type_id) ? room!.meal_type_id : null,
                    priceType: room?.price_type || null,
                    newPriceType: hotel.newRate ? PRICE_TYPE_SHORT[hotel.newRate as PRICE_TYPE] : null,
                    missingPriceTypes: hotel.missingTypes?.map(t => PRICE_TYPE_SHORT[t as PRICE_TYPE]).join(', ') || null,
                },
            };
        });

        return dayRateData;
    })
    data!: Record<string, DayRateInsightData>;

    @Expose()
    filters!: Record<string, string | number>;

    @Expose()
    @Transform((_, plain) => plain.alertData?.message || '')
    message!: string;
}
