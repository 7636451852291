import { Expose, Transform } from 'class-transformer';
import VueI18n from 'vue-i18n';
// @ts-ignore
import enLocale from 'element-ui/lib/locale/lang/en';
// @ts-ignore
import cnLocale from 'element-ui/lib/locale/lang/zh-CN';
// @ts-ignore
import jaLocale from 'element-ui/lib/locale/lang/ja';

export default class TranslationsModel {
    @Expose()
    @Transform((_, plain) => ({ ...plain.en?.translation, ...enLocale } || { ...enLocale }))
    en!: VueI18n.LocaleMessageObject;

    @Expose()
    @Transform((_, plain) => ({ ...plain.zh?.translation, ...cnLocale } || { ...cnLocale }))
    cn!: VueI18n.LocaleMessageObject;

    // [TODO] enable as translations will be verified
    // @Expose()
    // @Transform((_, plain) => ({ ...plain.ja?.translation, ...jaLocale } || { ...jaLocale }))
    // ja!: VueI18n.LocaleMessageObject;
}
