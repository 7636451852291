import { inject, injectable } from '@/inversify';
import { Route } from 'vue-router';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import { IMiddleware } from '@/router/interfaces/middleware.interface';
import StorageService, { StorageServiceS } from '@/modules/common/services/storage.service';

export const AuthMiddlewareS = Symbol.for('AuthMiddlewareS');
@injectable()
export default class AuthMiddleware implements IMiddleware {
    @inject(AuthServiceS) private authService!: AuthService;
    @inject(StorageServiceS) private storageService!: StorageService;

    async canActivate(to: Route) {
        if (!await this.authService.isLogin()) {
            if (to.fullPath !== '/forbidden') {
                this.storageService.redirrectUrl = to.fullPath;
            }

            window.location.href = this.authService.loginUrl;
            return true;
        }

        const { redirrectUrl } = this.storageService;

        if (redirrectUrl) {
            this.storageService.clearRedirrectUrl();

            if (redirrectUrl.startsWith('http')) {
                window.location.href = redirrectUrl;
                return true;
            }

            return redirrectUrl;
        }

        return true;
    }
}
