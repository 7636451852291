export const calculateDiff = (a: number, b: number, toFixed = 0) => {
    const isArgumentsInvalid = Number.isNaN(a)
        || Number.isNaN(b)
        || a === null
        || b === null
        || b === 0;

    if (isArgumentsInvalid) return null;
    return +((a - b) / b * 100).toFixed(toFixed);
};
