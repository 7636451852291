import { Module, VuexModule } from 'vuex-module-decorators';
import CAR_PRICE_TYPE from '@/modules/cars/constants/car-price-type.constant';
import {
    TRANSMISSION_ANY, MILEAGE_ANY, DOORS_ANY, PAYMENT_TERMS_ANY, CAR_CLASS_ANY, BRAND_AS_BROKER_ANY, BROKER_TO_BRAND,
} from '@/modules/cars/constants/car-filter-types.constant';
import CarsSettingsModel from '@/modules/cars/models/cars-settings.model';
import CarsDocumentModel from '@/modules/cars/models/cars-document.model';
import LoadingModel from '@/modules/common/models/loading.model';
import FleetAvailabilityDocumentModel from '@/modules/cars/models/fleet-availability-document.model';
import { getCachedAssessmentSettings } from '@/modules/cars/utils/assessment-settings-cache.util';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';
import VEHICLE_TYPE from '../constants/vehicle-type.constant';
import VEHICLE_TYPE_FILTER from '../constants/vehicle-type-filter.constant';
import CarsAnalysisData from '../models/cars-analysis.model';

@Module
export default class CarsStore extends VuexModule {
    document: CarsDocumentModel | null = null;
    fleetAvailability: FleetAvailabilityDocumentModel | null = null;
    showPriceDiff: boolean = false;
    analysis: CarsAnalysisData = new CarsAnalysisData();

    settings: CarsSettingsModel = {
        assessmentSettings: getCachedAssessmentSettings() || {
            [CAR_PRICE_TYPE.LOWEST]: [0, 0.05],
            [CAR_PRICE_TYPE.MEDIAN]: [0.01, 0.05],
            [CAR_PRICE_TYPE.HIGHEST]: [-0.05, 0],
        },
        priceShown: PRICE_SHOWN.TOTAL,
        pickUpCityCode: null,
        dataSource: null,
        lor: null,
        pos: null,
        priceType: CAR_PRICE_TYPE.MEDIAN,
        carClasses: [],
        carFuelTypes: [],
        paymentTerms: PAYMENT_TERMS_ANY,
        doors: DOORS_ANY,
        transmission: TRANSMISSION_ANY,
        mileage: MILEAGE_ANY,
        country: null,
        competitors: null,
        currentDocumentCompetitors: null,
        currentDocumentCarClasses: null,
        dataSourceCompetitors: {},
        carClassesChange: false,
        carFuelTypeChange: false,
        vehicleType: {
            type: VEHICLE_TYPE.VAN,
            filter: VEHICLE_TYPE_FILTER.WITHOUT,
        },
        chain: null,
        cluster: null,
        isAvgPrice: false,
        allBrands: [],
        currentDataSourceMode: BROKER_TO_BRAND,
        currentBrandAsBroker: BRAND_AS_BROKER_ANY,
        brokersCompetitors: [],
        allowedLORs: {},
        carsProviders: [],
        isLoadEventByPOS: false,
    };

    loading: LoadingModel = new LoadingModel();
}
