export enum COMPARE_KEY {
    DIFF_DAYS = 'diff_days',
}

export const COMPARE_NAMES: {
    [key in COMPARE_KEY]: string;
} = {
    [COMPARE_KEY.DIFF_DAYS]: 'titles.pastPeriod',
};

export const COMPARE_VALUES: {
    [key in COMPARE_KEY]: {
        name: string;
        value: string | number;
    }[];
} = {
    [COMPARE_KEY.DIFF_DAYS]: [{
        name: 'titles.yesterday',
        value: '1',
    }],
};
