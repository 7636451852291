import {
    ValidatorConstraint,
    ValidatorConstraintInterface,
    ValidationArguments,
    validate,
} from 'class-validator';
import RatesDocumentItemModel from '@/modules/rates/models/rates-document-item.model';
import ValidatorService, { ValidatorServiceS } from '@/modules/common/services/validator.service';
import { inject } from '@/inversify';

interface ICheckinDates {
    [day: number]: {
        [hotelId: number]: {
            rooms: {
                [roomTypeId: number]: RatesDocumentItemModel[],
            }
        }
    } | null
}

@ValidatorConstraint({ name: 'CheckinDates', async: true })
export default class RatesCheckinDatesValidator implements ValidatorConstraintInterface {
    @inject(ValidatorServiceS) private validatorService!: ValidatorService;

    private errorMessage = 'Rates checkinDates not valid';

    private async validateDocumentItem(documentItems: RatesDocumentItemModel[][]) {
        let documentItemErrors: string[] = [];

        // Validate all RatesDocumentItems and collect erros
        for (let i = 0; i < documentItems.length; i += 1) {
            // eslint-disable-next-line no-await-in-loop
            documentItemErrors = documentItemErrors.concat(await Promise.all(documentItems[i].map(item => (
                this.validatorService.validateResponse(item)
            )).filter(errorItem => errorItem)) as string[]);
        }

        return Array.from(new Set(documentItemErrors)).join('; ');
    }

    async validate(checkinDates: ICheckinDates | null, args: ValidationArguments) {
        let isValid = true;

        let ratesDocumentItems: RatesDocumentItemModel[][] = [];

        if (!checkinDates) {
            return isValid;
        }

        Object.keys(checkinDates).forEach(day => {
            if (Number.isNaN(Number(day))) {
                this.errorMessage += ', day is not a number';
                isValid = false;
            }
            Object.keys(checkinDates[Number(day)]!).forEach(hotelId => {
                if (Number.isNaN(Number(hotelId))) {
                    this.errorMessage += ', hotelId is not a number';
                    isValid = false;
                }
                ratesDocumentItems = Object.entries(checkinDates[Number(day)]![Number(hotelId)].rooms).map(([roomTypeId, item]) => {
                    if (Number.isNaN(Number(roomTypeId))) {
                        this.errorMessage += ', roomTypeId is not a number';
                        isValid = false;
                    }

                    return item;
                });
            });
        });

        if (!ratesDocumentItems.length) {
            return isValid;
        }

        const errors = await this.validateDocumentItem(ratesDocumentItems);

        if (!errors) {
            return isValid;
        }

        isValid = false;
        this.errorMessage += `, ${errors}`;

        return isValid;
    }

    defaultMessage(args: ValidationArguments) {
        return this.errorMessage;
    }
}
