import { Expose, Transform } from 'class-transformer';

export default class RoomsRMSStateModel {
    @Expose()
    @Transform((_, plain) => plain.eliminated_rooms)
    avoidedRoomTypes!: number[];

    @Expose()
    @Transform((_, plain) => plain.fornovaId)
    hotelId!: number;
}
