enum PAGES {
    RATES = 'rates',
    MARKETS = 'markets',
    RANKING = 'ranking',
    DILITE = 'di-lite',
    INSIGHTS = 'insights',
    PROMOTION_DETECTION = 'promotions',
    DEEP_ANALYSIS = 'compset-benchmark',
}

export default PAGES;
