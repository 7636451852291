import LocationAvailabilityDocumentModel from '@/modules/cars/modules/location-availability/models/location-availability-document.model';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import { inject, injectable } from '@/inversify';
import { COUNTRIES_ANY } from '@/modules/cars/constants/car-filter-types.constant';
import LocationAvailabilityStore from '@/modules/cars/modules/location-availability/store/location-availability.store';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import _ from 'lodash';

export const LocationAvailabilityFiltersServiceS = Symbol.for('LocationAvailabilityFiltersServiceS');
@injectable()
export default class LocationAvailabilityFiltersService {
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    isWatcherAdded: boolean = false;
    readonly storeState: LocationAvailabilityStore = this.storeFacade.getState('LocationAvailabilityStore');

    constructor() {
        this.storeFacade.watch(() => this.storeState.loading.isInitialized, () => {
            this.init();
        });
    }

    init() {
        this.storeFacade.watch(() => [
            this.carFiltersSettings,
        ], (newVal, oldVal) => {
            if (!_.isEqual(newVal, oldVal)) {
                this.initFiltersValues.call(this, true);
            }
        });

        this.storeFacade.watch(() => [ // prevent incorrect selected values from other pages
            this.storeState.settings.pos,
        ], () => {
            this.initPos();
            this.updateLocations(false);
        });
        const shouldAddWatcher = (this.carFiltersSettings.locations && !!this.carFiltersSettings.locations.length) || false;
        this.initFiltersValues(shouldAddWatcher);
    }

    initFiltersValues(addWatcher?: boolean) {
        const currentCountry = this.storeState.settings.country;
        if (!currentCountry) {
            [this.storeState.settings.country] = this.countryNames;
        }
        if (addWatcher && !this.isWatcherAdded) {
            this.isWatcherAdded = true;
            this.storeFacade.watch(() => this.storeState.settings.country, () => {
                this.initPos();
                this.updateLocations(true);
            });
            if (this.storeState.settings.country) {
                this.updateLocations(false);
            }
        }

        const { lor } = this.carFiltersSettings;

        const { availablePos } = this;

        if (availablePos) {
            [this.storeState.settings.pos] = availablePos;
        }

        if (lor) {
            [this.storeState.settings.lor] = lor;
        }
    }

    initPos() {
        const { availablePos } = this;
        if (!this.storeState.settings.pos || !availablePos.find(pos => pos === this.storeState.settings.pos)) {
            const [defaultPos] = availablePos;
            this.storeState.settings.pos = defaultPos;
        }
    }

    getLocationsBySelectedCountry(country: string, query?: string | null) {
        const { countries, allowedPosCodeLocations } = this.carFiltersSettings;
        let { locations } = this.carFiltersSettings;

        if (!locations || !countries || !allowedPosCodeLocations) {
            return [];
        }
        let allowedPosCodesLocationIds: string[] = [];
        const modifiedCountries = { ...countries };
        modifiedCountries[COUNTRIES_ANY] = Object.values(countries).flat();

        Object.values(allowedPosCodeLocations).forEach(posLocations => {
            allowedPosCodesLocationIds = allowedPosCodesLocationIds.concat(Object.keys(posLocations));
        });

        allowedPosCodesLocationIds = Array.from(new Set(allowedPosCodesLocationIds));
        const availableLocationsSet = new Set(_.intersection(allowedPosCodesLocationIds, modifiedCountries[country]));

        locations = locations.filter(location => availableLocationsSet.has(location.locationId));

        if (query) {
            const searchPattern = new RegExp(query.toLowerCase());
            locations = locations.filter(location => searchPattern.test(location.locationName.toLowerCase()));
        }

        return locations.sort((a, b) => {
            if (a.locationName > b.locationName) {
                return 1;
            }
            return -1;
        });
    }

    updateLocations(isByUserSelection?: boolean) {
        const { country, pos } = this.storeState.settings;
        const { allowedPosCodeLocations } = this.carsFiltersService.settings;
        let locations = country ? this.getLocationsBySelectedCountry(country) : [];
        locations = locations.filter(loc => _.get(allowedPosCodeLocations, `${pos}.${loc.locationId}`));
        if (isByUserSelection) {
            this.carsSharedService.savePickUpCities(locations.map(loc => loc.locationId), 'location-availability');
        } else {
            this.storeState.settings.pickUpLocationIds = locations.map(loc => loc.locationId);
        }
        this.currentPickupLocations = locations.map(loc => ({ name: loc.locationName, value: loc.locationId }));
    }

    get carFiltersSettings() {
        return this.carsFiltersService.settings;
    }

    get countryNames(): string[] {
        const { countries } = this.carFiltersSettings;

        if (!countries) {
            return [];
        }

        const countryNames = new Set(Object.keys(countries));

        return Array.from(countryNames)
            .filter(country => this.getLocationsBySelectedCountry(country).length > 0);
    }

    get locationQuery() {
        return this.storeState.locationQuery;
    }

    set locationQuery(value) {
        this.storeState.locationQuery = value;
    }

    get locations() {
        const { country } = this.storeState.settings;
        return country ? this.getLocationsBySelectedCountry(country, this.locationQuery) : [];
    }

    get providersList() {
        const { documents } = this.storeState;
        const { allowedBrands, dataSources } = this.carsFiltersService.settings;
        let providers: string[] = [];
        if (documents) {
            Object.values(documents).forEach((loc: LocationAvailabilityDocumentModel) => {
                if (loc.pickupDates) {
                    Object.values(loc.pickupDates).forEach(pickupDate => {
                        providers = providers.concat(Object.keys(pickupDate));
                    });
                }
            });
            providers = Array.from(new Set(providers));
        }
        const availableProviders = _.union(allowedBrands, dataSources);
        providers = _.intersection(availableProviders, providers);
        this.storeState.settings.providers = providers;
        return providers;
    }

    get availablePos() {
        const { availability } = this.carsFiltersService.settings;
        const { locations } = this;
        if (!availability || !locations) {
            return [];
        }
        const pickUpCityCodes = locations.map(location => location.locationId);
        let availablePoses: string[] = [];

        availability
            .filter(doc => pickUpCityCodes.includes(doc.id))
            .forEach(doc => {
                const locationPoses = Object.values(doc.path).map(val => Object.keys(val)).flat();
                availablePoses = availablePoses.concat(locationPoses);
            });

        return Array.from(new Set(availablePoses));
    }

    get availableLors() {
        const { documents } = this.storeState;
        let lor: number[] = [];
        if (documents) {
            Object.values(documents).forEach((loc: LocationAvailabilityDocumentModel) => {
                if (loc.pickupDates) {
                    Object.values(loc.pickupDates).forEach(pickupDate => {
                        Object.values(pickupDate).forEach(provider => {
                            lor = lor.concat(
                                Object.keys(provider).map(item => Number(item)).filter(item => !_.isNaN(item)),
                            );
                        });
                    });
                }
            });
            lor = Array.from(new Set(lor)).sort((a, b) => a - b);
            if (lor.length) {
                this.storeState.settings.lor = Number(lor[0]);
            }
        }
        return lor;
    }

    // Filter values

    get isClosedOnly() {
        return this.storeState.isClosedOnly;
    }
    set isClosedOnly(value) {
        this.storeState.isClosedOnly = value;
    }

    get currentPickupLocations() {
        return this.storeState.currentPickupLocations;
    }

    set currentPickupLocations(value) {
        this.storeState.currentPickupLocations = value;
    }

    get pickUpLocations() {
        return [...(this.currentPickupLocations || [])]
            .sort((a, b) => {
                if (a.name > b.name) {
                    return 1;
                }
                return -1;
            });
    }

    set pickUpLocations(locations: {name: string, value: string}[] | null) {
        this.storeState.settings.pickUpLocationIds = locations ? locations.map(loc => loc.value) : [];
        this.currentPickupLocations = locations && locations.length ? locations : [];
    }

    get lor() {
        return this.storeState.settings.lor;
    }
    set lor(lor: number | null) {
        this.storeState.settings.lor = lor;
    }

    get pos() {
        return this.storeState.settings.pos;
    }
    set pos(pos: string | null) {
        this.storeState.settings.pos = pos;
    }

    get country() {
        return this.storeState.settings.country;
    }
    set country(country: string | null) {
        this.storeState.settings.country = country;
    }

    get providers() {
        return this.storeState.settings.providers;
    }
    set providers(provider: string[] | null) {
        this.storeState.settings.providers = provider;
    }
}
