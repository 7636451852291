/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */

import { Expose, Transform } from 'class-transformer';

export interface PromotionTrendData {
    [date: string]: {
        [provider: string]: {
            [hotelId: string]: {
                [program: string]: {
                    status: boolean;
                    message?: string;

                    /**
                     * Percent value from 0 to 100
                     */
                    percentage?: number;
                    updateDate?: string;
                } | undefined;
            } | undefined;
        } | undefined;
    } | undefined;
}

export default class PromotionHistoryDocumentModel {
    @Expose()
    @Transform((_, plain) => plain.doc_id)
    public documentId!: number;

    @Expose()
    @Transform((_, plain) => plain.day)
    public day!: number;

    @Expose()
    @Transform((_, plain) => {
        Object.values(plain.trend_data).forEach((providerData: any) => {
            Object.values(providerData).forEach((hotelData: any) => {
                Object.values(hotelData).forEach((programData: any) => {
                    Object.values(programData).forEach((program: any) => {
                        if (program.update_date) {
                            // NOTE: Remove timezone offset since time is set by BE's timezone
                            program.updateDate = `${program.update_date.split('T')[0]}T00:00:00.000Z`;
                        }

                        delete program.update_date;
                    });
                });
            });
        });

        return plain.trend_data;
    })
    public trendData!: PromotionTrendData;
}
