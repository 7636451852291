import { inject, injectable } from '@/inversify';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import NotifyService, { NotifyServiceS } from '@/modules/common/services/notify.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import CarsTableauStore from '@/modules/cars/modules/tableau/store/cars-tableau.store';

export const CarsTableauServiceS = Symbol.for('CarsTableauServiceS');
@injectable()
export default class CarsTableauService {
    @inject(ApiServiceS) private apiService!: ApiService;
    @inject(NotifyServiceS) private notifyService!: NotifyService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;

    readonly storeState: CarsTableauStore = this.storeFacade.getState('CarsTableauStore');

    maxTries = 3;

    async getTicket() {
        this.storeState.loading.start();

        let tries = 0;
        while (tries < this.maxTries) {
            tries += 1;

            // eslint-disable-next-line no-await-in-loop
            const { data } = await this.apiService.get('car/ticket');

            if (data && data !== -1) {
                this.storeState.loading.finish();
                return String(data);
            }
        }

        this.notifyService.error();
        return null;
    }
}
