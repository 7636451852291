const PROVIDER_COLORS: { [provider: string]: string } = {
    Hertz: '#FFD205',
    Dollar: '#e01f39',
    Avis: '#c5212c',
    Sixt: '#ff5f00',
    Europcar: '#016201',
    Rentalcars: '#0085ff',
    Budget: '#000000',
    Virtuo: '#252525',
    Lyft: '#8B37FF',
    Booking: '#003580',
    Expedia: '#343B53',
    Alamo: '#295EAC',
    National: '#00703C',
    SurPrice: '#C6000D',
    Easirent: '#EF3437',
    Enterprise: '#159A5B',
    Greenmotion: '#6AA601',
    'THE OUT': '#000000',
    Hotwire: '#db3939',
    Kayak: '#ff690f',
    Priceline: '#0068EF',
};

export default PROVIDER_COLORS;
