import { Route } from 'vue-router';
import use from '../use';
import { CarMappingPopup } from './cars.routes';
import { CommonMenuRoutes } from './common.routes';

export default function EventsManagerRoutes(
    basePath: string = 'events-manager',
    prefix: string = 'events-manager',
    guards: Parameters<typeof use>[0] = [],
) {
    return {
        path: basePath,
        component: () => import('@/modules/common/pages/hotel-transition.page.vue'),
        props: (r: Route) => ({
            hotelId: Number(r.params.hotelId),
        }),
        children: [
            {
                path: '',
                name: prefix,
                beforeEnter: use(guards),
                props: (r: Route) => ({ hotelId: Number(r.params.hotelId) || null }),
                component: () => import('@/modules/events/pages/events-manager.page.vue'),
                children: [
                    {
                        name: `${prefix}.day-event-modal`,
                        path: 'day-events/:day',
                        component: () => import('@/modules/events/pages/events-day.modal.page.vue'),
                        props: (route: Route) => ({ day: Number(route.params.day) }),
                        meta: { modal: true },
                        children: [
                            {
                                name: `${prefix}.day-event-modal.view`,
                                path: 'view-event/:eventId',
                                component: () => import('@/modules/events/pages/event-view-edit.modal.page.vue'),
                                props: (route: Route) => ({ eventId: route.params.eventId }),
                                meta: { modal: true },
                            },
                            {
                                name: `${prefix}day-event-modal.edit`,
                                path: 'edit-event/:eventId',
                                component: () => import('@/modules/events/pages/event-view-edit.modal.page.vue'),
                                props: (route: Route) => ({ eventId: route.params.eventId }),
                                meta: { modal: true },
                            },
                        ],
                    },
                    {
                        name: `${prefix}.new`,
                        path: 'new-event',
                        component: () => import('@/modules/events/pages/event-view-edit.modal.page.vue'),
                        meta: { modal: true },
                    },
                    {
                        name: `${prefix}.edit`,
                        path: 'edit-event/:eventId',
                        component: () => import('@/modules/events/pages/event-view-edit.modal.page.vue'),
                        props: (route: Route) => ({ eventId: route.params.eventId }),
                        meta: { modal: true },
                    },
                    {
                        name: `${prefix}.view`,
                        path: 'view-event/:eventId',
                        component: () => import('@/modules/events/pages/event-view-edit.modal.page.vue'),
                        props: (route: Route) => ({ eventId: route.params.eventId }),
                        meta: { modal: true },
                    },
                    {
                        name: `${prefix}.download-excel`,
                        path: 'download-excel',
                        component: () => import('@/modules/events/pages/download-excel.page.vue'),
                        meta: { modal: true },
                    },
                    ...CommonMenuRoutes(prefix),
                    CarMappingPopup(prefix),
                ],
            },
        ],
    };
}
