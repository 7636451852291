import { Expose, Transform } from 'class-transformer';
import { Item } from '@/modules/common/interfaces';
import PRICE_TYPE from '@/modules/document-filters/constants/price-type.constant';
import { COLOR_NAME } from '@/modules/common/constants';
import { i18n } from '@/modules/translations/translations.service';
import { INSIGHT_CARD_VALUE } from '../constants';

interface ClusterInsightsDocumentResponse {
    los?: {
        los?: number,
        mainHotel?: number,
        competitorsAverage?: number
    }[];
    rate?: {
        missingRates?: PRICE_TYPE[];
        newRates?: PRICE_TYPE[];
    };
    promotion?: {
        mainHotelAveragePromotions?: number;
        competitorsAveragePromotions?: number;
    };
    // eslint-disable-next-line camelcase
    rate_trends?: {
        mainHotelAverageDiff?: number;
        competitorsAverageDiff?: number;
    };
    visibility?: {
        mainHotelAveragePosition?: number;
        competitorsAveragePosition?: number;
    };
    device?: {
        mainHotelAverageDiff?: number;
        competitorsAverageDiff?: number;
    };
    // eslint-disable-next-line camelcase
    new_rooms?: {
        mainHotelAverageRooms?: number;
        competitorsAverageRooms?: number;
    };
}

const calcDiff = (v1: number, v2: number) => `${Math.round((v1 - v2) / v2 * 100)}%`;

export default class ClusterInsightsDocumentModel {
    @Expose()
    id = null;

    @Expose()
    @Transform((_, plain: ClusterInsightsDocumentResponse) => {
        if (!plain.rate) {
            return INSIGHT_CARD_VALUE.NA;
        }

        return {
            items: [
                { name: 'insights.cluster.title.ratesNew', value: plain.rate.newRates !== undefined ? plain.rate.newRates.length : INSIGHT_CARD_VALUE.NA },
                { name: 'insights.cluster.title.ratesMissing', value: plain.rate.missingRates !== undefined ? plain.rate.missingRates.length : INSIGHT_CARD_VALUE.NA },
            ],
            color: (() => {
                if (plain.rate.missingRates === undefined) {
                    return COLOR_NAME.GRAY;
                }
                return plain.rate.missingRates.length > 0 ? COLOR_NAME.RED : COLOR_NAME.GREEN;
            })(),
            tooltip: (() => {
                let tooltip = '';
                if (plain.rate.newRates?.length) {
                    tooltip += i18n.t(plain.rate.newRates?.length === 1
                        ? 'insights.cluster.tooltip.rate_new_was'
                        : 'insights.cluster.tooltip.rate_new_were', [plain.rate.newRates?.length]);
                }
                if (plain.rate.missingRates?.length) {
                    if (tooltip) {
                        tooltip += ' ';
                    }
                    tooltip += i18n.t(plain.rate.missingRates?.length === 1
                        ? 'insights.cluster.tooltip.rate_missing_was'
                        : 'insights.cluster.tooltip.rate_missing_were', [plain.rate.missingRates?.length]);
                }
                return tooltip || i18n.tc('insights.cluster.tooltip.na');
            })(),
        };
    })
    rate!: { items: Item[], color: COLOR_NAME, tooltip: string } | INSIGHT_CARD_VALUE;

    @Expose()
    @Transform((_, plain: ClusterInsightsDocumentResponse) => {
        if (!plain.los || !plain.los.length) return INSIGHT_CARD_VALUE.NA;
        const los1 = plain.los.find(item => item.los === 1);
        const los2 = plain.los.find(item => item.los === 2);
        const los3 = plain.los.find(item => item.los === 3);
        const los1Diff = los1 !== undefined ? calcDiff(los1.mainHotel || 0, los1.competitorsAverage || 0) : null;
        const los2Diff = los2 !== undefined ? calcDiff(los2.mainHotel || 0, los2.competitorsAverage || 0) : null;
        const los3Diff = los3 !== undefined ? calcDiff(los3.mainHotel || 0, los3.competitorsAverage || 0) : null;
        let color = COLOR_NAME.GRAY;
        // Not enough data - grey
        if (los1Diff === null || los2Diff === null && los3Diff === null) {
            color = COLOR_NAME.GRAY;
        // los1 greater then both los2 and los3 - green
        } else if ((los2Diff === null || los1Diff > los2Diff) && (los3Diff === null || los1Diff > los3Diff)) {
            color = COLOR_NAME.GREEN;
        // All los the same - yellow
        } else if ((los2Diff === null || los1Diff === los2Diff) && (los3Diff === null || los1Diff === los3Diff)) {
            color = COLOR_NAME.YELLOW;
        // los1 lower then los2 or los3 - red
        } else {
            color = COLOR_NAME.RED;
        }
        return {
            items: [
                { name: 'insights.cluster.title.los1', value: los1Diff === null ? i18n.tc('na') : los1Diff },
                { name: 'insights.cluster.title.los2', value: los2Diff === null ? i18n.tc('na') : los2Diff },
                { name: 'insights.cluster.title.los3', value: los3Diff === null ? i18n.tc('na') : los3Diff },
            ],
            color,
            tooltip: (() => {
                let tooltip = '';
                if (los1) {
                    tooltip += i18n.t('insights.cluster.tooltip.los', [1, los1Diff === null ? i18n.tc('na') : los1Diff]);
                }
                if (los2) {
                    if (tooltip) {
                        tooltip += '<br /><br />';
                    }
                    tooltip += i18n.t('insights.cluster.tooltip.los', [2, los2Diff === null ? i18n.tc('na') : los2Diff]);
                }
                if (los3) {
                    if (tooltip) {
                        tooltip += '<br /><br />';
                    }
                    tooltip += i18n.t('insights.cluster.tooltip.los', [3, los3Diff === null ? i18n.tc('na') : los3Diff]);
                }
                return tooltip || i18n.tc('insights.cluster.tooltip.na');
            })(),
        };
    })
    los!: { items: Item[], color: COLOR_NAME, tooltip: string } | INSIGHT_CARD_VALUE;

    @Expose()
    @Transform((_, plain: ClusterInsightsDocumentResponse) => (
        plain.promotion
            ? {
                items: [
                    {
                        name: 'insights.cluster.title.promotionsMain',
                        value: plain.promotion.mainHotelAveragePromotions !== undefined ? Math.floor(plain.promotion.mainHotelAveragePromotions) : INSIGHT_CARD_VALUE.NA,
                    },
                    {
                        name: 'insights.cluster.title.promotionsComp',
                        value: plain.promotion.competitorsAveragePromotions !== undefined ? Math.floor(plain.promotion.competitorsAveragePromotions) : INSIGHT_CARD_VALUE.NA,
                    },
                ],
                color: (() => {
                    if (plain.promotion.mainHotelAveragePromotions === undefined || plain.promotion.competitorsAveragePromotions === undefined) {
                        return COLOR_NAME.GRAY;
                    }
                    if (plain.promotion.mainHotelAveragePromotions === plain.promotion.competitorsAveragePromotions) {
                        return COLOR_NAME.YELLOW;
                    }
                    return plain.promotion.mainHotelAveragePromotions < plain.promotion.competitorsAveragePromotions ? COLOR_NAME.RED : COLOR_NAME.GREEN;
                })(),
                tooltip: (() => {
                    let tooltip = '';
                    if (plain.promotion?.mainHotelAveragePromotions) {
                        tooltip += i18n.t(plain.promotion?.mainHotelAveragePromotions === 1
                            ? 'insights.cluster.tooltip.promotion_main_was'
                            : 'insights.cluster.tooltip.promotion_main_were', [Math.floor(plain.promotion?.mainHotelAveragePromotions)]);
                    }
                    if (plain.promotion?.competitorsAveragePromotions) {
                        if (tooltip) {
                            tooltip += ' ';
                        }
                        tooltip += i18n.t(plain.promotion?.competitorsAveragePromotions === 1
                            ? 'insights.cluster.tooltip.promotion_comp_was'
                            : 'insights.cluster.tooltip.promotion_comp_were', [Math.floor(plain.promotion?.competitorsAveragePromotions)]);
                    }
                    return tooltip || i18n.tc('insights.cluster.tooltip.na');
                })(),
            }
            : INSIGHT_CARD_VALUE.NA))
    promotion!: { items: Item[], color: COLOR_NAME, tooltip: string } | INSIGHT_CARD_VALUE;

    @Expose()
    @Transform((_, plain: ClusterInsightsDocumentResponse) => (
        plain.visibility
            ? {
                items: [
                    {
                        name: 'insights.cluster.title.marketMain',
                        value: plain.visibility.mainHotelAveragePosition !== undefined ? Math.floor(plain.visibility.mainHotelAveragePosition) : INSIGHT_CARD_VALUE.NA,
                    },
                    {
                        name: 'insights.cluster.title.marketComp',
                        value: plain.visibility.competitorsAveragePosition !== undefined ? Math.floor(plain.visibility.competitorsAveragePosition) : INSIGHT_CARD_VALUE.NA,
                    },
                ],
                color: (() => {
                    if (plain.visibility.mainHotelAveragePosition === undefined
                        || plain.visibility.competitorsAveragePosition === undefined
                        || !Math.floor(plain.visibility.mainHotelAveragePosition)
                        || !Math.floor(plain.visibility.competitorsAveragePosition)
                    ) {
                        return COLOR_NAME.GRAY;
                    }
                    if (Math.floor(plain.visibility.mainHotelAveragePosition) === Math.floor(plain.visibility.competitorsAveragePosition)) {
                        return COLOR_NAME.YELLOW;
                    }
                    return Math.floor(plain.visibility.mainHotelAveragePosition) > Math.floor(plain.visibility.competitorsAveragePosition)
                        ? COLOR_NAME.RED
                        : COLOR_NAME.GREEN;
                })(),
                tooltip: i18n.tc('insights.cluster.tooltip.visibility'),
            }
            : INSIGHT_CARD_VALUE.NA))
    visibility!: { items: Item[], color: COLOR_NAME, tooltip: string } | INSIGHT_CARD_VALUE;

    @Expose()
    @Transform((_, plain: ClusterInsightsDocumentResponse) => {
        if (!plain.new_rooms) {
            return INSIGHT_CARD_VALUE.NA;
        }

        return {
            items: [
                {
                    name: 'insights.cluster.title.roomsMain',
                    value: plain.new_rooms.mainHotelAverageRooms !== undefined ? Math.floor(plain.new_rooms.mainHotelAverageRooms) : INSIGHT_CARD_VALUE.NA,
                },
                {
                    name: 'insights.cluster.title.roomsComp',
                    value: plain.new_rooms.competitorsAverageRooms !== undefined ? Math.floor(plain.new_rooms.competitorsAverageRooms) : INSIGHT_CARD_VALUE.NA,
                },
            ],
            color: (() => {
                if (!plain.new_rooms.mainHotelAverageRooms) {
                    return COLOR_NAME.GRAY;
                }
                return COLOR_NAME.YELLOW;
            })(),
            tooltip: (() => {
                let tooltip = '';
                if (plain.new_rooms.mainHotelAverageRooms && Math.floor(plain.new_rooms.mainHotelAverageRooms)) {
                    tooltip += i18n.t(Math.floor(plain.new_rooms.mainHotelAverageRooms) === 1
                        ? 'insights.cluster.tooltip.new_rooms_main_was'
                        : 'insights.cluster.tooltip.new_rooms_main_were', [Math.floor(plain.new_rooms.mainHotelAverageRooms)]);
                }
                if (plain.new_rooms.competitorsAverageRooms && Math.floor(plain.new_rooms.competitorsAverageRooms)) {
                    if (tooltip) {
                        tooltip += ' ';
                    }
                    tooltip += i18n.t(Math.floor(plain.new_rooms.competitorsAverageRooms) === 1
                        ? 'insights.cluster.tooltip.new_rooms_comp_was'
                        : 'insights.cluster.tooltip.new_rooms_comp_were', [Math.floor(plain.new_rooms.competitorsAverageRooms)]);
                }
                return tooltip || i18n.tc('insights.cluster.tooltip.na');
            })(),
        };
    })
    // eslint-disable-next-line camelcase
    new_rooms!: { items: Item[], color: COLOR_NAME, tooltip: string } | INSIGHT_CARD_VALUE;

    @Expose()
    @Transform((_, plain: ClusterInsightsDocumentResponse) => (
        plain.device
            ? {
                items: [
                    {
                        name: 'insights.cluster.title.deviceMain',
                        value: plain.device.mainHotelAverageDiff !== undefined ? `${Math.floor(plain.device.mainHotelAverageDiff)}%` : INSIGHT_CARD_VALUE.NA,
                    },
                    {
                        name: 'insights.cluster.title.deviceComp',
                        value: plain.device.competitorsAverageDiff !== undefined ? `${Math.floor(plain.device.competitorsAverageDiff)}%` : INSIGHT_CARD_VALUE.NA,
                    },
                ],
                color: (() => {
                    if (plain.device.mainHotelAverageDiff === undefined || plain.device.competitorsAverageDiff === undefined) {
                        return COLOR_NAME.GRAY;
                    }

                    if (plain.device.mainHotelAverageDiff === 0) {
                        return COLOR_NAME.GREEN;
                    }

                    return COLOR_NAME.RED;
                })(),
                tooltip: (() => {
                    let tooltip = '';
                    if (plain.device?.mainHotelAverageDiff) {
                        tooltip += i18n.t('insights.cluster.tooltip.device_main', [Math.floor(plain.device?.mainHotelAverageDiff)]);
                    }
                    if (plain.device?.competitorsAverageDiff) {
                        if (tooltip) {
                            tooltip += ' ';
                        }
                        tooltip += i18n.t('insights.cluster.tooltip.device_comp', [Math.floor(plain.device?.competitorsAverageDiff)]);
                    }
                    return tooltip || i18n.tc('insights.cluster.tooltip.na');
                })(),
            }
            : INSIGHT_CARD_VALUE.NA))
    device!: { items: Item[], color: COLOR_NAME, tooltip: string } | INSIGHT_CARD_VALUE;

    @Expose()
    @Transform((_, plain: ClusterInsightsDocumentResponse) => {
        if (!plain.rate_trends) {
            return INSIGHT_CARD_VALUE.NA;
        }

        if (plain.rate_trends.mainHotelAverageDiff === 0) {
            return INSIGHT_CARD_VALUE.NOCHANGES;
        }

        return {
            items: [
                {
                    name: 'insights.cluster.title.trendsMain',
                    value: plain.rate_trends.mainHotelAverageDiff !== undefined ? `${Math.floor(plain.rate_trends.mainHotelAverageDiff)}%` : INSIGHT_CARD_VALUE.NA,
                },
                {
                    name: 'insights.cluster.title.trendsComp',
                    value: plain.rate_trends.competitorsAverageDiff !== undefined ? `${Math.floor(plain.rate_trends.competitorsAverageDiff)}%` : INSIGHT_CARD_VALUE.NA,
                },
            ],
            color: (() => {
                if (plain.rate_trends.mainHotelAverageDiff === undefined || plain.rate_trends.competitorsAverageDiff === undefined) {
                    return COLOR_NAME.GRAY;
                }
                if (plain.rate_trends.mainHotelAverageDiff === plain.rate_trends.competitorsAverageDiff) {
                    return COLOR_NAME.YELLOW;
                }
                return Math.floor(plain.rate_trends.mainHotelAverageDiff) < Math.floor(plain.rate_trends.competitorsAverageDiff)
                    ? COLOR_NAME.RED
                    : COLOR_NAME.GREEN;
            })(),
            tooltip: (() => {
                let tooltip = '';
                if (plain.rate_trends.mainHotelAverageDiff && Math.floor(plain.rate_trends.mainHotelAverageDiff)) {
                    tooltip += i18n.t('insights.cluster.tooltip.rate_trends_main', [Math.floor(plain.rate_trends.mainHotelAverageDiff)]);
                }
                if (plain.rate_trends.competitorsAverageDiff && Math.floor(plain.rate_trends.competitorsAverageDiff)) {
                    if (tooltip) {
                        tooltip += ' ';
                    }
                    tooltip += i18n.t('insights.cluster.tooltip.rate_trends_comp', [Math.floor(plain.rate_trends.competitorsAverageDiff)]);
                }
                return tooltip || i18n.tc('insights.cluster.tooltip.na');
            })(),
        };
    })
    // eslint-disable-next-line camelcase
    rate_trends!: { items: Item[], color: COLOR_NAME, tooltip: string } | INSIGHT_CARD_VALUE;

    // @Expose()
    // market_leader

    // @Expose()
    // relative_position
}
