import { inject, injectable } from '@/inversify';

export const StorageServiceS = Symbol.for('StorageService');
@injectable()
export default class StorageService {
    getItem(key: string) {
        const item = sessionStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }
    setItem(key: string, object: Object) {
        sessionStorage.setItem(key, JSON.stringify(object));
    }
    removeItem(key: string) {
        sessionStorage.removeItem(key);
    }
    clear() {
        sessionStorage.clear();
    }

    get redirrectUrl() {
        return this.getItem('redirrectUrl');
    }

    set redirrectUrl(url: string) {
        this.setItem('redirrectUrl', url);
    }

    clearRedirrectUrl() {
        this.removeItem('redirrectUrl');
    }
}
