import { Expose, plainToClass, Transform } from 'class-transformer';
import type Day from '@/modules/common/types/day.type';
import { IsNumber, IsOptional, Validate } from 'class-validator';
import MarketsDocumentItemModel from '@/modules/markets/models/markets-document-item.model';

interface ITrendData {
    [date: string]: {
        [hotelId: string]: {
            position: number,
            page: number,
            // eslint-disable-next-line camelcase
            number_of_hotels: number,
            // eslint-disable-next-line camelcase
            total_hotel_reviewers: number
        } | MarketsDocumentItemModel
    }
}

export default class MarketsHistoryModel {
    @Expose()
    @Transform((_, plain) => plain.day)
    @IsNumber()
    @IsOptional()
    requestDay: Day | null = null;

    @Expose()
    @Transform((_, plain) => {
        const trendData: ITrendData = plain.trend_data;

        if (!trendData) {
            return trendData;
        }

        Object.entries(trendData).forEach(([date, hotels]) => {
            Object.entries(hotels).forEach(([hotelId, marketItem]) => {
                trendData[date][hotelId] = plainToClass(
                    MarketsDocumentItemModel,
                    <MarketsDocumentItemModel> marketItem,
                    { excludeExtraneousValues: true },
                );
            });
        });

        return trendData;
    })
    trendData!: {
        [date: string]: { [hotelId: number]: MarketsDocumentItemModel } | null
    };
}
