/* eslint-disable camelcase */
import { Expose, plainToClass, Transform } from 'class-transformer';
import { DayMarketInsightData } from '../types';

interface BEHotel {
    date: string;
    fornovaId: number;
    position: number;
    page: number;
    promoted: boolean;
    promotions: {
        name: string;
        data: {
            status: boolean;
        }
    }[];
}

export default class InsightDayMarketModel {
    @Expose()
    @Transform((_, plain) => (
        Object.fromEntries(Object.entries<BEHotel>(plain.data).map(([fornovaId, hotel]) => {
            const tData = {
                ...hotel,
                name: plain.hotelNames[fornovaId] || fornovaId,
            };

            return [fornovaId, tData];
        }))
    ))
    data!: Record<string, DayMarketInsightData>;

    @Expose()
    filters!: Record<string, string | number>;

    @Expose()
    @Transform((_, plain) => plain.alertData?.message || '')
    message!: string;
}
