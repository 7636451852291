import { inject, injectable } from '@/inversify';
import moment from 'moment';
import * as _ from 'lodash';
import type Day from '@/modules/common/types/day.type';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import CarsPriceHistoryStore from '@/modules/cars/store/cars-price-history.store';
import HelperService, { HelperServiceS } from '@/modules/common/services/helper.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '@/modules/document-filters/document-filters.service';
import CarsStore from '@/modules/cars/store/cars.store';
import CarsPriceHistoryApi, { CarsPriceHistoryApiS } from './cars-price-history-api.service';

export const CarsPriceHistoryServiceS = Symbol.for('CarsPriceHistoryServiceS');
@injectable()
export default class CarsPriceHistoryService {
    @inject(UserServiceS) private userService!: UserService;
    @inject(CarsPriceHistoryApiS) private carsPriceHistoryApi!: CarsPriceHistoryApi;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(DocumentFiltersServiceS) private documentFiltersService!: DocumentFiltersService;

    readonly storeState: CarsPriceHistoryStore = this.storeFacade.getState('CarsPriceHistoryStore');
    readonly carStoreState: CarsStore = this.storeFacade.getState('CarsStore');

    documentDay: Day | null = null;

    myCarColor = '#448CCB';
    colors: string[] = [
        '#FF7F0E', '#7F7F7F', '#D62728', '#cb7249', '#A170CD', '#BCBD22', '#2CA02C',
        '#34475A', 'BROWN', '#52A9FF', '#00759E', '#34475A', 'pink',
    ];

    constructor() {
        this.storeFacade.watch(() => [
            this.documentFiltersService.storeState.settings.month,
            this.documentFiltersService.storeState.settings.year,
            this.carStoreState.settings.pickUpCityCode,
            this.carStoreState.settings.dataSource,
            this.carStoreState.settings.lor,
            this.carStoreState.settings.pos,
            this.carStoreState.settings.chain,
            this.carStoreState.settings.currentDataSourceMode,
            this.carStoreState.document,
        ], (newVal, oldVal) => {
            if (newVal && newVal.every((x: any) => x !== null) && !_.isEqual(oldVal, newVal)) {
                this.resetLoadings();
            }
        });

        this.storeFacade.watch(() => [
            this.carStoreState.fleetAvailability,
            this.storeState.document,
        ], this.resetOccupancyLoading.bind(this));
    }

    async resetLoadings() {
        this.storeState.loading.reset();
        this.storeState.document = null;
        this.storeState.occupancyDocument = null;
    }

    async resetOccupancyLoading() {
        this.storeState.loadingOccupancy.reset();
        this.storeState.occupancyDocument = null;
    }

    async loadData(): Promise<boolean> {
        const groupedId = this.carStoreState.document ? this.carStoreState.document.id : null;

        if (!groupedId || !this.documentDay) {
            return false;
        }

        const { settings } = this.documentFiltersService.storeState;
        const carSettings = this.carStoreState.settings;

        if (
            settings.month === null
            || settings.year === null
            || carSettings.pickUpCityCode === null
            || carSettings.dataSource === null
            || carSettings.lor === null
            || carSettings.pos === null
        ) {
            return false;
        }
        this.storeState.document = await this.carsPriceHistoryApi.getCarRatesPriceHistory(settings, carSettings, this.documentDay);

        return true;
    }

    async loadFleetOccupancyData(): Promise<boolean> {
        const { fleetAvailability } = this.carStoreState;
        const fleetOccupancyId = fleetAvailability ? fleetAvailability.id : null;

        if (!fleetOccupancyId || !this.documentDay || !this.storeState.document) {
            return false;
        }

        const carSettings = this.carStoreState.settings;
        const document = await this.carsPriceHistoryApi.getFleetAvailabilityTrendsDocument(this.documentDay, fleetOccupancyId, carSettings.pos!);
        this.storeState.occupancyDocument = document;

        return true;
    }

    get occupancyData() {
        this.helperService.dynamicLoading(this.storeState.loadingOccupancy, this.loadFleetOccupancyData.bind(this));
        return this.storeState.occupancyDocument;
    }

    getOccupancy(date: string) {
        let occupancy = null;
        const { carClasses } = this.carStoreState.settings;

        if (this.occupancyData && this.occupancyData.occupancyDates[date] && carClasses) {
            occupancy = {
                availableCars: 0,
                fleetSize: 0,
            };

            occupancy = Object.entries(this.occupancyData.occupancyDates[date])
                .filter(([carClass]) => carClasses.includes(carClass))
                .reduce(
                    (prevVal, [, nextVal]) => {
                        const availableCars = prevVal!.availableCars + nextVal!.availableCars;
                        const fleetSize = prevVal!.fleetSize + nextVal!.fleetSize;
                        return {
                            availableCars,
                            fleetSize,
                        };
                    },
                    occupancy,
                );
        }
        return occupancy;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.document;
    }

    initCarRatesData(day: Day) {
        this.documentDay = day;
        this.storeState.document = null;
        this.storeState.loading.reset();
    }

    get get30Days() {
        return Array(29).fill(null).map((val: any, i: number) => (i + 1) as Day).reverse();
    }
}
