import { Module, VuexModule } from 'vuex-module-decorators';
import CarsFiltersModel from '@/modules/cars/models/cars-filters.model';
import LoadingModel from '@/modules/common/models/loading.model';
import BranchesModel from '@/modules/cars/models/branches.model';

@Module
export default class CarsFiltersStore extends VuexModule {
    settings: CarsFiltersModel = new CarsFiltersModel();
    loading: LoadingModel = new LoadingModel();
    branchesLoading: LoadingModel = new LoadingModel();
    branches: BranchesModel[] = [];
}
