
import { Component, Vue } from 'vue-property-decorator';
import { TOOLTIP_DIRECTION } from './custom-tooltip-v2.vue';

/**
 * Is used as a directive.
 * Don't use it in a compoents directly.
 * Only for simple tooltips to show some message, for more complex logic use custom-tooltip-v2.vue
 */
@Component
export default class CustomTooltipDirective extends Vue {
    position: TOOLTIP_DIRECTION = TOOLTIP_DIRECTION.TOP;
    header: string = '';
    text: string = '';
    visible: boolean = false;
    left: number = 0;
    top: number = 0;
}
