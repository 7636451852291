import { inject, injectable } from '@/inversify';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import AuthStore from './store/auth.store';

export const TokenSessionStorageServiceS = Symbol.for('TokenSessionStorageServiceS');
@injectable()
export default class TokenSessionStorageService {
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;

    readonly storeState: AuthStore = this.storeFacade.getState('AuthStore');

    get token(): string | null {
        return localStorage.getItem('token');
    }

    setToken(token: string | null) {
        if (token) {
            localStorage.setItem('token', token);
            this.storeState.token = token;
        }
    }

    removeToken() {
        localStorage.removeItem('token');
    }
}
