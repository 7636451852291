import RatesDocumentModel from '@/modules/rates/models/rates-document.model';
import RatesDocumentAllModel from '@/modules/rates/models/rates-document-all.model';
import RatesSettingsModel from '@/modules/rates/models/rates-settings.model';
import DocumentFiltersModel from '@/modules/document-filters/models/document-filters.model';
import {
    Module, VuexModule,
} from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import CompsetModel from '@/modules/compsets/models/compset.model';
import type Day from '@/modules/common/types/day.type';
import RatesPriceHistoryModel from '../models/rates-price-history.model';
import RatesPriceHistoryFilterAllModel from '../models/rates-price-history-filter-all.model';
import { PRICE_SHOWN } from '../../rates/constants';

@Module
export default class RatesPriceHistoryStore extends VuexModule {
    loading: LoadingModel = new LoadingModel();
    isRatesDocumentLoading = true;

    dayIndex: number = 0;
    documentDay: Day | null = null;

    priceShown: PRICE_SHOWN | null = null;

    currency: string | null = null;

    // [TODO] remove it, when price history common service will be splited to smaller services
    // (take settings from correspondant services: rates/cluster/etc)
    ratesSettings: Record<string, RatesSettingsModel | null> | null = null;
    documentSettings: DocumentFiltersModel | null = null;
    compset: CompsetModel | null = null;

    // [TODO] remove it, when price history common service will be splited to smaller services
    // (take documents from correspondant services: rates/cluster/etc)
    rateDocuments: Record<string, RatesDocumentModel | RatesDocumentAllModel | null> | null = null;

    // Price history documents after merge with rate documents
    trendDocuments: {
        [key: string]: RatesPriceHistoryModel | RatesPriceHistoryFilterAllModel | null,
    } = {
        main: null,
    };
}
