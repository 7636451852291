/* eslint-disable no-param-reassign */
import { inject, injectable } from '@/inversify';
import { plainToClass } from 'class-transformer';

import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import { LOGTYPE } from '@/modules/open-telemetry/constants';

import ApiService, { ApiServiceS } from '../common/services/api.service';
import DocumentFiltersModel from '../document-filters/models/document-filters.model';
import PromotionsDocumentModel from './models/promotions-document.model';
import PromotionHistoryDocumentModel from './models/promotion-history-document.model';
import ConfigService, { ConfigServiceS } from '../config/config.service';

export const PromotionsApiServiceS = Symbol.for('PromotionsApiServiceS') as unknown as string;

@injectable()
export default class PromotionsApiService {
    @inject(ApiServiceS) private apiService!: ApiService;
    @inject(OpenTelemetryServiceS) private otelService!: OpenTelemetryService;
    @inject(ConfigServiceS) private configService!: ConfigService;

    async getPromotionData(settings: DocumentFiltersModel, comparison?: { key: string, value: string }) {
        const params = [
            settings.compsetId,
            settings.month + 1,
            settings.year,
        ].join('/');

        const query: { [key: string]: string | number | null } = {
            pos: settings.pos,
            los: settings.los,
        };

        if (comparison) {
            query[comparison.key] = comparison.value;
        }

        const { data } = await this.apiService.get(`promotion-detections/${params}`, query);

        return plainToClass(PromotionsDocumentModel, data, { excludeExtraneousValues: true });
    }

    async getTrendData(documentId: number, day: number) {
        const { data } = await this.apiService
            .get(`promotion-detections/trend/${documentId}/${day}`);

        return plainToClass(PromotionHistoryDocumentModel, data, { excludeExtraneousValues: true });
    }

    async getProgramList() {
        const { data } = await this.apiService.get('promotion-detections/types');

        return data as Record<string, string[]>;
    }

    async triggerScan(params: Promotions.ScanParams) {
        this.otelService.startSpan({ name: 'promotions', prefix: LOGTYPE.ONDEMAND });
        const { data } = await this.apiService
            .post('scan/promotion-detection/by-compset-id', params);
        this.otelService.endSpan({ name: 'promotions', prefix: LOGTYPE.ONDEMAND }, { sendLogs: true });

        return data as Promotions.ScanResponse;
    }

    async setOnDemandFeature(state: boolean) {
        await this.apiService
            .post('users/enable-features', {
                enableFeatures: {
                    on_demand_promotion_detection: state,
                },
            });
    }

    getProgramLogo(program: string) {
        return `${this.configService.apiUrl}/promotion-detections/types/image?name=${program}`;
    }
}
