import { inject, injectable } from '@/inversify';
import { KEY } from '@/inversify.keys';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import Day from '../common/types/day.type';
import { calculateDiff } from '../common/utils/calculate-diff';
import UserService, { UserServiceS } from '../user/user.service';
import RateValueScoreApiService from './rate-value-score-api.service';
import RateValueScoreService from './rate-value-score.service';
import RVSStore from './rvs.store';

export const RVSScoreServiceS = Symbol.for('RVSScoreService') as unknown as string;

@injectable()
export default class RVSScoreService {
    @inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @inject(UserServiceS)
    private userService!: UserService;

    @inject(KEY.RateValueScoreApiService)
    private rateValueScoreApiService!: RateValueScoreApiService;

    @inject(KEY.RateValueScoreSerivce)
    private rateValueScoreService!: RateValueScoreService;

    @inject(HelperServiceS)
    private helperService!: HelperService;

    readonly rvsStore: RVSStore = this.storeFacade.getState('RVSStore');

    constructor() {
        this.storeFacade.watch(() => [
            this.userService.currentHotelId,
            this.rvsStore.settings.provider,
        ], () => {
            this.storeState.loading.reset();
        });
    }

    get storeState() {
        return this.rvsStore.score;
    }

    get settings() {
        return this.rvsStore.settings;
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.data;
    }

    get day() {
        return new Date().getDate() as Day;
    }

    get mainScore() {
        return this.data?.monthMainAverage || 0;
    }

    get averageCompsetScore() {
        return this.data?.monthCompsetAverage || 0;
    }

    get difference() {
        return calculateDiff(this.mainScore, this.averageCompsetScore) || 0;
    }

    get isLoading() {
        return !this.data || this.storeState.loading.isLoading();
    }

    get hasData() {
        return this.data && (this.data.monthMainAverage || this.data.monthCompsetAverage);
    }

    get assessment() {
        return this.rateValueScoreService.getAssessment(this.day, this.data);
    }

    private async loadData() {
        const { currentHotelId } = this.userService;
        const { provider } = this.rvsStore.settings;

        const data = new Date();
        const year = data.getFullYear();
        const month = data.getMonth();

        if (!currentHotelId) {
            return false;
        }

        const doc = await this.rateValueScoreApiService.getMonthData(currentHotelId, year, month, provider);
        this.storeState.data = doc;

        return true;
    }
}
