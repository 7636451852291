import { Expose, plainToClass, Transform } from 'class-transformer';
import ClusterHotelsModel from '@/modules/cluster/models/cluster.model';
import MarketsCompsetMainModel from '@/modules/cluster/models/markets-compset-main.model';

export default class ClusterHotelsMarketsModel extends ClusterHotelsModel {
    @Expose()
    @Transform((_, plain) => plainToClass(MarketsCompsetMainModel, <MarketsCompsetMainModel>plain.main_compset, { excludeExtraneousValues: true }))
    compsetMain?: MarketsCompsetMainModel;

    @Expose()
    @Transform((_, plain) => plain.avgPosition || 0)
    avgPosition?: number;
}
