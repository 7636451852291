import { Expose, plainToClass, Transform } from 'class-transformer';
import {
    InsightGroupBE, Insight, RoomsInsight, MarketLeaderInsight,
} from '../types';
import InsightGroupModel from './insight-group.model';

export default class InsightGroupPopupModel<T extends MarketLeaderInsight | RoomsInsight | Insight> {
    @Expose()
    @Transform((_, plain: { data: InsightGroupBE, hotelNames: { [fId: string]: string } }) => {
        const group = plainToClass(InsightGroupModel, <InsightGroupBE> plain.data, { excludeExtraneousValues: true }) as InsightGroupModel<T>;
        group.insights = Object.entries(group.insights).reduce((acc, [insId, insValue]) => ({
            ...acc,
            [insId]: {
                ...insValue as T,
                // Add related hotel name to the insight
                hotelName: insValue.hotelName || plain.hotelNames[(insValue as T).fornovaId] || (insValue as T).fornovaId,
            },
        }), {} as Record<string, T>);
        return group;
    })
    data!: InsightGroupModel<T>;
}
