enum USER_LEVELS {
    HOTEL = 'hotel',
    CHAIN = 'chain',
    CLUSTER = 'cluster',
    ONBOARDING = 'Self Onboarding',
    MARKET = 'market',
    CAR = 'car',
}

export default USER_LEVELS;
