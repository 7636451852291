import { inject, injectable } from '@/inversify';
import StoreFacade, { StoreFacadeS } from '../common/services/store-facade';
import PromotionsStore from './promotions.store';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import PromotionsApiService, { PromotionsApiServiceS } from './promotions-api.service';
import DocumentFiltersService, { DocumentFiltersServiceS } from '../document-filters/document-filters.service';
import UserService, { UserServiceS } from '../user/user.service';
import { COMPARE_KEY } from './consts/compare-filter-values';
import SCAN_STATUS from '../rates/constants/scan-status.constant';
import CompsetsService, { CompsetsServiceS } from '../compsets/compsets.service';
import getScanRange from '../common/utils/get-scan-range.util';
import type Day from '../common/types/day.type';
import SocketService, { SocketServiceS } from '../common/modules/socket/socket.service';
import PromotionsScanModel from './models/promotions-scan.model';
import PromotionsSettings, { PromotionGraphType } from './models/promotions-settings.model';
import PromotionsDocumentModel, { ProgramData } from './models/promotions-document.model';
import ASSESSMENT_TYPES from '../common/constants/assessments-types.constant';
import ProvidersService, { ProvidersServiceS } from '../providers/providers.service';

type ProgramLabel = string;
export interface ProgramDictionary {
    [provider: string]: {
        [program: string]: ProgramLabel;
    }
}

interface PromotionsPublicInterface {
    /** Current promotions document */
    data: PromotionsDocumentModel | null;

    /** The second document for comparison */
    comparedData: PromotionsDocumentModel | null;

    /** Current loading state of service */
    isLoading: boolean;

    /** Current loading state of compared data */
    isComparedLoading: boolean;

    /** All possible hotels in the current document */
    hotels: number[];

    /** Current settings of service */
    settings: PromotionsSettings;

    /** Current provider */
    provider: string | null;

    /** List of providers that have data */
    providers: string[];

    /** Current program view */
    programView: string;

    /** Current graph type */
    graphType: PromotionGraphType;

    /** Current comparison filter */
    comparisonFilter: PromotionsStore['comparisonFilter'];

    /** The current document's scan status */
    scanStatus: SCAN_STATUS;

    /** Returns the ability state of scan */
    isScanFeatureEnabled: boolean;

    /** Returns the empty state of the specified document */
    isDocumentEmpty(doc: PromotionsDocumentModel | null): boolean;

    /**
     * Returns `true` if the specified document don't have any promotions for all days in
     * month for specified `provider` and `program`
     */
    isNoPromotions(provider: string, program: string, hotelId: number, checkActiveStatus: boolean, doc?: PromotionsDocumentModel): boolean;

    /** Returns the programs set with program data for specified day and hotelId */
    getPrograms(day: number, provider: string, hotelId: number, doc?: PromotionsDocumentModel): Record<string, ProgramData> | null;

    /** Returns the program data */
    getProgram(day: number, provider: string, hotelId: number, program: string, doc?: PromotionsDocumentModel): ProgramData | null;

    /** Returns active programs for specified day and hotelId */
    getActivePrograms(day: number, provider: string, hotelId: number, doc?: PromotionsDocumentModel): string[];

    /** Returns active days count for program */
    getProgramActiveDays(provider: string, program: string, hotelId: number, doc?: PromotionsDocumentModel): number;

    /** Returns average percent for program */
    getProgramAveragePercent(provider: string, program: string, hotelId: number, doc?: PromotionsDocumentModel): number;

    /** Returns average percent for program of competitors */
    getCompetitorsAveragePercentDay(day: number, provider: string, program: string, mainHotel: number, doc?: PromotionsDocumentModel): number | null;

    /** Returns new state of specified program */
    isProgramNew(provider: string, program: string, hotelId: number, doc?: PromotionsDocumentModel): boolean;

    /** Returns the logo url for specified program */
    getProgramLogo(program: string): string;

    /** Returns programs that have percentage value */
    getDealsPrograms(day: number, provider: string, hotelId: number, doc?: PromotionsDocumentModel): ProgramData[];

    /** Triggers a new scan for the current document */
    triggerScan(day?: number, allProviders?: boolean, doc?: PromotionsDocumentModel): Promise<void>;

    /** Returns the link of the specified promotion */
    getPromotionLink(day: number, provider: string, hotelId: number, doc?: PromotionsDocumentModel): string | null;

    /** Resets the service document state */
    resetDocument(): void;

    /** Returns the assesment type for specified params */
    getAssessment(day: number, hotelId: number, program: string, provider: string, doc?: PromotionsDocumentModel): ASSESSMENT_TYPES | null;

    /** Sets the existing document as current */
    setDocument(doc: PromotionsDocumentModel): void;

    /** Returns the list of competitors */
    getCompetitors(mainHotelId: number, doc?: PromotionsDocumentModel): number[];

    /** Returns the last scan date from the document for specified day */
    getLastScanDate(day: number): Date | null;

    /** Returns the active state for specified params */
    getDayActiveState(day: number, provider: string, mainHotelId: number, program: string, doc?: PromotionsDocumentModel): { competitors: boolean, main: boolean };
}

export const PromotionsServiceS = Symbol.for('PromotionsServiceS') as unknown as string;

@injectable()
export default class PromotionsService implements PromotionsPublicInterface {
    @inject(StoreFacadeS)
    private storeFacade!: StoreFacade;

    @inject(HelperServiceS)
    private helperService!: HelperService;

    @inject(PromotionsApiServiceS)
    private promotionsApiService!: PromotionsApiService;

    @inject(DocumentFiltersServiceS)
    private documentFiltersService!: DocumentFiltersService;

    @inject(UserServiceS)
    private userService!: UserService;

    @inject(CompsetsServiceS)
    private compsetsService!: CompsetsService;

    @inject(SocketServiceS)
    private socketService!: SocketService;

    @inject(ProvidersServiceS)
    private providersService!: ProvidersService;

    private storeState: PromotionsStore = this.storeFacade.getState('PromotionsStore');

    private storeChangeListeners: (() => void)[] = [];

    constructor() {
        this.storeFacade.watch(() => [
            this.documentFiltersService.settings.year,
            this.documentFiltersService.settings.month,
            this.documentFiltersService.settings.compsetId,
            this.documentFiltersService.settings.pos,
            this.documentFiltersService.settings.los,
        ], () => {
            this.storeState.loading.reset();
            this.dispatchChangeEvent();
        });

        this.storeFacade.watch(() => [
            this.documentFiltersService.settings.year,
            this.documentFiltersService.settings.month,
            this.documentFiltersService.settings.compsetId,
            this.storeState.comparisonFilter,
        ], () => {
            this.storeState.comparedLoading.reset();
        });

        this.socketService.onPromotionsScan(this.onScanUpdate.bind(this));
    }

    private onScanUpdate(data: PromotionsScanModel) {
        if (this.data && this.data.documentId === data.promotionsDocumentId) {
            this.storeState.loading.reset();
        }
    }

    get data() {
        this.helperService.dynamicLoading(this.storeState.loading, this.loadData.bind(this));
        return this.storeState.data;
    }

    get currentDocument() {
        return this.storeState.data;
    }

    get comparedData() {
        this.helperService
            .dynamicLoading(
                this.storeState.comparedLoading,
                this.loadData.bind(this, this.storeState.comparisonFilter),
            );
        return this.storeState.comparedData;
    }

    get isLoading() {
        return this.storeState.loading.isLoading();
    }

    get isComparedLoading() {
        return this.storeState.comparedLoading.isLoading();
    }

    get isScanFeatureEnabled() {
        return this.userService.enabledFeatures!.on_demand_promotion_detection;
    }

    get isNoData() {
        return (this.isDocumentEmpty(this.data) || !this.providers.length) && !this.isLoading;
    }

    get loading() {
        return this.storeState.loading;
    }

    get hotels() {
        if (!this.data) return [];
        return this.data.hotels;
    }

    get settings() {
        return this.storeState.settings;
    }

    get provider() {
        return this.settings.provider;
    }

    set provider(value: string | null) {
        this.settings.provider = value;
    }

    get programView() {
        return this.settings.programView;
    }

    set programView(value: string) {
        this.settings.programView = value;
    }

    get graphType() {
        return this.settings.graphType;
    }

    set graphType(value: PromotionGraphType) {
        this.settings.graphType = value;
    }

    get comparisonFilter() {
        return this.storeState.comparisonFilter;
    }

    set comparisonFilter(value: { key: COMPARE_KEY, value: string }) {
        this.storeState.comparisonFilter = value;
    }

    get providers() {
        if (!this.data || !this.data.providerData) return [];

        return Object
            .keys(this.data!.providerData)
            .filter(provider => this.getProgramList(provider, this.userService.currentHotelId!).length > 0);
    }

    get scanStatus() {
        if (!this.data) return SCAN_STATUS.FINISHED;

        return this.data.scanStatus;
    }

    set scanStatus(value: SCAN_STATUS) {
        if (!this.data) return;

        this.data.scanStatus = value;
    }

    isDocumentEmpty(doc: PromotionsDocumentModel | null) {
        return !doc || !doc.providerData || !Object.keys(doc.providerData).length;
    }

    onFiltersChanged(handler: () => void) {
        this.storeChangeListeners.push(handler);

        return () => {
            this.storeChangeListeners = this.storeChangeListeners.filter(h => handler !== h);
        };
    }

    getPrograms(day: number, provider: string, hotelId: number, doc?: PromotionsDocumentModel) {
        const data = doc || this.data;

        if (!data || !data.providerData) return null;

        const hotels = data.providerData[provider];

        if (!hotels) return null;
        if (!hotels[hotelId]) return null;

        return hotels[hotelId][day] || null;
    }

    getProgramList(provider: string, hotelId: number) {
        if (!this.data) return [];
        const { [provider]: programList = [] } = this.data.promotions;

        const { competitors } = this.documentFiltersService;

        return programList
            .filter(program => {
                const mainActive = !!this.getProgramActiveDays(provider, program, hotelId);
                if (mainActive) return true;

                const oneOfCompetitorsActive = competitors
                    .some(hotelId => !!this.getProgramActiveDays(provider, program, hotelId));

                return oneOfCompetitorsActive;
            });
    }

    getProgram(day: number, provider: string, hotelId: number, program: string, doc?: PromotionsDocumentModel) {
        const programs = this.getPrograms(day, provider, hotelId, doc);

        if (!programs) return null;

        return programs[program];
    }

    isNoPromotions(provider: string, program: string, hotelId: number, checkActiveStatus = false, doc?: PromotionsDocumentModel) {
        const data = doc || this.data;

        if (!data || !data.providerData) return true;

        return !this.documentFiltersService.days
            .some(day => {
                const { [provider]: hotels } = data.providerData;

                if (!hotels[hotelId]) return false;
                if (!hotels[hotelId][day]) return false;

                if (checkActiveStatus) {
                    return !!hotels[hotelId][day][program]
                        && hotels[hotelId][day][program].status;
                }

                return !!hotels[hotelId][day][program];
            });
    }

    getActivePrograms(day: number, provider: string, hotelId: number, doc?: PromotionsDocumentModel) {
        const data = doc || this.data;

        if (!data) return [];

        return (data.promotions[provider] || [])
            .filter(program => {
                const programData = this.getProgram(day, provider, hotelId, program, doc);

                return programData ? programData.status : false;
            });
    }

    getProgramActiveDays(provider: string, program: string, hotelId: number, doc?: PromotionsDocumentModel) {
        return this.documentFiltersService.days
            .filter(day => {
                const programs = this.getPrograms(day, provider, hotelId, doc);

                if (!programs) return false;

                return programs[program]
                    ? programs[program].status
                    : false;
            })
            .length;
    }

    getProgramAveragePercent(provider: string, program: string, hotelId: number, doc?: PromotionsDocumentModel) {
        let activeDays = 0;

        const percent = this.documentFiltersService.days
            .reduce((sum, day) => {
                const programData = this.getProgram(day, provider, hotelId, program, doc);

                if (!programData || !programData.status) return sum;

                activeDays++;

                return sum + programData.percentage;
            }, 0) / activeDays;

        return percent;
    }

    getCompetitorsAveragePercentDay(day: number, provider: string, program: string, mainHotel: number, doc?: PromotionsDocumentModel) {
        const data = doc || this.data;

        if (!data) return null;

        let activeCompetitorsCount = 0;

        const competitors = this.getCompetitors(mainHotel, doc);

        const competitorsSum = competitors.reduce((sum, hotelId) => {
            const programData = this.getProgram(day, provider, hotelId, program, doc);

            if (!programData || !programData.status) return sum;
            activeCompetitorsCount++;
            return sum + programData.percentage;
        }, 0);

        if (!activeCompetitorsCount) return 0;

        return competitorsSum / activeCompetitorsCount;
    }

    isProgramNew(provider: string, program: string, hotelId: number, doc?: PromotionsDocumentModel) {
        const data = doc || this.data;

        if (!data || !data.newPromotions) return false;

        const programList = data.newPromotions[provider];
        if (!programList) return false;

        const programData = programList[program];
        if (!programData) return false;

        return programData[hotelId];
    }

    getProgramLogo(program: string) {
        return this.promotionsApiService.getProgramLogo(program);
    }

    getDealsPrograms(day: number, provider: string, hotelId: number, doc?: PromotionsDocumentModel) {
        const programs = this.getPrograms(day, provider, hotelId, doc);

        if (!programs) return [];

        return Object
            .values(programs)
            .filter(programData => programData.status && programData.percentage);
    }

    async triggerScan(day?: number, allProviders: boolean = false, doc?: PromotionsDocumentModel) {
        const data = doc || this.data;
        const { currentCompset } = this.compsetsService;
        const { pos, los } = this.documentFiltersService.settings;
        const { provider } = this.settings;

        const isProviderValid = allProviders ? true : !!provider;

        if (!currentCompset || !los || !pos || !isProviderValid || !data) return;

        const [startDate, endDate] = getScanRange(this.documentFiltersService.settings, day as Day);
        const toIso = (d: Date) => [
            d.getFullYear(),
            String(d.getMonth() + 1).padStart(2, '0'),
            String(d.getDate()).padStart(2, '0'),
        ].join('-');

        await this.promotionsApiService.triggerScan({
            ondemand: true,
            compSetIds: [currentCompset.id],
            los: [los],
            pos: [pos],
            providers: allProviders
                ? this.providersService.promotionsProviders
                : [provider!],
            start_date: toIso(startDate),
            end_date: endDate && toIso(endDate),
        });

        data.scanStatus = SCAN_STATUS.IN_PROGRESS;
    }

    getPromotionLink(day: number, provider: string, hotelId: number, doc?: PromotionsDocumentModel) {
        const d = this.getPrograms(day, provider, hotelId, doc);

        if (!d) return null;

        const programData = Object.values(d).find(p => !!p.deep_link);

        if (!programData) return null;

        return programData.deep_link;
    }

    resetDocument() {
        this.storeState.data = null;
        this.storeState.loading.reset();
    }

    getAssessment(
        day: Day,
        hotelId: number,
        program: string,
        provider: string,
        doc?: PromotionsDocumentModel,
    ): ASSESSMENT_TYPES {
        const data = doc || this.data;

        if (!data) return ASSESSMENT_TYPES.NO_DATA;

        const dayActiveState = this.getDayActiveState(day, provider, hotelId, program, doc);

        const NO_DATA_VALUE = !dayActiveState.competitors
            ? ASSESSMENT_TYPES.NO_DATA
            : ASSESSMENT_TYPES.SOLD_OUT;

        const mainProgram = this.getProgram(day, provider, hotelId, program, doc);
        if (!mainProgram) return NO_DATA_VALUE;

        const isDeal = 'percentage' in mainProgram;

        if (!isDeal) {
            return mainProgram.status ? ASSESSMENT_TYPES.GOOD : NO_DATA_VALUE;
        }

        const competitorsAveragePercent = this.getCompetitorsAveragePercentDay(day, provider, program, hotelId, data);

        if (!mainProgram.status) {
            return NO_DATA_VALUE;
        }

        if (!competitorsAveragePercent) {
            return ASSESSMENT_TYPES.GOOD;
        }

        const mainPercent = mainProgram.percentage;

        if (competitorsAveragePercent === mainPercent) {
            return ASSESSMENT_TYPES.NORMAL;
        }

        return competitorsAveragePercent < mainPercent
            ? ASSESSMENT_TYPES.GOOD
            : ASSESSMENT_TYPES.BAD;
    }

    setDocument(doc: PromotionsDocumentModel | null) {
        this.storeState.data = doc;
        this.loading.finish();
    }

    getCompetitors(mainHotelId: number, doc?: PromotionsDocumentModel) {
        const data = doc || this.data;
        if (!data) return [];

        const { competitors } = this.documentFiltersService;

        return data.hotels
            .filter(hotelId => hotelId !== mainHotelId && competitors.includes(hotelId));
    }

    getLastScanDate(day: number) {
        if (!this.data) return null;
        if (!this.data.scanDateByDay) return null;

        return this.data.scanDateByDay[day];
    }

    getDayActiveState(
        day: number,
        provider: string,
        mainHotelId: number,
        program: string,
        doc?: PromotionsDocumentModel,
    ) {
        const data = doc || this.data;

        const state = {
            competitors: false,
            main: false,
        };

        if (!data) {
            return state;
        }

        const mainProgramData = this.getProgram(day, program, mainHotelId, program, doc);

        // NOTE: Since in some cases the compset is not available, we use competitors directly from the document
        const competitors = data.hotels.filter(hotelId => hotelId !== mainHotelId);

        state.main = !!mainProgramData && mainProgramData.status;
        state.competitors = competitors
            .some(hotelId => {
                const programData = this.getProgram(day, provider, hotelId, program, doc);
                return !!programData && programData.status;
            });

        return state;
    }

    private dispatchChangeEvent() {
        this.storeChangeListeners.forEach(handler => handler());
    }

    private async loadData(comparisonFilter?: {
        key: COMPARE_KEY;
        value: string;
    }) {
        const { settings: docSettings } = this.documentFiltersService;

        if (!docSettings.compsetId) return false;

        const doc = await this.promotionsApiService.getPromotionData(docSettings, comparisonFilter);

        if (comparisonFilter) {
            this.storeState.comparedData = doc;
        } else {
            this.storeState.data = doc;
        }

        return true;
    }
}
