import { Module, VuexModule } from 'vuex-module-decorators';
import LoadingModel from '@/modules/common/models/loading.model';
import LocationAvailabilityDocumentModel from '@/modules/cars/modules/location-availability/models/location-availability-document.model';
import LocationAvailabilitySettingsModel from '../models/location-availability-settings.model';

@Module
export default class LocationAvailabilityStore extends VuexModule {
    loading: LoadingModel = new LoadingModel();
    settings: LocationAvailabilitySettingsModel = new LocationAvailabilitySettingsModel();
    documents: {
        [locationId: string] : LocationAvailabilityDocumentModel
    } | null = null;
    closedDays: {
        [location: string]: {
            [provider: string]: number
        }
    } = {};
    isClosedOnly : boolean = false;
    locationQuery: string | null = null;
    currentPickupLocations: {name: string, value: string}[] = [];
}
