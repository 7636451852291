import { plainToClass } from 'class-transformer';
import { inject, injectable } from '@/inversify';
import ApiService, { ApiServiceS } from '../common/services/api.service';
import RVSDocumentModel from './models/rvs-document.model';

@injectable()
export default class RateValueScoreApiService {
    @inject(ApiServiceS)
    private apiService!: ApiService;

    async getMonthData(mainHotelId: number, year: number, month: number, provider?: string | null) {
        const query = !provider
            ? {}
            : { 'filters[provider_name]': provider };
        const { data } = await this.apiService.get(`rvs/${mainHotelId}/${year}/${month + 1}`, query);

        return plainToClass(RVSDocumentModel, data);
    }

    async getOvertimeData(mainHotelId: number, provider?: string | null) {
        const query = !provider
            ? {}
            : { 'filters[provider_name]': provider };
        const { data } = await this.apiService.get(`rvs/chart/${mainHotelId}`, query);

        return plainToClass(RVSDocumentModel, data as any[]);
    }
}
