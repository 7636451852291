import { Expose, Transform } from 'class-transformer';
import { RoomsGroup } from '../types';

type BEResponse = {
    providers: {
        [provider: string]: {
            [fornovaId: number]: {
                [groupId: number]: {
                    // eslint-disable-next-line camelcase
                    group_name: string;
                    mergedRooms: string[];
                }
            }
        }
    }
};

export default class ArchivedRoomsModel {
    @Expose()
    @Transform((_, plain: BEResponse) => {
        const model = {} as {
            [provider: string]: {
                [fornovaId: number]: RoomsGroup[];
            }
        };

        Object
            .entries(plain.providers)
            .forEach(([provider, hotels]) => {
                Object
                    .entries(hotels)
                    .forEach(([hotelId, roomGroups]) => {
                        model[provider] = model[provider] || {};
                        model[provider][+hotelId] = model[provider][+hotelId] || [];

                        Object
                            .entries(roomGroups)
                            .forEach(([groupId, group]) => {
                                model[provider][+hotelId] = [
                                    ...(model[provider][+hotelId] || []),
                                    {
                                        groupId,
                                        name: group.group_name,
                                        isNew: false,
                                        rooms: group.mergedRooms,
                                    },
                                ];
                            });
                    });
            });

        return model;
    })
    providers!: {
        [provider: string]: {
            [fornovaId: number]: RoomsGroup[];
        }
    };
}
