import { inject, injectable } from '@/inversify';
import { plainToClass } from 'class-transformer';
import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import EventsDTO, { EventsDTOUpdate } from '@/modules/events/dto/events.dto';
import EventsModel, { HolidayEventModel } from '@/modules/events/models/events.model';
import ApiService, { ApiServiceS } from '@/modules/common/services/api.service';
import EventGroup from './interfaces/event-group.enum';
import EventsExcelFormModel from './models/events-excel-form.model';
import { LOGTYPE } from '../open-telemetry/constants';
import OpenTelemetryService, { OpenTelemetryServiceS } from '../open-telemetry/open-telemetry.service';
import CountryModel from './models/country.model';
import DownloadExcelModel from '../rates/models/download-excel.model';

export const EventsApiServiceS = Symbol.for('EventsApiServiceS');
@injectable()
export default class EventsApiService {
    @inject(ApiServiceS) private apiService!: ApiService;
    @inject(OpenTelemetryServiceS) private openTelemetryService!: OpenTelemetryService;

    async getChainEvents(month: Month, year: Year, chainId: string, chainType: string): Promise<EventsModel[] | null> {
        const starts = new Date(Date.UTC(year, month, 1, 0, 0, 0)).toISOString().split('T')[0];
        const ends = new Date(Date.UTC(year, month + 1, 0, 1, 0, 0, 0)).toISOString().split('T')[0];

        const { data: { data } } = await this.apiService.post('events/get-events', {
            filters: {
                starts,
                ends,
                entity_type: chainType,
                entity_id: chainId,
            },
            limit: 0,
            skip: 0,
        });

        if (!data) {
            return null;
        }

        if (!data.length) {
            return null;
        }

        return plainToClass(EventsModel, <EventsModel[]> data, { excludeExtraneousValues: true });
    }

    async getMyEvents(month: Month, year: Year, fornovaId: number, marketId?: number): Promise<EventsModel[] | null> {
        const starts = new Date(Date.UTC(year, month, 1, 0, 0, 0)).toISOString().split('T')[0];
        const ends = new Date(Date.UTC(year, month + 1, 0, 1, 0, 0, 0)).toISOString().split('T')[0];

        const { data: { data } } = await this.apiService.post('events/get-events', {
            filters: {
                starts,
                ends,
                entity_type: 'hotel',
                entity_id: +fornovaId,
                marketId,
            },
            limit: 0,
            skip: 0,
        });

        if (!data) {
            return null;
        }

        const events = plainToClass(EventsModel, <EventsDTO[]> data, { excludeExtraneousValues: true });

        events.forEach(event => {
            if (event.entityType === 'market') {
                // eslint-disable-next-line
                event.group = EventGroup.MARKET;
            }
        });

        return events;
    }

    async getHolidaysEvents(month: Month, year: Year, countryCodes?: string[]): Promise<EventsModel[] | null> {
        const codes = countryCodes ? countryCodes.join(',') : undefined;
        const { data } = await this.apiService.get('holiday', { month, year, country_code: codes });

        if (!data) {
            return null;
        }
        // TODO: Add month filter when it will be enabled on server
        return plainToClass(HolidayEventModel, <HolidayEventModel[]> data, { excludeExtraneousValues: true });
    }

    async createEvent(event: EventsModel, creatorId: string): Promise<EventsModel | null> {
        const eventDto = plainToClass(EventsDTO, <EventsModel> event, { excludeExtraneousValues: true });

        const { data: newEventId } = await this.apiService.post('events', eventDto);

        if (!newEventId) {
            return null;
        }

        // eslint-disable-next-line no-param-reassign
        event.id = newEventId;
        // eslint-disable-next-line no-param-reassign
        event.creatorId = creatorId;

        return event;
    }

    async updateEvent(event: EventsModel): Promise<EventsModel | null> {
        const { id } = event;
        const eventDto = plainToClass(EventsDTOUpdate, <EventsModel> event, { excludeExtraneousValues: true, groups: ['update'] });

        const { data } = await this.apiService.put(`events/${id}`, eventDto);

        if (!data) {
            return null;
        }

        const updatedEvent = plainToClass(EventsModel, <EventsModel> data, { excludeExtraneousValues: true });
        updatedEvent.group = event.group;

        return event;
    }

    async removeLocalEvent(eventId: string): Promise<void> {
        await this.apiService.delete(`events/${eventId}`);
    }

    async ignoreEvents(eventIds: string[]): Promise<void> {
        await this.apiService.post('events/ignore/add', { event_ids: eventIds });
    }

    async getIgnoredEvents(): Promise<string[]> {
        const { data } = await this.apiService.get('events/ignore/get-current-user-ignores');

        return data.event_ids || [];
    }

    async restoreIgnoredEvent(eventId: string | string[]): Promise<void> {
        const eventIds = Array.isArray(eventId) ? eventId : [eventId];
        await this.apiService.put('events/ignore/remove-current-user-ignores', { event_ids: eventIds });
    }

    /**
     * Get list of all countries from BE, last item in countryNames is name which is used in holiday events.
     */
    async loadAllCountries() {
        const { data }: { data: CountryModel[] } = await this.apiService.get('event/ev-hol-countries');
        return plainToClass(CountryModel, data, { excludeExtraneousValues: true });
    }

    /**
     * Triggering excel generation for events manager
     */
    async getExcel(form: EventsExcelFormModel, hotelId?: number) {
        this.openTelemetryService.startSpan({ name: 'excel-events', prefix: LOGTYPE.DOWNLOAD });
        const { data } = await this.apiService.post(`events-reports/excel/${hotelId || ''}`, form);
        this.openTelemetryService.endSpan({ name: 'excel-events', prefix: LOGTYPE.DOWNLOAD }, { sendLogs: true });

        return plainToClass(DownloadExcelModel, data, { excludeExtraneousValues: true });
    }
}
