import { Module, VuexModule } from 'vuex-module-decorators';
import EventsSettingsModel from '@/modules/events/models/events-setting.model';
import LoadingModel from '@/modules/common/models/loading.model';
import { EventSet } from '../models/event-set.model';

type EventsOwnerHash = string;

@Module
export default class EventsStore extends VuexModule {
    settings: EventsSettingsModel = new EventsSettingsModel();

    countries: string[] = [];
    countryCodes: string[] = [];
    countriesLoading: LoadingModel = new LoadingModel();

    events: Record<EventsOwnerHash, EventSet> = {};
    ignoredEvents: string[] = [];
    loadingIgnores: LoadingModel = new LoadingModel();
}
