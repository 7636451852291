import StorageService, { StorageServiceS } from '@/modules/common/services/storage.service';
import StoreFacade, { StoreFacadeS } from '@/modules/common/services/store-facade';
import EVENT_TYPE_SETTINGS from '@/modules/events/constants/event-types-settings.constant';
import EventsSettingsModel from '@/modules/events/models/events-setting.model';
import EventsStore from '@/modules/events/store/events.store';
import UserService, { UserServiceS } from '@/modules/user/user.service';
import { inject, injectable } from '@/inversify';
import { $enum } from 'ts-enum-util';
import HelperService, { HelperServiceS } from '../common/services/helper.service';
import EventsApiService, { EventsApiServiceS } from './events-api.service';
import { EventSet } from './models/event-set.model';

const SETTINGS_KEY = 'event-manager-settings';

interface IEventsFilterSettings {
    settings: EventsSettingsModel,
    userId: string
}

export const EventsFilterServiceS = Symbol.for('EventsFilterServiceS');
@injectable()
export default class EventsFilterService {
    @inject(UserServiceS) private userService!: UserService;
    @inject(StorageServiceS) private storageService!: StorageService;
    @inject(StoreFacadeS) private storeFacade!: StoreFacade;
    @inject(HelperServiceS) private helperService!: HelperService;
    @inject(EventsApiServiceS) private eventsApiService!: EventsApiService;

    readonly storeState: EventsStore = this.storeFacade.getState('EventsStore');

    get settings(): EventsSettingsModel { return this.storeState.settings; }

    set settings(settings : EventsSettingsModel) {
        this.storeState.settings = settings;
        if (this.userService.user) {
            this.storageService
                .setItem(SETTINGS_KEY, { settings, userId: this.userService.user.id });
        }
    }

    setSettings(currentEventSet: EventSet, defaults: { countries?: string[] }) {
        const settingsData = this.storageService.getItem(SETTINGS_KEY) as IEventsFilterSettings;

        if (settingsData && this.userService.user && settingsData.userId === this.userService.user.id) {
            this.settings = settingsData.settings;
        } else {
            this.storageService.removeItem(SETTINGS_KEY);
            this.setDefaultSettings(currentEventSet, defaults);
        }
    }

    private async loadCountries() {
        const data = await this.eventsApiService.loadAllCountries();
        data.sort((a, b) => a.countryName.localeCompare(b.countryName));

        this.storeState.countries = data.map(country => country.countryName);
        this.storeState.countryCodes = data.map(country => country.countryCode);

        return true;
    }

    get allCountries() {
        this.helperService.dynamicLoading(this.storeState.countriesLoading, this.loadCountries.bind(this));
        return this.storeState.countries;
    }

    get allCountryCodes() {
        return this.storeState.countryCodes;
    }

    private async setDefaultSettings(currentEventSet: EventSet, defaults: { countries?: string[] }) {
        if (defaults.countries) {
            this.storeState.settings.countries = Object.keys(currentEventSet.countriesInvolved)
                .filter(countryCode => (defaults.countries || [])
                    .some(code => code.toLowerCase() === countryCode!.toLowerCase()))
                .map(countryCode => currentEventSet.countriesInvolved[countryCode]!);
        } else {
            await this.storeState.countriesLoading.whenLoadingFinished();
            this.storeState.settings.countries = [...this.allCountries];
        }

        this.storeState.settings.types = $enum(EVENT_TYPE_SETTINGS).map(item => item);
    }
}
