// Type names used on BE
export enum InsightType {
    RATE = 'rate',
    LOS = 'los',
    VISIBILITY = 'visibility',
    PROMOTION = 'promotion',
    ROOMS = 'new_rooms',
    DEVICE = 'device',
    RATE_TRENDS = 'rate_trends',
    MARKET_LEADER = 'market_leader',
}

// Route of insight day popup, is used in router and for redirrect. Keys must be upper cased values of InsightType.
export enum InsightDayRoute {
    RATE = 'rate',
    LOS = 'los',
    VISIBILITY = 'markets',
    PROMOTION = 'promotion',
    DEVICE = 'device',
    RATE_TRENDS = 'rate-trends',
    NEW_ROOMS = 'rooms',
    MARKET_LEADER = 'market-leader',
}
