import { inject, injectable } from '@/inversify';
import { validate, ValidationError } from 'class-validator';
import ResponseValidationException from '../modules/exception-handler/exceptions/response-validation.exception';

export const ValidatorServiceS = Symbol.for('ValidatorServiceS');
@injectable()
export default class ValidatorService {
    async validateResponse(document: {} | {}[]) {
        let errors: ValidationError[] | undefined;

        if (Array.isArray(document)) {
            errors = (await Promise.all(document.map(item => validate(item))))
                .reduce((acc: ValidationError[], error) => acc.concat(error), []);
        } else {
            errors = await validate(document);
        }

        if (!errors.length) {
            return null;
        }

        // Join all error messages in one message
        const errorMessage = Array.from(new Set(errors.map(error => {
            if (error.constraints) {
                return Object.entries(error.constraints).map(([_, msg]: [string, string]) => msg).join(', ');
            }

            return error.property;
        }))).join('; ');

        return errorMessage;
    }
}
