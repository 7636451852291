import { Expose, Transform } from 'class-transformer';
import {
    IsString, IsOptional, IsDate,
} from 'class-validator';

export default class CompsetMainModel {
    @Expose()
    @Transform((_, plain) => plain.compset_id)
    @IsString()
    id!: string;

    @Expose()
    @Transform((_, plain) => plain.pos)
    @IsString()
    pos!: string;

    @Expose()
    // eslint-disable-next-line no-underscore-dangle
    @Transform((_, plain) => plain._id)
    @IsString()
    documentId!: string | number;

    @Expose()
    @Transform((_, plain) => plain.provider_name)
    providerName!: string;

    @Expose()
    @Transform((_, plain) => plain.currency)
    currency?: string;

    @Expose()
    @Transform((_, plain) => plain.update_date && new Date(plain.update_date))
    @IsDate()
    finishScanDate?: Date;

    @Expose()
    @Transform((_, plain) => plain.update_date && new Date(plain.update_date))
    updateDate!: Date | null;
}
