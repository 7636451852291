import { Expose, Transform } from 'class-transformer';
import {
    IsNumber, IsString, IsDate, IsOptional, IsBoolean,
} from 'class-validator';
import VEHICLE_TYPE from '../constants/vehicle-type.constant';

export default class CarsDocumentItemModel {
    @Expose()
    @Transform((_, plain) => plain.id)
    @IsString()
    identifier!: string;

    @Expose()
    @Transform((_, plain) => Number(plain.price_total))
    @IsNumber()
    priceTotal!: number;

    @Expose()
    @Transform((_, plain) => Number(plain.price_net))
    @IsNumber()
    priceNet!: number;

    @Expose()
    @Transform((_, plain) => (plain.price_shown !== undefined ? Number(plain.price_shown) : plain.price_shown))
    @IsNumber()
    priceShown!: number;

    @Expose()
    @Transform((_, plain) => plain.mileage_policy)
    @IsString()
    mileage!: string;

    @Expose()
    @IsString()
    transmission!: string;

    @Expose()
    @Transform((_, plain) => plain.num_of_doors)
    @IsString()
    doors!: string;

    @Expose()
    @Transform((_, plain) => plain.car_description)
    @IsString()
    brand!: string;

    @Expose()
    @Transform((_, plain) => (plain.shop_date ? new Date(plain.shop_date) : null))
    @IsDate()
    @IsOptional()
    shopDate!: Date | null;

    @Expose()
    @Transform((_, plain) => plain.screenshot)
    @IsString()
    screenshot!: string;

    @Expose()
    @Transform((_, plain) => plain.rate_type)
    @IsString()
    paymentTerms!: string;

    @Expose()
    @Transform((_, plain) => plain.sipp_code)
    @IsString()
    sippCode!: string;

    @Expose()
    @Transform((_, plain) => plain.availability === 'Available')
    @IsBoolean()
    isAvailable!: boolean;

    @Expose()
    @Transform((_, plain) => plain.vehicle_type)
    @IsOptional()
    vehicleType!: VEHICLE_TYPE | null;

    @Expose()
    @Transform((_, plain) => plain.rectangle_uniqueness)
    rectangleUniqueness!: string;

    @Expose()
    @Transform((_, plain) => plain.fuel_type)
    @IsOptional()
    fuelType!: string;

    isMainCar: boolean = false;
}
