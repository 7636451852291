import type Month from '@/modules/common/types/month.type';
import type Year from '@/modules/common/types/year.type';
import PRICE_SHOWN from '@/modules/rates/constants/price-shown.constant';

const queryYear = window.location.search.match(/year=(\d{4})/)?.[1] || new Date().getFullYear();
const queryMonth = window.location.search.match(/month=(\d{1,2})/)?.[1] || (new Date().getMonth() + 1);

export default class DocumentFiltersModel {
    compsetId: string | null = null;
    los: number | null = null;
    pos: string | null = null;
    year: Year = +queryYear as Year;
    month: Month = (+queryMonth - 1) as Month;
    documentVendors?: Set<string> = new Set();
    priceShown: PRICE_SHOWN = PRICE_SHOWN.SHOWN;
    competitors: number[] = [];
}
