
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import { inject } from '@/inversify';
import CustomNotificationService, { CustomNotificationServiceS } from '@/modules/common/modules/custom-notification/custom-notification.service';
import NotificationWrapper from '@/modules/common/modules/custom-notification/components/notification-wrapper.vue';
import NotificationItemModel from '@/modules/common/modules/custom-notification/models/notification-item.model';

@Component({
    components: {
        NotificationWrapper,
    },
})
export default class ExcelNotification extends Vue {
    @inject(CustomNotificationServiceS)
    private customNotificationService!: CustomNotificationService;

    @Prop({
        required: true,
        type: Object,
    })
    data!: NotificationItemModel;

    get percent() {
        return this.data.progress;
    }

    get notificationData() {
        return this.data;
    }

    get fileName() {
        return this.notificationData.fileName;
    }

    get isReady() {
        return this.data.isReady;
    }

    handleClose() {
        this.customNotificationService.close(this.notificationData.id);
    }

    handleDownload() {
        this.customNotificationService
            .getExcelFile(this.notificationData.reportId, this.notificationData.fileName);
        this.customNotificationService.close(this.notificationData.id);
    }
}
